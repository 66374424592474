
import { Component, Vue, Prop } from "vue-property-decorator";
import { ICustomer } from "../../../store/customers";
import CustomerModal from "../CustomerModal.vue";

@Component({
  name: "CustomerList",
  components: {
    CustomerModal,
  },
})
export default class CustomerList extends Vue {
  @Prop({ type: Array, required: true }) customers!: Partial<ICustomer>[];
  @Prop({ type: Boolean, required: true }) canModify!: boolean;

  expandedIndex: number | null = null;

  toggleCustomer(index: number): void {
    this.expandedIndex = this.expandedIndex === index ? null : index;
  }
}
