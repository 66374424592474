
import Vue from "vue";
import Component from "vue-class-component";
import { Getter, Action } from "vuex-class";
import { AccountRoleActions } from "@/store/accountRole";
import { IAccountRoleDTO, IFieldMetadata } from "@shared/types";
import DynamicForm from "@/components/Settings/Services/components/DynamicForm.vue";
import { PermissionLevel } from "@/services/FormGeneratorService";

@Component({
  name: "AccountRoleSettings",
  components: { DynamicForm },
})
export default class AccountRoleSettings extends Vue {
  @Action public addToDialogQueue!: Function;
  @Action(AccountRoleActions.GET_ROLES) getRoles!: Function;
  @Action(AccountRoleActions.CREATE_ROLE) createRole!: Function;
  @Action(AccountRoleActions.UPDATE_ROLE) updateRole!: Function;
  @Action(AccountRoleActions.DELETE_ROLE) deleteRole!: Function;
  @Getter public roles!: IAccountRoleDTO[];
  @Getter public accountRoleMetadata!: IFieldMetadata[];

  public editingRole: Partial<IAccountRoleDTO> | null = null;
  private showForm = false;
  private isEditing = false;
  private isCreating = false;

  get defaultRole(): Partial<IAccountRoleDTO> {
    return {
      name: "",
      appointmentsAndClients: PermissionLevel.None,
      settings: PermissionLevel.None,
      statisticsAndFinances: PermissionLevel.None,
      administration: PermissionLevel.None,
    };
  }

  get nonReactiveTranslations() {
    return Object.freeze({ ...this.$ts.dtos.accountRoleDTO });
  }

  async created() {
    await this.getRoles();
  }

  openNewRoleDialog() {
    this.isCreating = true;
    this.isEditing = false;
    this.editingRole = { ...this.defaultRole };
    this.showForm = true;
  }

  startEdit(role: IAccountRoleDTO) {
    this.isEditing = true;
    this.isCreating = false;
    this.editingRole = { ...role };
    this.showForm = true;
  }

  cancelEdit() {
    this.isEditing = false;
    this.isCreating = false;
    this.editingRole = null;
    this.showForm = false;
  }

  async onSave(formData: IAccountRoleDTO) {
    try {
      if (this.isEditing) {
        await this.updateRole({
          id: formData.id,
          role: formData,
        });
      } else {
        await this.createRole(formData);
      }

      await this.getRoles();
      this.cancelEdit();
    } catch (error) {
      console.error("Error saving role:", error);
    }
  }

  async deleteRoleHandler(roleId: string) {
    try {
      await this.deleteRole(roleId);
      await this.getRoles();
    } catch (error) {
      console.error("Error deleting role:", error);
    }
  }
}
