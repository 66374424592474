var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-row',[_c('v-col',[_c('v-row',[_c('v-card',{attrs:{"flat":"","width":"100%","height":"100%"}},[_c('v-card-text',[_c('h2',[_vm._v(_vm._s(_vm.$t(_vm.$ts.link)))])]),_c('v-card-text',[(_vm.isOldDomain)?_c('h3',[_vm._v(" "+_vm._s(_vm.$t("yourBookingLinkWillBe", { link: "https://booking.vergba-app.no/" + _vm.currentAdminInfo.bookingLink, }))+" ")]):_vm._e(),(!_vm.isOldDomain && !_vm.isCitaroDomain)?_c('h3',[_vm._v(" "+_vm._s(_vm.$t("yourBookingLinkWillBe", { link: "https://booking.availify.no/" + _vm.currentAdminInfo.bookingLink, }))+" ")]):_vm._e(),(_vm.isCitaroDomain)?_c('h3',[_vm._v(" "+_vm._s(_vm.$t("yourBookingLinkWillBe", { link: "https://booking.citaro.app/" + _vm.currentAdminInfo.bookingLink, }))+" ")]):_vm._e(),_c('v-text-field',{attrs:{"type":"text","label":_vm.$t('bookingID')},on:{"blur":function($event){return _vm.saveField('bookingLink', _vm.currentAdminInfo.bookingLink)}},model:{value:(_vm.currentAdminInfo.bookingLink),callback:function ($$v) {_vm.$set(_vm.currentAdminInfo, "bookingLink", $$v)},expression:"currentAdminInfo.bookingLink"}}),_c('h3',[_vm._v(_vm._s(_vm.$t(_vm.$ts.connectSystemWithCommonBookingLink)))]),(_vm.isOldDomain)?_c('h3',[_vm._v(" "+_vm._s(_vm.$t("yourCommonBookingLinkWillBe", { link: "https://booking.vergba-app.no/velg/" + _vm.currentAdminInfo.connectedBookingLink, }))+" ")]):_vm._e(),(!_vm.isOldDomain)?_c('h3',[_vm._v(" "+_vm._s(_vm.$t("yourCommonBookingLinkWillBe", { link: "https://booking.availify.no/velg/" + _vm.currentAdminInfo.connectedBookingLink, }))+" ")]):_vm._e(),_c('v-text-field',{attrs:{"type":"text","label":_vm.$t('commonID')},on:{"blur":function($event){return _vm.saveField(
                  'connectedBookingLink',
                  _vm.currentAdminInfo.connectedBookingLink
                )}},model:{value:(_vm.currentAdminInfo.connectedBookingLink),callback:function ($$v) {_vm.$set(_vm.currentAdminInfo, "connectedBookingLink", $$v)},expression:"currentAdminInfo.connectedBookingLink"}}),_c('h3',[_vm._v(_vm._s(_vm.$t(_vm.$ts.locationName)))]),_c('v-text-field',{attrs:{"type":"text","label":_vm.$t('locationName')},on:{"blur":function($event){return _vm.saveField(
                  'bookingLocationName',
                  _vm.currentAdminInfo.bookingLocationName
                )}},model:{value:(_vm.currentAdminInfo.bookingLocationName),callback:function ($$v) {_vm.$set(_vm.currentAdminInfo, "bookingLocationName", $$v)},expression:"currentAdminInfo.bookingLocationName"}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }