
import { Component, Vue, Prop } from "vue-property-decorator";
import { IServiceCategory } from "@/store/appointmentTypes";
import BaseFormDialog from "@/components/global/BaseFormDialog.vue";
import CompanyInfoField from "@/components/global/CompanyInfoField.vue";
import Textfield from "@/components/global/Textfield.vue";

@Component({
  name: "ServiceCategoryForm",
  components: { BaseFormDialog, CompanyInfoField, Textfield },
})
export default class ServiceCategoryForm extends Vue {
  @Prop() editingCategory?: IServiceCategory;

  private isSaving = false;
  private categoryData: Partial<IServiceCategory> = {
    title: "",
    isShowing: true,
  };

  private titleRules: ((v: string) => boolean | string)[] = [];

  created() {
    if (this.editingCategory) {
      this.categoryData = { ...this.editingCategory };
    }

    this.titleRules = [
      (v: string) =>
        !!v ||
        this.$t(this.$ts.serviceCategories.validation.nameRequired).toString(),
    ];
  }

  private validateField(fieldName: string) {
    const baseForm = this.$refs.baseForm as any;
    if (baseForm) {
      baseForm.validate();
    }
  }

  private async onSave() {
    const baseForm = this.$refs.baseForm as any;
    if (baseForm && baseForm.validate()) {
      this.isSaving = true;
      this.$emit("save", this.categoryData);
    }
  }

  private async onCreate() {
    const baseForm = this.$refs.baseForm as any;
    if (baseForm && baseForm.validate()) {
      this.isSaving = true;
      this.$emit("create", this.categoryData);
    }
  }

  private onCancel() {
    this.$emit("cancel");
  }
}
