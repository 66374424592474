
import Vue from "vue";
import Component from "vue-class-component";
import { Getter } from "vuex-class";
import { IUserDTO } from "@shared/types";
import GeneralSettings from "./invoice-settings/GeneralSettings.vue";
import KidSettings from "./invoice-settings/KidSettings.vue";
import DianSettings from "./invoice-settings/DianSettings.vue";

@Component({
  name: "InvoiceSettings",
  components: {
    GeneralSettings,
    KidSettings,
    DianSettings,
  },
})
export default class InvoiceSettings extends Vue {
  public currentTab: number = 0;
  @Getter public user: IUserDTO;
}
