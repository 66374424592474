
import { Component, Prop, Vue } from "vue-property-decorator";
import { IUserDTO } from "@shared/types";
import { formatTimeWithFormat } from "@/Utilities/dateUtility";

@Component({
  name: "EventCard",
})
export default class EventCard extends Vue {
  @Prop({ required: true }) event!: any;
  @Prop({ required: true }) eventParsed!: any;
  @Prop({ required: true }) user!: IUserDTO;

  getFirstWordOfString(value: string): string {
    return value.split(" ")[0];
  }

  formatTimeWithFormatForEvent(eventInfo: any): string {
    return formatTimeWithFormat(eventInfo.hour, eventInfo.minute, false, false);
  }
}
