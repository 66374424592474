// @ts-ignore
import { GetterTree, MutationTree, ActionTree } from "vuex";
// @ts-ignore
import axios, { AxiosRequestConfig } from "axios";
import { APIURL2 } from "@/main";
import { actionStringSnackbar } from "./snackbar";
import authConfig, { AuthConfig } from "@/Utilities/authConfig";
import { actionStringUser } from "@/store/user";
import { IAppointmentDTO, IStaffMemberDTO } from "@shared/types";
import { createPatchObject } from "../Utilities/ObjectUtility";
import { IAppointmentPatch } from "./appointments/appointments";
import { i18n } from "@/main";
export const enum StaffMemberMutations {
  SET_STAFFMEMBERS = "setStaffMembers",
}
export const enum StaffMemberActions {
  GET_STAFFMEMBERS = "getStaffMembers",
  UPLOAD_STAFF_IMAGE = "uploadStaffImage",
}

export interface StaffMemberState {
  staffMembers: IStaffMemberDTO[];
}

export const state: StaffMemberState = {
  staffMembers: [],
};

export interface IStaffMemberPatch {
  staffMemberId: number;
  patch: Partial<IStaffMemberDTO>;
}

export const getters: GetterTree<StaffMemberState, StaffMemberState> = {
  staffMembers: (state) => state.staffMembers,
  hasMultipleStaff: (state) => state.staffMembers.length > 1,
};

export const mutations: MutationTree<StaffMemberState> = {
  setStaffMembers(state, payload: IStaffMemberDTO[]) {
    state.staffMembers = payload;
  },
};

export const actions: ActionTree<StaffMemberState, any> = {
  async getStaffMembers({ commit, dispatch }): Promise<any> {
    let header: AxiosRequestConfig = await authConfig();
    return new Promise((resolve, reject) => {
      axios
        .get(APIURL2 + "StaffMember/current", header)
        .then((res) => {
          commit(StaffMemberMutations.SET_STAFFMEMBERS, res.data);
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          dispatch(actionStringSnackbar.postSnackbarMessage, err.message);
          reject(err);
        });
    });
  },

  async deleteStaffMember({ dispatch }, payload): Promise<any> {
    let header: AxiosRequestConfig = await authConfig();

    return new Promise((resolve, reject) => {
      try {
        axios
          .delete(
            APIURL2 + "StaffMember/current?staffMemberId=" + payload,
            header
          )
          .then((response) => {
            dispatch(
              actionStringSnackbar.postSnackbarMessage,
              i18n.t("settings.staffDeleted").toString()
            );
            dispatch(StaffMemberActions.GET_STAFFMEMBERS);
            return resolve(response);
          });
      } catch (e) {
        reject(e);
      }
    });
  },

  async patchStaffMember(
    { dispatch },
    payload: IStaffMemberPatch
  ): Promise<any> {
    let patchPayload = createPatchObject(payload.patch);

    let header: AxiosRequestConfig = await authConfig();

    return new Promise((resolve, reject) => {
      try {
        axios
          .patch(
            APIURL2 +
              "StaffMember/current?staffMemberId=" +
              payload.staffMemberId,
            patchPayload,
            header
          )
          .then((response) => {
            dispatch(
              actionStringSnackbar.postSnackbarMessage,
              i18n.t("settings.staffUpdated").toString()
            );
            dispatch(StaffMemberActions.GET_STAFFMEMBERS);
            return resolve(response);
          });
      } catch (e) {
        reject(e);
      }
    });
  },

  async removeOAuthToken({ commit, dispatch }, payload): Promise<any> {
    let header: AxiosRequestConfig = await authConfig();
    return new Promise((resolve, reject) => {
      axios
        .delete(APIURL2 + "GoogleCalendar/oauth/" + payload.id, header)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  async hasOauthToken({ commit, dispatch }, payload): Promise<any> {
    let header: AxiosRequestConfig = await authConfig();
    return new Promise((resolve, reject) => {
      axios
        .get(APIURL2 + "StaffMember/current/hasOAuth/" + payload.id, header)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  async setServiceToStaffMember({ commit, dispatch }, payload): Promise<any> {
    let header: AxiosRequestConfig = await authConfig();
    return new Promise((resolve, reject) => {
      axios
        .put(
          APIURL2 +
            "StaffMember/current/service?staffMemberId=" +
            payload.staffMemberId +
            "&enabled=" +
            payload.enabled +
            "&serviceId=" +
            payload.serviceId,
          {},
          header
        )
        .then((res) => {
          dispatch(StaffMemberActions.GET_STAFFMEMBERS);
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          dispatch(actionStringSnackbar.postSnackbarMessage, err.message);
          reject(err);
        });
    });
  },

  async postStaffMember(
    { commit, dispatch },
    payload: Partial<IStaffMemberDTO>
  ): Promise<any> {
    let header: AxiosRequestConfig = await authConfig();
    return new Promise((resolve, reject) => {
      axios
        .post(APIURL2 + "StaffMember/current", payload, header)
        .then((res) => {
          dispatch(StaffMemberActions.GET_STAFFMEMBERS);
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          dispatch(actionStringSnackbar.postSnackbarMessage, err.message);
          reject(err);
        });
    });
  },

  async uploadStaffImage(
    { dispatch },
    payload: { staffMemberId: number; formData: FormData }
  ): Promise<boolean> {
    let header: AxiosRequestConfig = await authConfig();
    return new Promise((resolve) => {
      axios
        .put(
          APIURL2 + `StaffMember/current/picture/${payload.staffMemberId}`,
          payload.formData,
          {
            headers: {
              Authorization: header.headers.Authorization,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then(() => {
          dispatch(
            actionStringSnackbar.postSnackbarMessage,
            i18n.t("user.imageUploaded").toString()
          );
          dispatch(StaffMemberActions.GET_STAFFMEMBERS).then(() => {});
          return resolve(true);
        })
        .catch((err) => {
          dispatch(
            actionStringSnackbar.postSnackbarMessage,
            i18n.t("user.imageUploadError").toString()
          );
          console.error(err);
          return resolve(false);
        });
    });
  },
};

export const staffMember = {
  actions,
  mutations,
  getters,
  state,
};
