
import { Component, Vue, Prop, Model, Watch } from "vue-property-decorator";

@Component({
  name: "base-form-dialog",
})
export default class BaseFormDialog extends Vue {
  //@Model("input", { type: Boolean, default: false }) readonly value!: boolean;
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) isEditing!: boolean;
  @Prop({ required: true }) isCreating!: boolean;
  @Prop({ default: false }) isSaving!: boolean;
  @Prop({ default: "" }) saveText!: string;
  @Prop({ default: "" }) createText!: string;
  @Prop({ default: "" }) cancelText!: string;
  @Prop({ default: 660 }) maxWidth!: number;

  private isValid: boolean = false;

  mounted() {
    this.$nextTick(() => {
      this.validate();
    });
  }

  onInput() {
    const form = this.$refs.form as any;
    if (form) {
      this.isValid = form.validate();
    }
  }

  public validate(): boolean {
    const form = this.$refs.form as any;
    if (!form) return false;
    this.isValid = form.validate();
    return this.isValid;
  }

  public reset(): void {
    const form = this.$refs.form as any;
    if (form) {
      form.reset();
    }
  }

  //   get dialogModel() {
  //     return this.value;
  //   }

  //   set dialogModel(value: boolean) {
  //     this.$emit("input", value);
  //   }
}
