
import Vue from "vue";
import Component from "vue-class-component";
import NotificationBell from "../global/NotificationBell.vue";
import { Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({
  name: "NavigationItems",
  components: { NotificationBell },
})
export default class NavigationItems extends Vue {
  @Prop({ required: true }) selectedIndex!: number;

  @Getter("userPermissions/canViewAppointmentsAndClients")
  canViewAppointmentsAndClients!: boolean;
  @Getter("userPermissions/canWriteAppointmentsAndClients")
  canWriteAppointmentsAndClients!: boolean;
  @Getter("userPermissions/canViewStatisticsAndFinances")
  canViewStatisticsAndFinances!: boolean;

  get iconSize() {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    return width < 420 ? 24 : 32;
  }

  get itemList() {
    const width = document.documentElement.clientWidth;
    const items = [];

    if (this.canViewAppointmentsAndClients) {
      items.push(
        {
          index: 0,
          alt: this.$t(this.$ts.mobileHeader.home),
          text: this.$t(this.$ts.mobileHeader.home),
          image: "../img/mobile/dashboard.svg",
          image2: "../img/mobile/dashboard_fill.svg",
          route: "/admin",
          icon: "dashboard",
        },
        {
          index: 1,
          alt: this.$t(this.$ts.mobileHeader.calendar),
          text: this.$t(this.$ts.mobileHeader.calendar),
          image: "../img/mobile/calendar.svg",
          image2: "../img/mobile/calendar_fill.svg",
          route: "/kalender",
          icon: "calendar",
        }
      );

      if (width > 800) {
        items.push(
          {
            index: 2,
            alt: this.$t(this.$ts.mobileHeader.customers),
            text: this.$t(this.$ts.mobileHeader.customers),
            image: "../img/mobile/customer.svg",
            image2: "../img/mobile/customer_fill.svg",
            route: "/kunder",
            icon: "account_circle",
          },
          {
            index: 3,
            alt: this.$t(this.$ts.mobileHeader.orders),
            text: this.$t(this.$ts.mobileHeader.orders),
            image: "../img/mobile/overview.svg",
            image2: "../img/mobile/overview_fill.svg",
            route: "/bestillinger",
            icon: "inbox",
          }
        );
      }
    }

    if (width > 800) {
      items.push({
        index: 5,
        alt: this.$t(this.$ts.mobileHeader.settings),
        text: this.$t(this.$ts.mobileHeader.settings),
        image: "../img/mobile/settings.svg",
        image2: "../img/mobile/settings_fill.svg",
        action: () => this.$emit("settings-click"),
        icon: "calendar_today",
      });
    }

    return items;
  }
}
