
import Vue from "vue";
import Component from "vue-class-component";
import { Getter, Action } from "vuex-class";
import ToolTip from "../../../ToolTip.vue";
import { IAuditLogDTO, IUserDTO } from "@shared/types";

@Component({
  name: "BookingPageGeneralSettings",
  components: { ToolTip },
})
export default class BookingPageGeneralSettings extends Vue {
  @Action public postSnackbarMessage!: Function;
  @Action public postAuditLog!: Function;
  @Getter user!: IUserDTO;
  @Action patchUser!: Function;

  currentAdminInfo: Partial<IUserDTO> = {
    bookingLink: "",
    connectedBookingLink: "",
    bookingLocationName: "",
  };

  setCurrentUser(): void {
    this.currentAdminInfo.bookingLink = this.user.bookingLink;
    this.currentAdminInfo.connectedBookingLink = this.user.connectedBookingLink;
    this.currentAdminInfo.bookingLocationName = this.user.bookingLocationName;
  }

  created() {
    this.setCurrentUser();
  }

  async saveField(field: string, value: string): Promise<void> {
    try {
      // Validate bookingLink length
      if (field === "bookingLink" && value.length < 3) {
        this.postSnackbarMessage(
          this.$t(
            "bookingPageGeneralSettings.validation.bookingLinkTooShort"
          ).toString()
        );
        // Reset to previous value
        this.currentAdminInfo.bookingLink = this.user.bookingLink;
        return;
      }

      // Create audit log based on field
      if (field === "bookingLink" && value !== this.user.bookingLink) {
        await this.postAuditLog({
          action: 1,
          type: 2,
          comment: "",
          message: this.$t(
            "bookingPageGeneralSettings.auditLogs.changedBookingLink",
            {
              link: value,
            }
          ).toString(),
          hideForUser: false,
        });
      } else if (
        field === "connectedBookingLink" &&
        value !== this.user.connectedBookingLink
      ) {
        await this.postAuditLog({
          action: 1,
          type: 2,
          comment: "",
          message: this.$t(
            "bookingPageGeneralSettings.auditLogs.changedCommonLink",
            {
              link: value,
            }
          ).toString(),
          hideForUser: false,
        });
      } else if (
        field === "bookingLocationName" &&
        value !== this.user.bookingLocationName
      ) {
        await this.postAuditLog({
          action: 1,
          type: 2,
          comment: "",
          message: this.$t(
            "bookingPageGeneralSettings.auditLogs.changedLocationName",
            {
              name: value,
            }
          ).toString(),
          hideForUser: false,
        });
      }

      // Save the field
      await this.patchUser({ [field]: value });
    } catch (error) {
      console.error(error);
      // Reset to previous value on error
      this.currentAdminInfo[field] = this.user[field];
    }
  }

  get isOldDomain(): boolean {
    return window.location.toString().includes("vergba");
  }

  get isCitaroDomain(): boolean {
    return window.location.toString().includes("citaro");
  }
}
