import { render, staticRenderFns } from "./BaseFormDialog.vue?vue&type=template&id=2e2ca002&scoped=true&"
import script from "./BaseFormDialog.vue?vue&type=script&lang=ts&"
export * from "./BaseFormDialog.vue?vue&type=script&lang=ts&"
import style0 from "./BaseFormDialog.vue?vue&type=style&index=0&id=2e2ca002&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e2ca002",
  null
  
)

export default component.exports