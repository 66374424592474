
import { Component, Vue, Prop } from "vue-property-decorator";
import { Action } from "vuex-class";

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

@Component({
  name: "image-upload",
})
export default class ImageUpload extends Vue {
  @Prop({ required: true }) currentImageUrl!: string | undefined;
  @Prop({ required: true }) size!: number;
  @Prop({ default: false }) isLoading!: boolean;
  @Prop({ default: "mdi-account-circle" }) defaultIcon!: string;
  @Prop({ default: "Upload Image" }) uploadLabel!: string;

  @Action public postSnackbarMessage!: Function;

  files: File | null = null;
  hasFile: boolean = false;
  isShowingUploadSection: boolean = false;

  public previewFiles(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input.files || !input.files[0]) return;

    this.files = input.files[0];

    if (this.files.size > 200000) {
      this.postSnackbarMessage(
        this.$t(this.$ts.snackbar.imageTooLarge).toString()
      );
      this.hasFile = false;
      this.files = null;
      return;
    }

    if (
      this.files.type === "image/jpeg" ||
      this.files.type === "image/png" ||
      this.files.type === "image/jpg"
    ) {
      this.hasFile = true;
      this.$emit("file-selected", this.files);
    } else {
      this.postSnackbarMessage(
        this.$t(this.$ts.snackbar.invalidImageFormat).toString()
      );
      this.hasFile = false;
      this.files = null;
    }
  }

  public toggleUploadSection(): boolean {
    return (this.isShowingUploadSection = !this.isShowingUploadSection);
  }
}
