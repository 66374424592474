
import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter } from "vuex-class";
import { IDialog } from "@/store/dialog";
import { IAuditLogDTO, IStaffMemberDTO, IUserDTO } from "@shared/types";
import ToolTip from "../../ToolTip.vue";
import { VerificationType } from "@/store/user";
import CompanyInfoField from "../../global/CompanyInfoField.vue";
import BookingDistribution from "./BookingDistribution.vue";
import Textfield from "@/components/global/Textfield.vue";

@Component({
  name: "GeneralBookingSettings",
  components: { ToolTip, CompanyInfoField, BookingDistribution, Textfield },
})
export default class GeneralBookingSettings extends Vue {
  @Action addToDialogQueue!: Function;
  @Action patchUser!: Function;
  @Action postAuditLog!: Function;
  @Getter user!: IUserDTO;
  @Getter staffMembers!: IStaffMemberDTO[];

  public abilityToCancel: boolean = false;
  public hoursToCancel: number = 0;
  public maxDaysInAdvanceForBooking: number = 0;
  public minumumHourBeforeBooking: number = 0;

  public smsVerification: boolean = false;
  public verificationType: VerificationType = VerificationType.None;
  public customerReceiptAndReminderMode: string = "";

  public sendAutomaticallyReminder: boolean = false;
  public sendAutomaticallyReceipt: boolean = false;

  public customerCanSelectStaff: boolean = false;

  created() {
    this.abilityToCancel = this.user.abilityToCancel;
    this.hoursToCancel = this.user.hoursToCancel;
    this.maxDaysInAdvanceForBooking = this.user.maxDaysInAdvanceForBooking;
    this.minumumHourBeforeBooking = this.user.minumumHourBeforeBooking;
    this.verificationType = this.user.verificationType;
    this.customerReceiptAndReminderMode =
      this.user.customerReceiptAndReminderMode;
    this.sendAutomaticallyReceipt = this.user.sendAutomaticallyReceipt;
    this.sendAutomaticallyReminder = this.user.sendAutomaticallyReminder;
    this.customerCanSelectStaff = this.user.customerCanSelectStaff;
  }

  async saveField(field: string, value: any, oldValue: any): Promise<void> {
    try {
      // Create audit log based on field
      if (field === "verificationType" && value !== oldValue) {
        await this.postAuditLog({
          action: 1,
          type: 2,
          comment: "",
          message: this.$t(
            "generalBookingSettings.auditVerificationChanged"
          ).toString(),
          hideForUser: false,
        });
      } else if (field === "abilityToCancel" && value !== oldValue) {
        await this.postAuditLog({
          action: 1,
          type: 2,
          comment: "",
          message: this.$t(
            "generalBookingSettings.auditCancellationChanged"
          ).toString(),
          hideForUser: false,
        });
      } else if (field === "hoursToCancel" && value !== oldValue) {
        await this.postAuditLog({
          action: 1,
          type: 2,
          comment: "",
          message: this.$t(
            this.$ts.generalBookingSettings.auditHoursToCancelChanged,
            {
              hours: value,
            }
          ).toString(),
          hideForUser: false,
        });
      } else if (
        field === "customerReceiptAndReminderMode" &&
        value !== oldValue
      ) {
        await this.postAuditLog({
          action: 1,
          type: 2,
          comment: "",
          message: this.$t(
            "generalBookingSettings.auditReceiptReminderChanged"
          ).toString(),
          hideForUser: false,
        });
      } else if (field === "maxDaysInAdvanceForBooking" && value !== oldValue) {
        await this.postAuditLog({
          action: 1,
          type: 2,
          comment: "",
          message: this.$t(
            this.$ts.generalBookingSettings.auditMaxDaysChanged,
            {
              days: value,
            }
          ).toString(),
          hideForUser: false,
        });
      } else if (field === "minumumHourBeforeBooking" && value !== oldValue) {
        await this.postAuditLog({
          action: 1,
          type: 2,
          comment: "",
          message: this.$t(
            this.$ts.generalBookingSettings.auditMinHoursChanged,
            {
              hours: value,
            }
          ).toString(),
          hideForUser: false,
        });
      }

      // Handle special cases for email/SMS requirements
      if (
        (field === "verificationType" && value === VerificationType.Email) ||
        (field === "customerReceiptAndReminderMode" && value === "email")
      ) {
        if (
          this.user.customerRequiredEmailOptional ||
          !this.user.customerRequiredEmail
        ) {
          await this.addToDialogQueue({
            text: this.$t(
              this.$ts.generalBookingSettings.emailRequired
            ).toString(),
          });
          await this.patchUser({
            customerRequiredEmailOptional: false,
            customerRequiredEmail: true,
          });
        }
      } else if (
        field === "customerReceiptAndReminderMode" &&
        (value === "sms" || value === "whatsapp")
      ) {
        if (
          this.user.customerRequiredPhoneNumberOptional ||
          !this.user.customerRequiredPhoneNumber
        ) {
          await this.addToDialogQueue({
            text: this.$t(
              this.$ts.generalBookingSettings.phoneRequired
            ).toString(),
          });
          await this.patchUser({
            customerRequiredPhoneNumberOptional: false,
            customerRequiredPhoneNumber: true,
          });
        }
      }

      // Save the field
      await this.patchUser({ [field]: value });
    } catch (error) {
      console.error(error);
    }
  }

  get verificationOptions() {
    return [
      {
        value: 0,
        label: this.$t(this.$ts.generalBookingSettings.noVerification),
      },
      { value: 1, label: this.$t(this.$ts.generalBookingSettings.email) },
    ];
  }

  booleanToOnOff(value: boolean): string {
    return value ? this.$t(this.$ts.boolean.on) : this.$t(this.$ts.boolean.off);
  }

  booleanToOnOff2(value: boolean): string {
    return value ? this.$t(this.$ts.boolean.yes) : this.$t(this.$ts.boolean.no);
  }
}
