// @ts-ignore
import axios, { AxiosRequestConfig } from "axios";
// @ts-ignore
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { APIURL2 } from "@/main";
import authConfig, { AuthConfig, logout } from "@/Utilities/authConfig";
import { actionStringSnackbar } from "./snackbar";
import { ISubscriptionPlan, Subscription } from "@/types/HasSubscription";
import { formatDate, FormatSpaceType } from "@/types/formatDateHelper";
import { Sector } from "@/types/Sector";
import { DialogActions, DialogMutations, IDialog } from "@/store/dialog";
import { createPatchObject } from "../Utilities/ObjectUtility";
import { ISubscriptionStatus } from "../types/HasSubscription";
// @ts-ignore
import { User } from "firebase/app";
import { i18n } from "@/main"; // Import i18n for translations
import { IUserDTO } from "@shared/types";

export interface userState {
  user: IUserDTO | null;
  userToBook: IUserDTO | null;
  subscriptionStatus: ISubscriptionStatus | null;
  isLoggedIn: boolean;
  hasResolvedUser: boolean;
  isNewUser: boolean;
  subscriptionPlan: ISubscriptionPlan | null;
  selectedSector: Sector | null;
  firebaseUser: User | null;
}

export enum VerificationType {
  None = 0,
  Email = 1,
  Phone = 2,
}

export const enum mutationStringUser {
  setUser = "setUser",
  updateUser = "updateUser",
  setUserToBook = "setUserToBook",
  SET_SUBSCRIPTION_STATUS = "setSubscriptionStatus",
  SET_SUBSCRIPTION_PLAN = "setSubscriptionPlan",
  SET_SELECTED_SECTOR = "setSelectedSector",
}

export const enum actionStringUser {
  getCurrentUser = "getCurrentUser",
  GET_USER_SUBSCRIPTION = "getUserSubscription",
  GET_USER_SUBSCRIPTION_PLAN = "getUserSubscriptionPlan",
  UPDATE_USER = "updateUser",
  UPDATE_USERPROFILE = "updateUserProfile",
}

export const enum getterStringUser {
  SUBSCRIPTION_STATUS = "subscriptionStatus",
  SUBSCRIPTION_PLAN = "subscriptionPlan",
  SELECTED_SECTOR = "selectedSector",
}

export const state: userState = {
  user: null,
  userToBook: null,
  subscriptionStatus: null,
  isLoggedIn: false,
  hasResolvedUser: false,
  isNewUser: false,
  subscriptionPlan: null,
  selectedSector: null,
  firebaseUser: null,
};

export const getters: GetterTree<userState, any> = {
  user: (state) => state.user,
  userToBook: (state) => state.userToBook,
  subscriptionStatus: (state) => state.subscriptionStatus,
  isLoggedIn: (state) => state.isLoggedIn,
  hasResolvedUser: (state) => state.hasResolvedUser,
  isNewUser: (state) => state.isNewUser,
  subscriptionPlan: (state) => state.subscriptionPlan,
  selectedSector: (state) => state.selectedSector,
  firebaseUser: (state) => state.firebaseUser,
};

export const mutations: MutationTree<userState> = {
  setSelectedSector(state, payload: Sector) {
    state.selectedSector = payload;
  },
  setUser(state, payload: IUserDTO) {
    state.user = payload;
  },
  updateUser(state, payload: Partial<IUserDTO>) {
    Object.assign(state.user, payload);
  },
  setUserToBook(state, payload: any) {
    state.userToBook = payload;
  },
  setIsLoggedIn(state, payload: any) {
    state.isLoggedIn = payload;
  },
  setSubscriptionStatus(state, payload: ISubscriptionStatus) {
    state.subscriptionStatus = payload;
  },
  setHasResolvedUser(state, payload: boolean) {
    state.hasResolvedUser = payload;
  },
  setFirebaseUser(state, payload: User) {
    state.firebaseUser = payload;
  },
  setIsNewUser(state, payload: boolean) {
    state.isNewUser = payload;
  },
  setSubscriptionPlan(state, payload: ISubscriptionPlan) {
    state.subscriptionPlan = payload;
  },
};

// @ts-ignore
export const actions: ActionTree<userState, any> = {
  async getUserSubscription({ commit }): Promise<ISubscriptionStatus> {
    let header: AxiosRequestConfig = await authConfig();

    return new Promise((resolve, reject) => {
      axios
        .get(APIURL2 + "Subscription/Status/current", header)
        .then((res) => {
          commit(mutationStringUser.SET_SUBSCRIPTION_STATUS, res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },

  async getUserSubscriptionPlan({ commit }): Promise<ISubscriptionPlan> {
    let header: AxiosRequestConfig = await authConfig();

    return new Promise((resolve, reject) => {
      try {
        axios
          .get(APIURL2 + "Subscription/current", header)
          .then((res) => {
            res.data.nextPayment = formatDate(
              res.data.nextPayment,
              FormatSpaceType.DOT
            );
            res.data.previousPayment = formatDate(
              res.data.previousPayment,
              FormatSpaceType.DOT
            );

            let price = res.data.monthlyPrice.toString();
            res.data.monthlyPrice = price.slice(0, -2) + " kr";

            commit(mutationStringUser.SET_SUBSCRIPTION_PLAN, res.data);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      } catch (e) {
        console.error(e);
      }
    });
  },

  // @ts-ignore
  async fetchInitialUserInfo({ dispatch, commit }): Promise<IUser> {
    let user = dispatch("getCurrentUser");

    dispatch("getUserSubscription");

    dispatch("getBookingSettingsIntervals");
    dispatch("getBookingSettings");
    dispatch("getAllWorkingDays");
    dispatch("getAppointmentTypes");
    dispatch("getCategories");
    dispatch("getFirebaseUserAccount");

    Promise.all([user]).then(() => {
      commit("setHasResolvedUser", true);
      return new Promise((resolve) => {
        resolve(user);
      });
    });
  },

  async registerUser({ commit }, payload: IUserDTO): Promise<IUserDTO> {
    let header: AxiosRequestConfig = await authConfig();

    return new Promise((resolve, reject) => {
      axios
        .post(APIURL2 + "User/current", payload, header)
        .then((res) => {
          commit(mutationStringUser.setUser, res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },

  async patchUser({ commit, dispatch }, payload: Partial<IUserDTO>) {
    let patchPayload = createPatchObject(payload);

    let header: AxiosRequestConfig = await authConfig();
    return new Promise((resolve, reject) => {
      axios
        .patch(APIURL2 + "User/current", patchPayload, header)
        .then((res) => {
          console.log("Payload: ", patchPayload);
          commit(mutationStringUser.updateUser, payload);
          dispatch(
            actionStringSnackbar.postSnackbarMessage,
            i18n.t("messages.updated").toString()
          );
          resolve(res.data);
        })
        .catch((err) => {
          dispatch(
            actionStringSnackbar.postSnackbarMessage,
            i18n.t("user.updateFailed").toString()
          );
          reject(err);
        });
    });
  },

  async updateUser({ commit, dispatch }, payload: Partial<IUserDTO>) {
    let header: AxiosRequestConfig = await authConfig();
    return new Promise((resolve, reject) => {
      axios
        .put(APIURL2 + "User/current", payload, header)
        .then((res) => {
          console.log("Payload: ", payload);
          commit(mutationStringUser.updateUser, payload);
          dispatch(
            actionStringSnackbar.postSnackbarMessage,
            i18n.t("messages.updated").toString()
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err.response);
          if (
            err.response.data ==
            "Cannot set sms verification to true when there is no subscription."
          ) {
            dispatch(
              actionStringSnackbar.postSnackbarMessage,
              i18n.t("user.smsVerificationError").toString()
            );
          } else if (err.response.data == "Booking link exists") {
            dispatch(
              actionStringSnackbar.postSnackbarMessage,
              i18n.t("user.bookingLinkExists").toString()
            );
          } else {
            dispatch(
              actionStringSnackbar.postSnackbarMessage,
              i18n.t("user.updateFailed").toString()
            );
          }
          reject(err);
        });
    });
  },

  async updateUserProfile({ commit, dispatch }, payload: Partial<IUserDTO>) {
    let header: AxiosRequestConfig = await authConfig();
    return new Promise((resolve, reject) => {
      axios
        .put(APIURL2 + "User/current/profile", payload, header)
        .then((res) => {
          // commit(mutationStringUser.updateUser, payload);
          dispatch("getCurrentUser");
          dispatch(
            actionStringSnackbar.postSnackbarMessage,
            "Innstillinger oppdatert."
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err.response);
          if (
            err.response.data ==
            "Cannot set sms verification to true when there is no subscription."
          ) {
            dispatch(
              actionStringSnackbar.postSnackbarMessage,
              "Kan ikke bruke SMS verifikasjon uten abonnement."
            );
          } else if (err.response.data == "Booking link exists") {
            dispatch(
              actionStringSnackbar.postSnackbarMessage,
              "Denne bedriftsnavnet eksisterer fra før av. Ditt bedriftsnavn? Kontakt oss."
            );
          } else {
            dispatch(
              actionStringSnackbar.postSnackbarMessage,
              "Kunne ikke oppdatere bruker."
            );
          }
          reject(err);
        });
    });
  },

  async getCurrentUser({ commit, dispatch }): Promise<IUserDTO> {
    let header: AxiosRequestConfig = await authConfig();
    return new Promise((resolve, reject) => {
      axios
        .get(APIURL2 + "User/current", header)
        .then((res) => {
          commit(mutationStringUser.setUser, res.data);
          resolve(res.data);
        })
        .catch((err) => {
          let dialog: IDialog = {
            text: i18n.t("user.loginError").toString(),
          };
          dispatch(DialogActions.AddToDialogQueue, dialog);
          logout();
          reject(err);
        });
    });
  },

  async updateUserImage({ commit, dispatch }, payload: any): Promise<boolean> {
    let header: AxiosRequestConfig = await authConfig();
    return new Promise((resolve) => {
      axios
        .put(APIURL2 + "User/Picture", payload, {
          headers: {
            Authorization: header.headers.Authorization,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(() => {
          dispatch(
            actionStringSnackbar.postSnackbarMessage,
            i18n.t("user.imageUploaded").toString()
          );
          dispatch(actionStringUser.getCurrentUser).then(() => {});
          return resolve(true);
        })
        .catch((err) => {
          dispatch(
            actionStringSnackbar.postSnackbarMessage,
            i18n.t("user.imageUploadError").toString()
          );
          console.error(err);
          return resolve(false);
        });
    });
  },

  getUserAsAnonymous({ commit }, adminId: string): Promise<IUserDTO> {
    return new Promise((resolve, reject) => {
      axios
        .get(APIURL2 + "User/" + adminId)
        .then((res) => {
          commit(mutationStringUser.setUserToBook, res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },
  getUserByNameAsAnonymous({ commit }, companyName: string): Promise<IUserDTO> {
    return new Promise((resolve, reject) => {
      axios
        .get(APIURL2 + "User/ByUser/" + companyName)
        .then((res) => {
          commit(mutationStringUser.setUserToBook, res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },
};

export const user = {
  actions,
  mutations,
  getters,
  state,
};
