
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import AuditLogger, { AuditLoggerType } from "../../mixins/AuditLogger";
import { Getter } from "vuex-class";
import { PermissionArea, PermissionLevel } from "../../store/userPermissions";

interface NavigationItem {
  alt: string;
  text: string;
  image: string;
  image2?: string;
  route?: string;
  icon: string;
  action?: () => void;
  children?: any[];
  onlySmallMenu?: boolean;
}

@Component({
  name: "NavigationDrawer",
  mixins: [AuditLogger],
})
export default class NavigationDrawer extends Vue implements AuditLoggerType {
  @Prop({ required: true }) value!: boolean;
  @Prop({ required: true }) width!: number;
  @Getter("hasPermission", { namespace: "userPermissions" })
  hasPermission!: Function;

  selectedItem = null;

  // Implement the mixin method to satisfy TypeScript
  logAudit!: (message: string, action?: number, type?: number) => void;

  get drawerVisible(): boolean {
    return this.value;
  }

  set drawerVisible(value: boolean) {
    this.$emit("input", value);
  }

  get navigationItems(): NavigationItem[] {
    const items: NavigationItem[] = [];

    // Only add appointments and clients related items if user has permission
    if (
      this.hasPermission(
        PermissionArea.APPOINTMENTS_AND_CLIENTS,
        PermissionLevel.READ
      )
    ) {
      items.push(
        {
          alt: this.$t(this.$ts.mobileHeader.customers).toString(),
          text: this.$t(this.$ts.mobileHeader.customers).toString(),
          image: "../img/customers.png",
          image2: "../img/white-icons/customers2.png",
          route: "/kunder",
          icon: "account_circle",
        },
        {
          alt: this.$t(this.$ts.mobileHeader.orders).toString(),
          children: [],
          text: this.$t(this.$ts.mobileHeader.overview).toString(),
          image: "../img/overview.png",
          route: "/bestillinger",
          icon: "event_note",
        }
        // {
        //   alt: this.$t(this.$ts.mobileHeader.log).toString(),
        //   text: this.$t(this.$ts.mobileHeader.log).toString(),
        //   image: "../img/commerce.png",
        //   image2: "../img/commerce.png",
        //   route: "/logg",
        //   icon: "post",
        // }
      );
    }

    // Always show settings
    items.push({
      alt: this.$t(this.$ts.mobileHeader.settings).toString(),
      text: this.$t(this.$ts.mobileHeader.settings).toString(),
      onlySmallMenu: true,
      image: "../img/settings.png",
      action: () => this.openSettings(),
      icon: "calendar_today",
    });

    // Only add statistics if user has permission
    if (
      this.hasPermission(
        PermissionArea.STATISTICS_AND_FINANCES,
        PermissionLevel.READ
      )
    ) {
      items.push({
        alt: this.$t(this.$ts.mobileHeader.statistics).toString(),
        text: this.$t(this.$ts.mobileHeader.statistics).toString(),
        image: "../img/increasing-chart.png",
        route: "/statistikk",
        icon: "insert_chart",
      });
    }

    return this.width > 800
      ? items.filter((item) => !item.onlySmallMenu)
      : items;
  }

  handleItemClick(item: NavigationItem): void {
    if (item.action) {
      item.action();
    } else if (item.route) {
      this.logAudit(" went to route from navigation drawer: " + item.route);
      this.$router.push(item.route).catch(() => {});
    }
  }

  openSettings(): void {
    this.logAudit(" opened settings dialog from navigation drawer");
    this.drawerVisible = false;
    this.$emit("settings-click");
  }
}
