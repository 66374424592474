
import Vue from "vue";
import Component from "vue-class-component";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { IAppointmentTypeDTO, IStaffMemberDTO } from "@shared/types";
import { Prop } from "vue-property-decorator";
import Loader from "../../../global/Loader.vue";
import { getToken } from "@/Utilities/authConfig";
import { DialogType, IDialog } from "@/store/dialog";

@Component({
  name: "StaffMemberGoogleCalendarSettings",
  components: { Loader },
})
export default class StaffMemberGoogleCalendarSettings extends Vue {
  public loading: boolean = false;
  @Action public hasOauthToken: Function;
  @Action public removeOAuthToken: Function;
  @Action public addToDialogQueue: Function;
  @Prop({}) staffMember: IStaffMemberDTO;

  public e6 = 1;
  public loadingToken = true;
  public hasOAuth = false;
  public token = "";
  public attempts = 0;
  public interval = null;

  async removeSyncConfirm() {
    await this.removeOAuthToken(this.staffMember);
    this.hasOAuth = false;
    this.e6 = 1;
    await this.listenToLogin();
  }

  async removeSync() {
    let dialog: IDialog = {
      text: this.$t(
        this.$ts.staffMemberGoogleCalendar.confirmRemoveSync
      ).toString(),
      type: DialogType.Choice,
      action: this.removeSyncConfirm,
    };
    this.addToDialogQueue(dialog);
  }

  async listenToLogin() {
    this.interval = setInterval(async (x) => {
      let res = await this.hasOauthToken(this.staffMember);
      console.log("Res: ", res);
      if (res.data) {
        if (this.attempts == 0) {
          this.e6 = 4;
        } else {
          this.e6 = 3;
        }
        console.log("has oauth");
        this.hasOAuth = true;
        clearInterval(this.interval);
      } else {
        this.attempts++;
      }
    }, 1000);
  }
  async created() {
    await this.fetchToken();

    await this.listenToLogin();
  }

  async destroyed() {
    clearInterval(this.interval);
  }

  async fetchToken() {
    this.loadingToken = true;
    let token = await getToken();
    this.token = token;
    this.loadingToken = false;
  }
}
