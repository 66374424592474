
        "use strict";

        import Vue from "vue";
        import { Component } from 'vue-property-decorator';

//* Component definition ************************************************************

        @Component({
            name: "contact-footer"
        })

//* Class ***************************************************************************

        export default class ContactFooter extends Vue {
        }

    