
    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
    import {Action, Getter} from "vuex-class";
    import {onboardingProgressType} from "@/types/OnboardingProgress";
    import {IUser} from "../../../store/user";
    import {ICompany} from "@/store/company";

    @Component({
        name: 'Register-appointment-distribution',
    })
    export default class RegisterUser extends Vue {
        @Prop() public el;

        public workHourSplit:Array<number> = [15, 30, 60, 90];
        public appointmentDistributionInMinutes:Number = 60;
        public selectedHourFormat:Number = 60;
        public updatedUser:Object | null = null;

        @Action public updateCompanyAppointmentDistribution:Function;
        // @Action public getCompany:Function;
        @Action public getCurrentUser:Function;
        @Action public patchUser:Function;
        @Getter public user:IUser;
        // @Getter public company:ICompany;

        @Watch('el')
        checkElValue(newval){
            //Check onboardingprogress
            if(newval == 3){
                this.getCurrentUser();
            }
        }

    }
