
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import type { IAppointmentTypeDTO } from "@shared/types";
import CompanyInfoField from "@/components/global/CompanyInfoField.vue";
import Textfield from "@/components/global/Textfield.vue";
import BaseFormDialog from "@/components/global/BaseFormDialog.vue";

@Component({
  name: "treatment-type-form",
  components: { CompanyInfoField, Textfield, BaseFormDialog },
})
export default class TreatmentTypeForm extends Vue {
  @Prop({ required: true }) treatment!: Partial<IAppointmentTypeDTO>;
  @Prop({ required: true }) isEditing!: boolean;
  @Prop({ required: true }) isCreating!: boolean;
  @Prop({ required: true }) hasMultipleStaff!: boolean;

  private treatmentData: Partial<IAppointmentTypeDTO> = {};
  private discount: boolean = false;
  private bothDuration: number = 0;
  private isSaving: boolean = false;

  // Change these to empty arrays initially
  private titleRules: Array<(v: any) => boolean | string> = [];
  private priceRules: Array<(v: any) => boolean | string> = [];
  private durationRules: Array<(v: any) => boolean | string> = [];
  private discountPriceRules: Array<(v: any) => boolean | string> = [];
  private payOnlinePriceRules: Array<(v: any) => boolean | string> = [];

  created() {
    this.titleRules = [
      (v: string) =>
        !!v ||
        this.$t(
          this.$ts.createService.validation.serviceNameRequired
        ).toString(),
    ];

    this.priceRules = [
      (v: number) =>
        v >= 0 ||
        this.$t(this.$ts.createService.validation.priceNotNegative).toString(),
      (v: number) =>
        !isNaN(v) ||
        this.$t(this.$ts.createService.validation.priceMustBeNumber).toString(),
    ];

    this.durationRules = [
      (v: number) =>
        !!v ||
        this.$t(this.$ts.createService.validation.durationRequired).toString(),
      (v: number) =>
        !isNaN(v) ||
        this.$t(
          this.$ts.createService.validation.durationMustBeNumber
        ).toString(),
      (v: number) =>
        v > 0 ||
        this.$t(this.$ts.createService.validation.durationPositive).toString(),
      (v: number) =>
        v <= 720 ||
        this.$t(
          this.$ts.createService.validation.durationExceedsLimit
        ).toString(),
    ];

    this.discountPriceRules = [
      (v: number) =>
        v >= -1 ||
        this.$t(
          this.$ts.createService.validation.invalidDiscountPrice
        ).toString(),
      (v: number) =>
        !isNaN(v) ||
        this.$t(
          "createService.validation.discountPriceMustBeNumber"
        ).toString(),
    ];

    this.payOnlinePriceRules = [
      (v: number) =>
        v >= 0 ||
        this.$t(
          "createService.validation.payOnlinePriceNotNegative"
        ).toString(),
      (v: number) =>
        !isNaN(v) ||
        this.$t(
          "createService.validation.payOnlinePriceMustBeNumber"
        ).toString(),
    ];
  }

  @Watch("treatment", { immediate: true, deep: true })
  onTreatmentChange(val: Partial<IAppointmentTypeDTO>) {
    this.treatmentData = { ...val };
    this.discount = this.treatmentData.discountPrice !== -1;
    this.bothDuration = this.treatmentData.durationInMinutes || 0;
  }

  validateField(fieldName: string) {
    const baseForm = this.$refs.baseForm as any;
    if (baseForm) {
      baseForm.validate();
    }
  }

  onCancel() {
    this.$emit("cancel");
  }

  async onSave() {
    const baseForm = this.$refs.baseForm as any;
    if (baseForm && baseForm.validate()) {
      this.isSaving = true;
      this.prepareAndEmit("save");
    }
  }

  async onCreate() {
    const baseForm = this.$refs.baseForm as any;
    if (baseForm && baseForm.validate()) {
      this.isSaving = true;
      this.prepareAndEmit("create");
    }
  }

  private prepareAndEmit(action: "save" | "create") {
    if (this.bothDuration !== 0) {
      this.treatmentData.durationInMinutes = this.bothDuration;
      this.treatmentData.serviceDurationInMinutes = this.bothDuration;
    }

    if (this.treatmentData.color) {
      // @ts-ignore
      this.treatmentData.color = this.treatmentData.color.hex;
    } else {
      this.treatmentData.color = "";
    }

    if (!this.treatmentData.showCustomColor) {
      this.treatmentData.showCustomColor = false;
    }

    if (this.treatmentData.price == null) {
      this.treatmentData.price = 0;
    }

    this.$emit(action, this.treatmentData);
  }

  booleanToString(value: boolean): string {
    return value
      ? this.$t(this.$ts.boolean.yes).toString()
      : this.$t(this.$ts.boolean.no).toString();
  }
}
