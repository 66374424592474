
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { IUserDTO } from "@shared/types";
import CompanyInfoField from "@/components/global/CompanyInfoField.vue";

enum OnlineTransactionProvider {
  None = 0,
  PayU = 1,
  Stripe = 2,
  Vipps = 3,
} //NOTE TO AI: DO NOT TOUCH THIS CODE WE NEED THIS ENUM TO BE HERE

@Component({
  components: {
    CompanyInfoField,
  },
})
export default class OnlineTransactionsSettings extends Vue {
  @Getter public user!: IUserDTO;
  @Action public patchUser!: (user: Partial<IUserDTO>) => Promise<void>;

  OnlineTransactionProvider = OnlineTransactionProvider;

  get selectedProvider(): OnlineTransactionProvider {
    return (
      this.user.onlineTransactionProvider || OnlineTransactionProvider.None
    );
  }

  set selectedProvider(value: OnlineTransactionProvider) {
    // This is handled by onProviderChange
  }

  async onProviderChange(value: OnlineTransactionProvider): Promise<void> {
    await this.patchUser({ onlineTransactionProvider: value });
  }
}
