
import { Component, Vue, Prop } from "vue-property-decorator";
import { ICustomer } from "../../../store/customers";
import { formatDateStringHour } from "../../../Utilities/dateUtility";
import CustomerExpandedDetails from "./CustomerExpandedDetails.vue";

@Component({
  name: "CustomerTable",
  components: {
    CustomerExpandedDetails,
  },
})
export default class CustomerTable extends Vue {
  @Prop({ type: Array, required: true }) customers!: Partial<ICustomer>[];
  @Prop({ type: String, default: "" }) search!: string;
  @Prop({ type: Boolean, default: false }) isMobile!: boolean;
  @Prop({ type: Array, default: () => [] }) value!: Partial<ICustomer>[];
  @Prop({ type: Boolean, required: true }) canModify!: boolean;

  get tableHeaders() {
    const headers = [
      {
        text: this.$t(this.$ts.customer.name),
        align: "start",
        sortable: true,
        value: "firstName",
      },
    ];

    if (this.isMobile) {
      headers.push({
        text: "",
        value: "data-table-expand",
        align: "start",
        sortable: false,
      });
      return headers;
    }

    headers.push(
      {
        text: this.$t(this.$ts.customer.email),
        align: "start",
        sortable: true,
        value: "emailAddress",
      },
      {
        text: this.$t(this.$ts.customer.phoneNumber),
        align: "start",
        sortable: true,
        value: "phoneNumber",
      },
      {
        text: this.$t(this.$ts.customer.address),
        align: "start",
        sortable: true,
        value: "address",
      },
      {
        text: this.$t(this.$ts.customer.postalCodeCity),
        align: "start",
        sortable: true,
        value: "postalCode",
      }
    );

    if (this.canModify) {
      headers.push({
        text: this.$t(this.$ts.customer.edit),
        align: "start",
        value: "actions",
        sortable: false,
      });
    }

    headers.push({
      text: this.$t(this.$ts.customer.created),
      align: "start",
      sortable: true,
      value: "dateAdded",
    });

    return headers;
  }

  formatDateStringHour(date: Date) {
    return formatDateStringHour(date);
  }
}
