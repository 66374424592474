import { store } from "../store/store";
import router from "@/router";
import { logout } from "@/Utilities/authConfig";
import firebase from "firebase/app";
import { IDialog } from "../store/dialog";
import { i18n } from "@/main";

import * as Sentry from "@sentry/vue";

import { IAuditLogDTO, IUserDTO } from "@shared/types";

function isMobile() {
  return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

function isiPad() {
  return /iPad/i.test(navigator.userAgent);
}

async function onStateChanged(user) {
  let device = "Desktop";
  if (isMobile()) {
    device = "Mobile";
  }
  if (isiPad()) {
    device = "iPad";
  }

  if (user) {
    store.commit("setFirebaseUser", user);
    store.commit("setHasResolvedUser", false);

    store.commit("setIsLoggedIn", true);

    Sentry.setUser({
      id: user.uid,
      email: user.email,
      username: user.displayName || user.email,
    });

    if (isNewUser(user)) {
      //We may get name and picture from google/facebook login eventually.
      let newUser: Partial<IUserDTO> = {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        emailAddress: user.email,
        profilePictureUrl: "",
        appointmentDistributionInMinutes: 60,
        sector: 0,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        language: i18n.locale,
      };

      if (
        window.location.toString().includes("citaro") ||
        window.location.toString().includes("localhost")
      ) {
        //@ts-ignore
        newUser.defaultPhoneNumberCountryCode = 57; // set locale to Spanish if "citaro" is in the domain. as a temp solution for the new domain
        newUser.timeZone = "America/Bogota"; // set locale to Spanish if "citaro" is in the domain. as a temp solution for the new domain
        newUser.timeFormat = "12h"; // set locale to Spanish if "citaro" is in the domain. as a temp solution for the new domain
      }

      try {
        await store.dispatch("registerUser", newUser);
        await store.dispatch("fetchInitialUserInfo");
        router.replace("/onboarding");

        store.commit("setIsNewUser", false);
      } catch (e) {
        await logout();
        router.replace("/");
        let dialog: IDialog = {
          text: "Kunne ikke registrere. Vennligst kontakt oss for hjelp.",
        };
        store.commit("addToDialogQueue", dialog);
        console.error(e);
      }
    } else {
      await store.dispatch("fetchInitialUserInfo");

      let lastUrl = localStorage.getItem("lasturl");
      if (lastUrl != null && lastUrl != "" && lastUrl != "undefined") {
        if (lastUrl == "/") {
          router.replace("/admin");
        } else {
          // router.replace(lastUrl);
        }
      } else {
        router.replace("/admin");
      }
    }
  } else {
    store.commit("setHasResolvedUser", true);
    store.commit("setIsLoggedIn", false);

    Sentry.setUser(null);

    let metaTags: any = router.currentRoute.meta;

    if (metaTags.requiresAuth == true) {
      router.replace("/");
    }
  }
}

setTimeout(() => {
  firebase.auth().onAuthStateChanged(function (user) {
    onStateChanged(user);
  });
}, 1000);

function isNewUser(user) {
  return store.getters.isNewUser;
}
