
import Vue from "vue";
import Component from "vue-class-component";
import { Getter, Action } from "vuex-class";
import { IStaffMemberDTO, IUserDTO } from "@shared/types";
import { formatDateStringHour } from "@/Utilities/dateUtility";

@Component({
  name: "EmailListSettings",
})
export default class EmailListSettings extends Vue {
  public activateEmailList: boolean = false;
  public isLoading: boolean = false;

  @Action public postSnackbarMessage!: Function;
  @Action public getEmailList!: Function;
  @Getter public emailList!: IStaffMemberDTO[];
  @Getter user!: IUserDTO;
  @Action patchUser!: Function;

  public headers = [
    { text: this.$t(this.$ts.email), sortable: true, value: "email" },
    { text: this.$t(this.$ts.name), sortable: true, value: "name" },
    { text: this.$t(this.$ts.signedUp), sortable: true, value: "signupDate" },
  ];

  async created() {
    this.activateEmailList = this.user.activateEmailList;
    this.isLoading = true;
    await this.getEmailList();
    this.isLoading = false;
  }

  async saveEmailListSetting(value: boolean): Promise<void> {
    try {
      await this.patchUser({ activateEmailList: value });
    } catch (error) {
      console.error(error);
      // Reset to previous value on error
      this.activateEmailList = this.user.activateEmailList;
      this.postSnackbarMessage(
        this.$t(this.$ts.errors.savingFailed).toString()
      );
    }
  }

  formatDateStringHour(date: Date): string {
    return formatDateStringHour(date);
  }

  booleanToOnOff(value: boolean): string {
    return value
      ? this.$t(this.$ts.boolean.on).toString()
      : this.$t(this.$ts.boolean.off).toString();
  }
}
