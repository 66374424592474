var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('CompanyInfoField',{attrs:{"ignore-mobile-layout":true}},[(!_vm.useMultipleServices)?_c('v-autocomplete',{ref:"singleselect",staticClass:"format-box",attrs:{"prepend-icon":"shopping_basket","value":_vm.selectedServiceId,"items":_vm.appointmentTypeItems,"item-value":"id","placeholder":_vm.$t('placeHolder.typeToSearch'),"outlined":"","dense":"","filter":_vm.selectServicesFilter},on:{"change":_vm.onChangeService},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticStyle:{"color":"red","font-size":"24px"}},[_vm._v("*")]),_vm._v(_vm._s(_vm.$t(_vm.$ts.service))+" ")]},proxy:true},{key:"item",fn:function(data){return [(data.item.id === -1)?_c('div',[_c('v-icon',[_vm._v("mdi-calendar-remove")]),_vm._v(" "+_vm._s(data.item.title)+" ")],1):_c('div',[(
            data.item != null &&
            data.item.title != null &&
            data.item.title.length > 0
          )?_c('div',[_vm._v(" "+_vm._s(data.item.title)+" ("+_vm._s(data.item.durationInMinutes)+" min | "+_vm._s(_vm.$t(_vm.$ts.currency))+_vm._s(data.item.price)+") ")]):_vm._e()])]}},{key:"selection",fn:function(data){return [(data.item.id === -1)?_c('div',[_vm._v(" "+_vm._s(data.item.title)+" ")]):_c('div',[(
            data.item != null &&
            data.item.title != null &&
            data.item.title.length > 0
          )?_c('div',[_vm._v(" "+_vm._s(data.item.title)+" ")]):_vm._e()])]}},{key:"no-data",fn:function(){return undefined},proxy:true}],null,false,2978952455)}):_vm._e(),(_vm.useMultipleServices)?_c('v-autocomplete',{ref:"multipleselect",staticClass:"format-box",attrs:{"multiple":"","prepend-icon":"shopping_basket","value":_vm.selectedServiceIds,"filter":_vm.selectServicesFilter,"items":_vm.appointmentTypeItems,"item-value":"id","outlined":"","dense":"","placeholder":_vm.$t('placeHolder.typeToSearch')},on:{"change":_vm.onChangeServiceMultiple},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticStyle:{"color":"red","font-size":"24px"}},[_vm._v("*")]),_vm._v(_vm._s(_vm.$t(_vm.$ts.service))+" ")]},proxy:true},{key:"item",fn:function(data){return [(data.item.id === -1)?_c('div',[_c('v-icon',[_vm._v("mdi-calendar-remove")]),_vm._v(" "+_vm._s(data.item.title)+" ")],1):_c('div',[(
            data.item != null &&
            data.item.title != null &&
            data.item.title.length > 0
          )?_c('div',[_vm._v(" "+_vm._s(data.item.title)+" ("+_vm._s(data.item.durationInMinutes)+" min | "+_vm._s(_vm.$t(_vm.$ts.currency))+_vm._s(data.item.price)+") ")]):_vm._e()])]}},{key:"selection",fn:function(data){return [_c('v-chip',[_vm._v(" "+_vm._s(data.item.title)+" ")])]}}],null,false,4228896780)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }