
import { Component, Vue, Prop } from "vue-property-decorator";
import { toolbarConfig } from "@/pages/admin/toolbarconfig";

@Component
export default class SettingsToolbar extends Vue {
  @Prop({ required: true }) title!: string;
  @Prop({ default: () => [] }) actions!: Array<{
    handler: string;
    tooltip: string;
    icon: string;
  }>;
  @Prop({ required: true }) currentComponent!: any; // TODO: Wouldbe nice with types

  // Getter for current toolbar actions based on the current component
  get currentToolbarActions() {
    return toolbarConfig[this.currentComponent?.name]?.actions || [];
  }

  // Handle toolbar actions
  handleAction(handlerName: string) {
    const component = this.$refs.currentComponentRef;
    if (component && typeof component[handlerName] === "function") {
      component[handlerName]();
    } else {
      console.warn(
        `Handler ${handlerName} is not defined on ${this.currentComponent?.name}`
      );
    }
  }
}
