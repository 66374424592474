
import { Component, Vue } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import { IAuditLogDTO, IStaffMemberDTO, IUserDTO } from "@shared/types";

@Component({
  name: "CalendarStyleSettings",
})
export default class CalendarStyleSettings extends Vue {
  public showInformationBox: boolean = false;
  public informationBoxText: string = "";
  public hideIrrelevantDaysInCalendar: boolean = false;
  public intervalMinutes: number = 0;

  public showStaffMemberInEvent: number = 0;
  public showTimeInEvent: boolean | number = false;
  public showServiceNameInEvent: boolean | number = false;

  @Action public postSnackbarMessage!: Function;
  @Getter public staffMembers!: IStaffMemberDTO[];
  @Getter user!: IUserDTO;
  @Action patchUser!: Function;
  @Action postAuditLog!: Function;

  intervalMinutesOptions = [90, 60, 30, 15];

  private fieldMessages: {};

  created() {
    this.showInformationBox = this.user.showInformationBox;
    this.informationBoxText = this.user.informationBoxText;
    this.hideIrrelevantDaysInCalendar = this.user.hideIrrelevantDaysInCalendar;
    this.intervalMinutes = this.user.intervalMinutes;
    this.showStaffMemberInEvent = this.user.showStaffMemberInEvent;
    this.showTimeInEvent = this.user.showTimeInEvent;
    this.showServiceNameInEvent = this.user.showServiceNameInEvent;

    this.fieldMessages = {
      hideIrrelevantDaysInCalendar: this.$t(
        "fieldsettings.hideDaysOutsideWorkingHours"
      ),
      showServiceNameInEvent: this.$t(
        this.$ts.fieldsettings.showServiceNameInEvent
      ),
      showTimeInEvent: this.$t(this.$ts.fieldsettings.showTimeInEvent),
      showStaffMemberInEvent: this.$t(this.$ts.fieldsettings.showStaffInEvent),
    };
  }

  async saveField(field: string, newValue: boolean | number): Promise<void> {
    try {
      const oldValue = this.user[field];
      if (newValue === oldValue) return;

      // Create audit log
      const message =
        field === "showStaffMemberInEvent"
          ? ` endret innstilling '${
              this.fieldMessages[field]
            }' til ${this.showStaffMemberInEventLabel(newValue as number)}`
          : ` endret innstilling '${
              this.fieldMessages[field]
            }' til ${this.booleanToOnOff(newValue)}`;

      await this.postAuditLog({
        action: 1,
        type: 2,
        comment: "",
        message,
        hideForUser: false,
      });

      // Save the field
      await this.patchUser({ [field]: newValue });
    } catch (error) {
      console.error(error);
      // Reset to previous value on error
      this[field] = this.user[field];
    }
  }

  showStaffMemberInEventLabel(value: number): string {
    if (value === 0) {
      return this.$t(this.$ts.boolean.off).toString();
    }
    if (value === 1) {
      return this.$t(this.$ts.name).toString();
    }
    if (value === 2) {
      return this.$t(this.$ts.color).toString();
    }
    return "";
  }

  booleanToOnOff(value: boolean | number): string {
    return value || value === 1
      ? this.$t(this.$ts.boolean.on).toString()
      : this.$t(this.$ts.boolean.off).toString();
  }

  get isOldDomain(): boolean {
    return window.location.toString().includes("vergba");
  }

  get hasMultipleStaff(): boolean {
    return this.staffMembers.length > 1;
  }
}
