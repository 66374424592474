// @ts-ignore
import { GetterTree, MutationTree, ActionTree } from "vuex";
// @ts-ignore
import axios, { AxiosRequestConfig } from "axios";
import { APIURL2 } from "@/main";
import { mutationStringUser } from "@/store/user";
import authConfig, { AuthConfig } from "@/Utilities/authConfig";
import { IServiceStatDTO } from "@shared/types";

export interface IEarnedThisAndPreviousYear {
  current: Array<number>;
  previous: Array<number>;
}

// Interface
export interface statisticState {
  moneyMade: number | null;
  appointmentsDone: number | null;
  earnedThisAndPreviousYear: IEarnedThisAndPreviousYear | null;
  serviceStats: IServiceStatDTO[];
  customerStats: IServiceStatDTO[];
}

// Mutation enums
export const enum mutationStringStatistics {
  setMoneyMade = "setMoneyMade",
  setAppointmentsDone = "setAppointmentsDone",
  SET_EARNED_THIS_AND_PREVIOUS_YEAR = "setEearnedThisAndPreviousYear",
}
export const enum actionStringStatistics {
  GET_EARNED_THIS_AND_PREVIOUS_YEAR = "getEearnedThisAndPreviousYear",
  GET_MONEY_MADE = "getMoneyMade",
  GET_APPOINTMENTS_DONE = "getAppointmentsDone",
}
export const enum getterStringStatistics {
  EARNED_THIS_AND_PREVIOUS_YEAR = "earnedThisAndPreviousYear",
  MONEY_MADE = "moneyMade",
  APPOINTMENTS_DONE = "appointmentsDone",
}
export const state: statisticState = {
  moneyMade: null,
  appointmentsDone: null,
  earnedThisAndPreviousYear: null,
  serviceStats: null,
  customerStats: null,
};

export const getters: GetterTree<any, statisticState> = {
  moneyMade: (state) => state.moneyMade,
  appointmentsDone: (state) => state.appointmentsDone,
  earnedThisAndPreviousYear: (state) => state.earnedThisAndPreviousYear,
  serviceStats: (state) => state.serviceStats,
  customerStats: (state) => state.customerStats,
};

export const mutations: MutationTree<statisticState> = {
  setMoneyMade(state, payload: number) {
    state.moneyMade = payload;
  },
  setAppointmentsDone(state, payload: number) {
    state.appointmentsDone = payload;
  },
  setEearnedThisAndPreviousYear(state, payload: IEarnedThisAndPreviousYear) {
    state.earnedThisAndPreviousYear = payload;
  },
  setServiceBookedStats(state, payload: IServiceStatDTO[]) {
    state.serviceStats = payload;
  },
  setCustomerBookedStats(state, payload: IServiceStatDTO[]) {
    state.customerStats = payload;
  },
};

export const actions: ActionTree<statisticState, any> = {
  async getMoneyMade({ commit }, payload: number): Promise<number> {
    let header: AxiosRequestConfig = await authConfig();
    return new Promise((resolve, reject) => {
      axios
        .get(APIURL2 + "Statistics/current/moneyMade?month=" + payload, header)
        .then((res) => {
          commit(mutationStringStatistics.setMoneyMade, res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  async getAppointmentsDone({ commit }, payload: number): Promise<number> {
    let header: AxiosRequestConfig = await authConfig();
    return new Promise((resolve, reject) => {
      axios
        .get(
          APIURL2 + "Statistics/current/appointments?month=" + payload,
          header
        )
        .then((res) => {
          commit(mutationStringStatistics.setAppointmentsDone, res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  async getEearnedThisAndPreviousYear({ commit }) {
    let header: AxiosRequestConfig = await authConfig();
    return new Promise((resolve, reject) => {
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      axios
        .get(
          APIURL2 +
            "Statistics/current/moneyMade/splitByMonth?year=" +
            currentYear,
          header
        )
        .then((res) => {
          commit(
            mutationStringStatistics.SET_EARNED_THIS_AND_PREVIOUS_YEAR,
            res.data
          );
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  async getServiceStats({ commit }) {
    let header: AxiosRequestConfig = await authConfig();
    return new Promise((resolve, reject) => {
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      axios
        .get(APIURL2 + "Statistics/current/servicesBooked/", header)
        .then((res) => {
          commit("setServiceBookedStats", res.data);
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  async getCustomerStats({ commit }) {
    try {
      let header: AxiosRequestConfig = await authConfig();
      let res = await axios.get(
        APIURL2 + "Statistics/current/customerBooked/",
        header
      );
      commit("setCustomerBookedStats", res.data);
      return res;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
};

export const statistics = {
  actions,
  mutations,
  getters,
  state,
};
