
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import AppointmentMenu from "../../AppointmentMenu.vue";
import AppointmentSummary from "@/components/global/AppointmentSummary.vue";

@Component({
  components: {
    AppointmentMenu,
    AppointmentSummary,
  },
})
export default class EventMenu extends Vue {
  @Prop({ default: null }) selectedEvent!: any; // Replace 'any' with the correct type
  @Prop({ required: true }) selectedOpen!: boolean;
  @Prop({ required: true }) selectedElement!: Element | null;
  @Getter("canWriteAppointmentsAndClients", { namespace: "userPermissions" })
  canWriteAppointmentsAndClients!: boolean;

  closeMenu() {
    this.$emit("update:selectedOpen", false);
  }

  gotoAppointment(appointmentNumber: number) {
    this.$emit("goto-appointment", appointmentNumber);
  }

  onSummaryClose() {
    this.$emit("summary-close");
  }
}
