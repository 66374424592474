// @ts-ignore
import axios, { AxiosRequestConfig } from "axios";
// @ts-ignore
import { GetterTree, MutationTree, ActionTree } from "vuex";
import { store } from "@/store/store";
import { APIURL2 } from "@/main";
import authConfig, { AuthConfig } from "@/Utilities/authConfig";
import { actionStringSnackbar } from "@/store/snackbar";
import { i18n } from "@/main"; // Import i18n for translations
import { IAppointmentTypeDTO } from "@shared/types";

// Interface
export interface appointmentTypeState {
  appointmentType: IAppointmentTypeDTO[];
  extraServices: IAppointmentTypeDTO[];
  categories: Array<IServiceCategory>;
}

export interface IServiceCategory {
  id: number;
  title: string;
  order: number;
}

// Mutation enums
export const enum mutationStringAppointmentType {
  setAppointmentType = "setAppointmentType",
  SET_APPOINTMENT_TYPE = "setAppointmentType",
  SET_EXTRA_SERVICES = "setExtraServices",
  SET_CATEGORIES = "setCategories",
}
export const enum actionStringAppointmentType {
  GET_APPOINTMENT_TYPES_AS_CUSTOMER = "getAppointmentTypesAsCustomer",
}
export const enum getterStringAppointmentType {
  APPOINTMENT_TYPE = "appointmentType",
}

export const state = {
  appointmentType: {},
  categories: {},
  extraServices: [],
};

export const getters: GetterTree<any, appointmentTypeState> = {
  appointmentType: (state) => state.appointmentType,
  categories: (state) => state.categories,
  extraServices: (state) => state.extraServices,
};

export const mutations: MutationTree<appointmentTypeState> = {
  setAppointmentType(state, payload: IAppointmentTypeDTO[]) {
    state.appointmentType = payload;
  },
  setExtraServices(state, payload: IAppointmentTypeDTO[]) {
    state.extraServices = payload;
  },

  setCategories(state, payload: Array<IServiceCategory>) {
    state.categories = payload;
  },
};

export const actions: ActionTree<appointmentTypeState, any> = {
  async getAppointmentTypes({ commit }, payload) {
    try {
      const header = await authConfig();
      const { data } = await axios.get(
        APIURL2 + "AppointmentType/current",
        header
      );

      const normalServices = data.filter((x) => !x.isExtraService);
      const extraServices = data.filter((x) => x.isExtraService);

      commit(
        mutationStringAppointmentType.SET_APPOINTMENT_TYPE,
        normalServices
      );
      commit(mutationStringAppointmentType.SET_EXTRA_SERVICES, extraServices);
    } catch (err) {
      console.log(err);
    }
  },

  async getCategories({ commit }) {
    try {
      const header = await authConfig();
      const { data } = await axios.get(
        APIURL2 + "AppointmentType/categories/current",
        header
      );

      const newobj = data.map((item) =>
        Object.assign(item, { isShowing: false })
      );
      commit(mutationStringAppointmentType.SET_CATEGORIES, newobj);
    } catch (err) {
      console.log(err);
    }
  },

  async changeCategoryOfService({ commit, dispatch }, payload) {
    try {
      const header = await authConfig();
      await axios.put(
        `${APIURL2}AppointmentType/changeCategoryOrder/current?id=${payload.serviceId}&categoryId=${payload.categoryId}&order=${payload.order}`,
        {},
        header
      );

      dispatch(
        actionStringSnackbar.postSnackbarMessage,
        i18n.t("messages.categoryChanged").toString()
      );
      await dispatch("getCategories");
    } catch (err) {
      console.log(err);
    }
  },

  async changeCategoryOrder({ commit, dispatch }, payload) {
    try {
      const header = await authConfig();
      await axios.put(
        `${APIURL2}AppointmentType/category/changeOrder/current?categoryId=${payload.categoryId}&order=${payload.order}`,
        {},
        header
      );

      dispatch(
        actionStringSnackbar.postSnackbarMessage,
        i18n.t("messages.categoryOrderChanged").toString()
      );
      await dispatch("getCategories");
    } catch (err) {
      console.log(err);
    }
  },

  // Add new appointmentType / treatment on an admin
  async changeServiceOrder({ commit, dispatch }, payload) {
    try {
      const header = await authConfig();
      await axios.put(
        `${APIURL2}AppointmentType/service/changeOrder/current?serviceId=${payload.serviceId}&order=${payload.order}`,
        {},
        header
      );

      dispatch(
        actionStringSnackbar.postSnackbarMessage,
        i18n.t("messages.serviceOrderChanged").toString()
      );
      await dispatch("getAppointmentTypes");
    } catch (err) {
      console.log(err);
    }
  },

  // Add new appointmentType / treatment on an admin
  async changeExtraService({ commit, dispatch }, payload) {
    try {
      const header = await authConfig();
      await axios.put(
        APIURL2 + "AppointmentType/changeExtraService/current",
        payload,
        header
      );
      await dispatch("getAppointmentTypes");
    } catch (err) {
      console.log(err);
    }
  },

  // Add new appointmentType / treatment on an admin
  async putCategory({ commit, dispatch }, payload: IServiceCategory) {
    try {
      const header = await authConfig();
      await axios.put(
        APIURL2 + "AppointmentType/category/current",
        payload,
        header
      );

      dispatch(
        actionStringSnackbar.postSnackbarMessage,
        i18n.t("messages.categoryUpdated").toString()
      );
      await dispatch("getCategories");
    } catch (err) {
      console.log(err);
    }
  },

  // Add new appointmentType / treatment on an admin
  async addNewCategory({ commit, dispatch }, newTreatment: IServiceCategory) {
    try {
      const header = await authConfig();
      await axios.post(
        APIURL2 + "AppointmentType/category/current",
        newTreatment,
        header
      );

      dispatch(
        actionStringSnackbar.postSnackbarMessage,
        i18n.t("messages.newCategoryAdded").toString()
      );
      await dispatch("getCategories");
    } catch (err) {
      console.log(err);
    }
  },

  async deleteCategory({ dispatch }, categoryId: number) {
    try {
      const header = await authConfig();
      await axios.delete(
        APIURL2 + "AppointmentType/category/current?id=" + categoryId,
        header
      );

      dispatch(
        actionStringSnackbar.postSnackbarMessage,
        i18n.t("messages.categoryDeleted").toString()
      );
      await dispatch("getCategories");
    } catch (err) {
      console.log(err);
    }
  },

  // Add new appointmentType / treatment on an admin
  async addNewAppointmentType(
    { commit, dispatch },
    newTreatment: IAppointmentTypeDTO
  ) {
    try {
      const header = await authConfig();
      await axios.post(
        APIURL2 + "AppointmentType/current",
        newTreatment,
        header
      );

      dispatch(
        actionStringSnackbar.postSnackbarMessage,
        i18n.t("messages.newServiceAdded").toString()
      );
      await dispatch("getAppointmentTypes");
    } catch (err) {
      console.log(err);
    }
  },

  async deleteAppointmentType({ dispatch }, appointmentTypeId: string) {
    try {
      const header = await authConfig();
      await axios.delete(
        APIURL2 + "AppointmentType/current?id=" + appointmentTypeId,
        header
      );

      dispatch(
        actionStringSnackbar.postSnackbarMessage,
        i18n.t("messages.serviceDeleted").toString()
      );
      await dispatch("getAppointmentTypes");
    } catch (err) {
      console.log(err);
    }
  },

  async editAppointmentType({ dispatch }, service: IAppointmentTypeDTO) {
    try {
      const header = await authConfig();
      await axios.put(APIURL2 + "AppointmentType/current", service, header);

      dispatch(
        actionStringSnackbar.postSnackbarMessage,
        i18n.t("messages.serviceUpdated").toString()
      );
      await dispatch("getAppointmentTypes");
    } catch (err) {
      console.log(err);
    }
  },

  async getAppointmentTypesAsCustomer({ commit }, userId) {
    try {
      const { data } = await axios.get(
        APIURL2 + "AppointmentType/asAnonymous?userId=" + userId
      );
      commit(mutationStringAppointmentType.SET_APPOINTMENT_TYPE, data);
    } catch (err) {
      console.log(err);
    }
  },
};

export const appointmentTypes = {
  actions,
  mutations,
  getters,
  state,
};
