
import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter } from "vuex-class";
import { IAuditLogDTO, ICustomFieldDTO, IUserDTO } from "@shared/types";
import ToolTip from "../../../ToolTip.vue";
import { VerificationType } from "@/store/user";
import { Watch } from "vue-property-decorator";

@Component({
  name: "BookingPageFieldSettings",
  components: { ToolTip },
})
export default class BookingPageFieldSettings extends Vue {
  @Getter user!: IUserDTO;
  @Action patchUser!: Function;
  @Action postAuditLog!: Function;
  @Action postCustomField!: Function;
  @Action putCustomField!: Function;
  @Action getCustomFields!: Function;
  @Getter customFields!: Array<ICustomFieldDTO>;

  public customerRequiredEmail: boolean = false;
  public customerRequiredEmailOptional: boolean = false;
  public customerRequiredPhoneNumber: boolean = false;
  public customerRequiredPhoneNumberOptional: boolean = false;
  public customerRequiredName: boolean = false;
  public customerRequiredAddress: boolean = false;
  public customerRequiredPostalCode: boolean = false;
  public customerRequiredCity: boolean = false;
  public customerRequiredSSN: boolean = false;
  public customerRequiredBirthdate: boolean = false;
  public customerRequiredGender: boolean = false;
  public useCustomerAccounts: boolean = false;

  public editedCustomFields: Array<ICustomFieldDTO> = [];

  public customerReceiptAndReminderMode: string = "";
  public verificationType: VerificationType = VerificationType.None;

  public showAddCustomField: boolean = false;
  public newCustomField: ICustomFieldDTO | null = null;

  @Watch("customFields")
  onCustomFieldsChange(newValue: Array<ICustomFieldDTO>) {
    this.editedCustomFields = newValue;
  }

  created() {
    this.customerRequiredEmail = this.user.customerRequiredEmail;
    this.customerRequiredEmailOptional =
      this.user.customerRequiredEmailOptional;
    this.customerRequiredPhoneNumber = this.user.customerRequiredPhoneNumber;
    this.customerRequiredPhoneNumberOptional =
      this.user.customerRequiredPhoneNumberOptional;
    this.customerRequiredName = this.user.customerRequiredName;
    this.customerRequiredAddress = this.user.customerRequiredAddress;
    this.customerRequiredPostalCode = this.user.customerRequiredPostalCode;
    this.customerRequiredCity = this.user.customerRequiredCity;
    this.customerRequiredSSN = this.user.customerRequiredSSN;
    this.customerRequiredBirthdate = this.user.customerRequiredBirthdate;
    this.customerRequiredGender = this.user.customerRequiredGender;

    this.customerReceiptAndReminderMode =
      this.user.customerReceiptAndReminderMode;
    this.verificationType = this.user.verificationType;

    this.getCustomFields();
  }

  addCustomField() {
    this.newCustomField = {
      label: "",
    } as ICustomFieldDTO;

    this.showAddCustomField = true;
  }

  async saveFieldChange(field: string, value: boolean): Promise<void> {
    try {
      //1. this seems out of place, and 2. i want to translate it.

      const audit: Partial<IAuditLogDTO> = {
        action: 1,
        type: 2,
        comment: "",
        message: this.$t(
          "bookingPageFieldSettings.auditLogs.changedMultipleServices",
          {
            field: this.$t(field),
          }
        ).toString(),
        hideForUser: false,
      };
      await this.postAuditLog(audit);

      const updatedUserPayload: Partial<IUserDTO> = {
        [field]: value,
      };

      await this.patchUser(updatedUserPayload);
    } catch (error) {
      console.error(error);
    }
  }

  async confirmAddCustomField() {
    if (this.newCustomField) {
      await this.postCustomField(this.newCustomField);
      this.newCustomField = null;
      this.showAddCustomField = false;
    }
  }

  async saveCustomField(field: ICustomFieldDTO): Promise<void> {
    try {
      await this.putCustomField(field);
    } catch (error) {
      console.error(error);
    }
  }
}
