
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { IAppointmentTypeDTO, IStaffMemberDTO } from "@shared/types";

@Component({
  name: "DashboardHints",
})
export default class DashboardHints extends Vue {
  @Getter public appointmentType!: IAppointmentTypeDTO[];
  @Getter public staffMembers!: IStaffMemberDTO[];

  private expanded: boolean = true;
  private dialogVisible: boolean = false;
  private selectedService: IAppointmentTypeDTO | null = null;

  get servicesWithoutStaff(): IAppointmentTypeDTO[] {
    if (
      !this.appointmentType ||
      !Array.isArray(this.appointmentType) ||
      !this.staffMembers
    )
      return [];

    return this.appointmentType.filter((service) => {
      // Skip services that are hidden from booking
      if (service.hideInBooking) return false;

      // Check if any staff member has this service
      return !this.staffMembers.some((staff) =>
        staff.services?.some((staffService) => staffService.id === service.id)
      );
    });
  }

  //23FRH3FPG7BZ2S9E4R523RLR for twilio

  get hasHints(): boolean {
    return this.servicesWithoutStaff.length > 0;
  }

  showServiceAvailabilityDialog(service: IAppointmentTypeDTO): void {
    this.selectedService = service;
    this.dialogVisible = true;
  }

  closeDialog(): void {
    this.dialogVisible = false;
  }
}
