
import { Component, Vue } from "vue-property-decorator";
import Sidebar from "../../components/global/Sidebar.vue";
import HeaderBar from "../../components/global/MainHeader.vue";
import CustomerModal from "../../components/global/CustomerModal.vue";
import Loader from "../../components/global/Loader.vue";
import { actionStringCustomers, ICustomer } from "../../store/customers";
import { Action } from "vuex-class";
import { DialogActions, DialogType, IDialog } from "../../store/dialog";
import EditCustomer from "../../components/global/EditCustomer.vue";
import { IAuditLogDTO } from "@shared/types";
import { i18n } from "@/main";

@Component({
  name: "customer",
  components: { EditCustomer, Loader, CustomerModal, Sidebar, HeaderBar },
})
export default class Customer extends Vue {
  public customer: ICustomer = null;
  @Action(DialogActions.AddToDialogQueue) addToDialogQueue: (
    dialog: IDialog
  ) => void;
  @Action(actionStringCustomers.GET_CUSTOMER) getCustomer: (
    customerId: string
  ) => Promise<ICustomer>;
  @Action public deleteCustomer: Function;
  @Action public postSnackbarMessage: (message: string) => void;
  @Action postAuditLog: Function;

  deleteCustomerDialog: boolean = false;
  editing: boolean = false;

  onEditedCustomer() {
    this.loadCustomer();
  }
  async created() {
    await this.loadCustomer();

    let customerId: string = this.$route.params.customerId;

    let audit: Partial<IAuditLogDTO> = {
      action: 3,
      type: 4,
      comment: "",
      message: " opened customer page: " + customerId,
      hideForUser: true,
    };

    this.postAuditLog(audit);
  }
  async loadCustomer() {
    let customerId: string = this.$route.params.customerId;
    try {
      this.customer = await this.getCustomer(customerId);
    } catch (e) {
      let dialog: IDialog = {
        text: i18n.t("customer.notFound").toString(),
      };
      this.addToDialogQueue(dialog);
      this.goBack();
    }
  }

  goBack() {
    this.$router.push("/kunder").catch(() => {});
  }

  async deletedCustomer() {
    try {
      await this.deleteCustomer(this.customer.id);
      this.postSnackbarMessage(
        i18n.t("customer.deleted", { name: this.customer.firstName }).toString()
      );
      this.goBack();
    } catch (e) {
      this.postSnackbarMessage(i18n.t("customer.couldNotDelete").toString());
    }
  }

  public editCustomer(): void {
    this.editing = true;
  }

  finished() {
    this.editing = false;
    this.$emit("finished");
  }

  deleteCustomerAction() {
    let vm = this;

    let dialog: IDialog = {
      text: i18n.t("customer.confirmDeleteCustomer").toString(),
      type: DialogType.Choice,
      header: i18n.t("customer.deleteCustomerHeader").toString(),
      action: function () {
        if (vm.customer != null) {
          vm.deletedCustomer();
          vm.finished();

          vm.deleteCustomerDialog = false;
        }
      },
    };

    this.addToDialogQueue(dialog);
  }
  deleted(customerId) {
    this.$emit("deleted", customerId);
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }
}
