
import { Component, Vue } from "vue-property-decorator";
import SettingsGroup from "@/components/Settings/SettingsGroup.vue";
import SettingsListItem from "@/components/Settings/SettingsListItem.vue";
import StaffSettings from "@/components/Settings/StaffSettings.vue";
import { createSettingsData } from "@/data/settingsData";
import { IStaffMemberDTO, IUserDTO } from "@shared/types";
import { Getter, Action } from "vuex-class";
import { PermissionArea, PermissionLevel } from "@/store/userPermissions";

// Import all settings components
import CompanyInfoCard from "@/components/global/CompanyInfoCard.vue";
import SystemSettings from "@/components/Settings/SystemSettings.vue";
import TreatmentTypes from "@/components/Settings/Services/TreatmentTypes.vue";
import ServiceCategories from "@/components/global/ServiceCategories.vue";
import Resources from "@/components/Settings/Services/Resources.vue";
import SubscriptionInformation from "@/components/Settings/SubscriptionInformation.vue";
import AccountSettings from "@/components/Settings/AccountSettings.vue";
import PersonalAccountSettings from "@/components/Settings/PersonalAccountSettings.vue";
import BookingNotificationSettings from "@/components/Settings/Booking/BookingNotificationSettings.vue";
import BookingPageFieldSettings from "@/components/Settings/Booking/Booking-page/BookingPageFieldSettings.vue";
import GeneralBookingSettings from "@/components/Settings/Booking/GeneralBookingSettings.vue";
import BookingPageGeneralSettings from "@/components/Settings/Booking/Booking-page/BookingPageGeneralSettings.vue";
import ServiceOptions from "@/components/Settings/Services/ServiceOptions.vue";
import StaffMembersSettings from "@/components/Settings/StaffMembers/StaffMembersOverview.vue";
import CalendarStyleSettings from "@/components/Settings/Calendar/CalendarStyleSettings.vue";
import EmailListSettings from "@/components/Settings/Functions/EmailListSettings.vue";
import ExtraServices from "@/components/Settings/Services/ExtraServices.vue";
import BookingPageInformationSettings from "@/components/Settings/Booking/Booking-page/BookingPageInformationSettings.vue";
import BookingPageCustomerSettings from "@/components/Settings/Booking/Booking-page/BookingPageCustomerSettings.vue";
import InvoiceSettings from "@/components/InvoiceSettings.vue";
import AccountRoleSettings from "@/components/Settings/AccountRoleSettings.vue";

// Import staff-related components
import StaffMemberDetailsSettings from "@/components/Settings/StaffMembers/StaffMember/StaffMemberDetailsSettings.vue";
import StaffMemberServicesSettings from "@/components/Settings/StaffMembers/StaffMember/StaffMemberServicesSettings.vue";
import WorkingHours from "@/components/Settings/StaffMembers/StaffMember/WorkingHours.vue";
import VacationDays from "@/components/Settings/StaffMembers/StaffMember/VacationDays.vue";
import StaffMemberGoogleCalendarSettings from "@/components/Settings/StaffMembers/StaffMember/StaffMemberGoogleCalendarSettings.vue";
import StaffMembersOverview from "@/components/Settings/StaffMembers/StaffMembersOverview.vue";
import AuditOverview from "@/pages/admin/AuditOverview.vue";
import OnlineTransactionsSettings from "@/components/Settings/Functions/OnlineTransactionsSettings.vue";

import { toolbarConfig } from "./toolbarconfig";

@Component({
  components: {
    SettingsGroup,
    SettingsListItem,
    StaffSettings,
    CompanyInfoCard,
    SystemSettings,
    TreatmentTypes,
    ServiceCategories,
    Resources,
    SubscriptionInformation,
    AccountSettings,
    AccountRoleSettings,
    PersonalAccountSettings,
    BookingNotificationSettings,
    BookingPageFieldSettings,
    GeneralBookingSettings,
    BookingPageGeneralSettings,
    ServiceOptions,
    StaffMembersSettings,
    CalendarStyleSettings,
    EmailListSettings,
    ExtraServices,
    BookingPageInformationSettings,
    BookingPageCustomerSettings,
    StaffMemberDetailsSettings,
    StaffMemberServicesSettings,
    WorkingHours,
    VacationDays,
    StaffMemberGoogleCalendarSettings,
    StaffMembersOverview,
    AuditOverview,
    InvoiceSettings,
    OnlineTransactionsSettings,
  },
})
export default class SettingsMobile extends Vue {
  dialog = false;
  currentComponent = null;
  currentSettingTitle = "";
  componentProps = {};
  currentRoute = "";

  @Getter public staffMembers: IStaffMemberDTO[];
  @Action public getStaffMembers: () => Promise<void>;

  @Getter public user: IUserDTO;
  @Getter("hasPermission", { namespace: "userPermissions" })
  hasPermission!: Function;

  // Map routes to component names and titles
  routeComponentMap = {};

  get currentToolbarActions() {
    return toolbarConfig[this.currentComponent]?.actions || [];
  }

  get isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  handleAction(handlerName) {
    const component = this.$refs.currentComponentRef;
    if (component && typeof component[handlerName] === "function") {
      component[handlerName]();
    } else {
      console.warn(
        `Handler ${handlerName} is not defined on ${this.currentComponent}`
      );
    }
  }

  showSettingsDialog(route: string, disabled: boolean): void {
    if (!disabled && route) {
      const componentInfo = this.routeComponentMap[route];
      if (componentInfo) {
        this.currentComponent = componentInfo.component;
        this.currentSettingTitle = componentInfo.title;
        this.currentRoute = route;
        this.dialog = true;
      }
    }
  }

  get settings() {
    const meSection = {
      me: [
        {
          id: 1001,
          label: this.$t(this.$ts.settings.myAccount),
          icon: "mdi-account-circle",
          route: "/settings/my-account",
          disabled: false,
        },
      ],
    };

    // If user doesn't have settings read permission, only show me section
    if (!this.hasPermission(PermissionArea.SETTINGS, PermissionLevel.READ)) {
      return meSection;
    }

    // Otherwise, build full settings object with permission checks
    const fullSettings: any = {
      ...meSection,
    };

    // Only show administration section if user has administration read permission
    if (
      this.hasPermission(PermissionArea.ADMINISTRATION, PermissionLevel.READ)
    ) {
      fullSettings.administration = [
        {
          id: 0,
          label: this.$t(this.$ts.settings.companyInfo),
          icon: "mdi-account-details",
          route: "/settings/company-info",
          disabled: false,
        },
        {
          id: 5,
          label: this.$t(this.$ts.settings.subscription),
          icon: "mdi-credit-card-outline",
          route: "/settings/subscription",
          disabled: false,
        },
        {
          id: 6,
          label: this.$t(this.$ts.settings.accounts),
          icon: "mdi-account-outline",
          route: "/settings/accounts",
          disabled: false,
        },
        {
          id: 7,
          label: this.$t(this.$ts.settings.accountRoles),
          icon: "mdi-account-key",
          route: "/settings/account-roles",
          disabled: false,
        },
        {
          id: 204311,
          label: this.$t(this.$ts.settings.system),
          icon: "mdi-web",
          route: "/settings/system",
          disabled: false,
        },
        {
          id: 948328402,
          label: this.$t(this.$ts.settings.auditLog),
          icon: "mdi-format-list-bulleted-square",
          route: "/settings/audit-log",
          disabled: false,
        },
      ];
    }

    // Staff settings
    if (this.hasPermission(PermissionArea.SETTINGS, PermissionLevel.READ)) {
      fullSettings.staff = [
        {
          id: 2,
          label: this.$t(this.$ts.settings.staff),
          icon: "mdi-account-group",
          route: "/settings/staff",
          disabled: false,
        },
      ];
    }

    const servicesItems = [
      {
        id: 3,
        label: this.$t(this.$ts.settings.services),
        icon: "mdi-star-circle",
        route: "/settings/services",
        disabled: false,
      },
      {
        id: 20,
        label: this.$t(this.$ts.settings.extraServices),
        icon: "mdi-star-plus",
        route: "/settings/extra-services",
        disabled: true,
      },
      {
        id: 4,
        label: this.$t(this.$ts.settings.categories),
        icon: "mdi-shape",
        route: "/settings/categories",
        disabled: false,
      },
      {
        id: 43243,
        label: this.$t(this.$ts.settings.resources),
        icon: "mdi-cube-outline",
        route: "/settings/resources",
        disabled: false,
      },
      {
        id: 12,
        label: this.$t(this.$ts.settings.options),
        icon: "mdi-cog",
        route: "/settings/options",
        disabled: false,
      },
    ];

    const enabledServicesItems = servicesItems.filter((item) => !item.disabled);
    if (enabledServicesItems.length > 0) {
      fullSettings.services = enabledServicesItems;
    }

    const bookingItems = [
      {
        id: 10,
        label: this.$t(this.$ts.settings.booking),
        icon: "mdi-calendar",
        route: "/settings/booking",
        disabled: false,
      },
      {
        id: 40,
        label: this.$t(this.$ts.settings.information),
        icon: "mdi-information",
        route: "/settings/booking/information",
        disabled: false,
      },
      {
        id: 8,
        label: this.$t(this.$ts.settings.alerts),
        icon: "mdi-bell-ring",
        route: "/settings/booking/alerts",
        disabled: false,
      },
      {
        id: 11,
        label: this.$t(this.$ts.settings.bookingSide),
        icon: "mdi-link",
        route: "/settings/booking/side",
        disabled: false,
      },
      {
        id: 9,
        label: this.$t(this.$ts.settings.customerInformation),
        icon: "mdi-pen",
        route: "/settings/booking/customer-information",
        disabled: false,
      },
      {
        id: 41,
        label: this.$t(this.$ts.settings.customerAccount),
        icon: "mdi-account",
        route: "/settings/booking/customer-account",
        disabled: false,
      },
    ];

    const enabledBookingItems = bookingItems.filter((item) => !item.disabled);
    if (enabledBookingItems.length > 0) {
      fullSettings.booking = enabledBookingItems;
    }

    const calendarItems = [
      {
        id: 30,
        label: this.$t(this.$ts.settings.calendar),
        icon: "mdi-calendar-range",
        route: "/settings/calendar",
        disabled: false,
      },
    ];

    const enabledCalendarItems = calendarItems.filter((item) => !item.disabled);
    if (enabledCalendarItems.length > 0) {
      fullSettings.calendar = enabledCalendarItems;
    }

    const functionsItems = [
      {
        id: 2042,
        label: this.$t(this.$ts.settings.newsletter),
        icon: "mdi-email-mark-as-unread",
        route: "/settings/functions/newsletter",
        disabled: false,
      },
      {
        id: 20,
        label: this.$t(this.$ts.settings.customerAccounts),
        icon: "mdi-lock",
        route: "/settings/functions/customer-accounts",
        disabled: true,
      },
      {
        id: 21,
        label: this.$t(this.$ts.settings.invoicesAndPayments),
        icon: this.user.enablePaymentModule ? "mdi-cash" : "mdi-lock",
        route: "/settings/functions/invoices-and-payments",
        disabled: !this.user.enablePaymentModule,
      },
      {
        id: 23422,
        label: this.$t(this.$ts.settings.customFields),
        icon: "mdi-lock",
        route: "/settings/functions/custom-fields",
        disabled: true,
      },
      {
        id: 23142,
        label: this.$t(this.$ts.settings.giftCards),
        icon: "mdi-lock",
        route: "/settings/functions/gift-cards",
        disabled: true,
      },
      {
        id: 2323121,
        label: this.$t(this.$ts.settings.customerFollowUp),
        icon: "mdi-lock",
        route: "/settings/functions/customer-follow-up",
        disabled: true,
      },
      {
        id: 231323,
        label: this.$t(this.$ts.settings.cashRegisterSystem),
        icon: "mdi-lock",
        route: "/settings/functions/cash-register-system",
        disabled: true,
      },
      {
        id: 23,
        label: this.$t(this.$ts.settings.multipleLocations),
        icon: "mdi-lock",
        route: "/settings/functions/multiple-locations",
        disabled: true,
      },
      {
        id: 204311,
        label: this.$t(this.$ts.settings.onlineTransactions),
        icon: "mdi-credit-card-outline",
        route: "/settings/functions/online-transactions",
        disabled: false,
      },
    ];

    const enabledFunctionsItems = functionsItems.filter(
      (item) => !item.disabled
    );
    if (enabledFunctionsItems.length > 0) {
      fullSettings.functions = enabledFunctionsItems;
    }

    return fullSettings;
  }

  handleStaffSettingsDialog(dialogInfo: {
    component: string;
    title: string;
    props: any;
  }) {
    // Force component re-render by temporarily setting it to null
    this.currentComponent = null;
    this.$nextTick(() => {
      this.currentComponent = dialogInfo.component;
      this.currentSettingTitle = dialogInfo.title;
      this.componentProps = dialogInfo.props || {};
      // Set the current route based on the staff member if available
      if (dialogInfo.props?.staffMember) {
        const staffId = dialogInfo.props.staffMember.id;
        // Extract the route from the component name
        switch (dialogInfo.component) {
          case "StaffMemberDetailsSettings":
            this.currentRoute = `/settings/staff/${staffId}/information`;
            break;
          case "StaffMemberServicesSettings":
            this.currentRoute = `/settings/staff/${staffId}/services`;
            break;
          case "WorkingHours":
            this.currentRoute = `/settings/staff/${staffId}/working-hours`;
            break;
          case "VacationDays":
            this.currentRoute = `/settings/staff/${staffId}/friday`;
            break;
          case "StaffMemberGoogleCalendarSettings":
            this.currentRoute = `/settings/staff/${staffId}/google-calendar`;
            break;
          default:
            this.currentRoute = "/settings/staff";
        }
      } else {
        this.currentRoute = "/settings/staff";
      }
      this.dialog = true;
    });
  }

  created() {
    this.getStaffMembers();
    //this.settings = createSettingsData();
    this.routeComponentMap = {
      "/settings/my-account": {
        component: "PersonalAccountSettings",
        title: this.$t(this.$ts.settings.myAccount).toString(),
      },
      "/settings/company-info": {
        component: "CompanyInfoCard",
        title: this.$t(this.$ts.settings.companyInfo).toString(),
      },
      "/settings/system": {
        component: "SystemSettings",
        title: this.$t(this.$ts.settings.system).toString(),
      },
      "/settings/account-roles": {
        component: "AccountRoleSettings",
        title: this.$t(this.$ts.settings.accountRoles).toString(),
      },
      "/settings/services": {
        component: "TreatmentTypes",
        title: this.$t(this.$ts.settings.services).toString(),
      },
      "/settings/categories": {
        component: "ServiceCategories",
        title: this.$t(this.$ts.settings.categories).toString(),
      },
      "/settings/audit-log": {
        component: "AuditOverview",
        title: this.$t(this.$ts.settings.auditLog).toString(),
      },
      "/settings/resources": {
        component: "Resources",
        title: this.$t(this.$ts.settings.resources).toString(),
      },
      "/settings/subscription": {
        component: "SubscriptionInformation",
        title: this.$t(this.$ts.settings.subscription).toString(),
      },
      "/settings/accounts": {
        component: "AccountSettings",
        title: this.$t(this.$ts.settings.accounts).toString(),
      },
      "/settings/booking/alerts": {
        component: "BookingNotificationSettings",
        title: this.$t(this.$ts.settings.alerts).toString(),
      },
      "/settings/booking/customer-information": {
        component: "BookingPageFieldSettings",
        title: this.$t(this.$ts.settings.customerInformation).toString(),
      },
      "/settings/booking": {
        component: "GeneralBookingSettings",
        title: this.$t(this.$ts.settings.booking).toString(),
      },
      "/settings/booking/side": {
        component: "BookingPageGeneralSettings",
        title: this.$t(this.$ts.settings.bookingSide).toString(),
      },
      "/settings/options": {
        component: "ServiceOptions",
        title: this.$t(this.$ts.settings.options).toString(),
      },
      "/settings/staff": {
        component: "StaffMembersSettings",
        title: this.$t(this.$ts.settings.staff).toString(),
      },
      "/settings/calendar": {
        component: "CalendarStyleSettings",
        title: this.$t(this.$ts.settings.calendar).toString(),
      },
      "/settings/functions/newsletter": {
        component: "EmailListSettings",
        title: this.$t(this.$ts.settings.newsletter).toString(),
      },
      "/settings/extra-services": {
        component: "ExtraServices",
        title: this.$t(this.$ts.settings.extraServices).toString(),
      },
      "/settings/booking/information": {
        component: "BookingPageInformationSettings",
        title: this.$t(this.$ts.settings.information).toString(),
      },
      "/settings/booking/customer-account": {
        component: "BookingPageCustomerSettings",
        title: this.$t(this.$ts.settings.customerAccount).toString(),
      },
      "/settings/functions/invoices-and-payments": {
        component: "InvoiceSettings",
        title: this.$t(this.$ts.settings.invoicesAndPayments).toString(),
      },
      "/settings/functions/online-transactions": {
        component: "OnlineTransactionsSettings",
        title: this.$t(this.$ts.settings.onlineTransactions).toString(),
      },
    };

    if (!this.isMobile) {
      let defaultRoute = this.hasPermission(
        PermissionArea.SETTINGS,
        PermissionLevel.READ
      )
        ? "/settings/company-info"
        : "/settings/my-account";

      let current = this.routeComponentMap[defaultRoute];
      this.currentComponent = current.component;
      this.currentSettingTitle = current.title;
      this.currentRoute = defaultRoute;
    }
  }
}
