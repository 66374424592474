
import Vue from "vue";
import Component from "vue-class-component";
import { Getter, Action } from "vuex-class";
import { IAuditLogDTO, IUserDTO } from "@shared/types";
import CompanyInfoField from "@/components/global/CompanyInfoField.vue";

enum CustomerMergeType {
  None,
}

@Component({
  name: "BookingPageCustomerSettings",
  components: { CompanyInfoField },
})
export default class BookingPageCustomerSettings extends Vue {
  public customerMergeType: CustomerMergeType = CustomerMergeType.None;
  public automaticAddCustomers: boolean = false;

  @Action public postSnackbarMessage!: Function;
  @Getter user!: IUserDTO;
  @Action patchUser!: Function;
  @Action postAuditLog!: Function;

  created() {
    this.customerMergeType = this.user.customerMergeType;
    this.automaticAddCustomers = this.user.automaticAddCustomers;
  }

  async saveField(
    field: string,
    value: boolean | CustomerMergeType
  ): Promise<void> {
    try {
      const oldValue = this.user[field];
      if (value === oldValue) return;

      // Create audit log based on field
      if (field === "customerMergeType") {
        const auditLogMessage = this.$t(
          "bookingPageCustomerSettings.auditLogs.changedAssignmentType",
          {
            setting: this.enumToString(value as CustomerMergeType),
          }
        );

        await this.postAuditLog({
          action: 1,
          type: 2,
          comment: "",
          message: auditLogMessage.toString(),
          hideForUser: false,
        });
      } else if (field === "automaticAddCustomers") {
        const auditLogMessage = this.$t(
          "bookingPageCustomerSettings.auditLogs.changedAutomaticCustomerCreation",
          {
            setting: this.booleanToOnOff(value as boolean),
          }
        );

        await this.postAuditLog({
          action: 1,
          type: 2,
          comment: "",
          message: auditLogMessage.toString(),
          hideForUser: false,
        });
      }

      // Save the field
      await this.patchUser({ [field]: value });
    } catch (error) {
      console.error(error);
      // Reset to previous value on error
      this[field] = this.user[field];
      this.postSnackbarMessage(
        this.$t(this.$ts.errors.savingFailed).toString()
      );
    }
  }

  enumToString(value: CustomerMergeType): string {
    return value === CustomerMergeType.None
      ? this.$t(this.$ts.off).toString()
      : this.$t(this.$ts.phoneNumber).toString();
  }

  booleanToOnOff(value: boolean): string {
    return value
      ? this.$t(this.$ts.on).toString()
      : this.$t(this.$ts.off).toString();
  }
}
