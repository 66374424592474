// @ts-ignore
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
// @ts-ignore
import { GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState, store } from "@/store/store";
import { APIURL2 } from "@/main";
import authConfig, { AuthConfig } from "@/Utilities/authConfig";
import { actionStringSnackbar } from "@/store/snackbar";
import { IResourceDTO } from "@shared/types";

const CONTROLLER_NAME = "Resources";

// Interface
export interface ResourceState {
  resources: Array<IResourceDTO>;
}

export const state: ResourceState = {
  resources: [],
};

export const getters: GetterTree<ResourceState, ResourceState> = {
  resources: (state) => state.resources,
};

export const mutations: MutationTree<ResourceState> = {
  setResources(state, payload: Array<IResourceDTO>) {
    state.resources = payload;
  },
};

export const actions: ActionTree<ResourceState, ResourceState> = {
  async getResources({ commit }) {
    let API_RESOURCE = APIURL2 + CONTROLLER_NAME;

    let header: AxiosRequestConfig = await authConfig();
    try {
      let res: AxiosResponse<Array<IResourceDTO>> = await axios.get(
        API_RESOURCE + "/current",
        header
      );
      commit("setResources", res.data);
    } catch (e) {
      console.log(e);
    }
  },

  async addResource({ commit, dispatch }, payload: Partial<IResourceDTO>) {
    let API_RESOURCE = APIURL2 + CONTROLLER_NAME;

    let header: AxiosRequestConfig = await authConfig();
    try {
      await axios.post(API_RESOURCE + "/current", payload, header);
      dispatch("getResources");
    } catch (e) {
      console.log(e);
    }
  },

  async editResource({ commit, dispatch }, payload: Partial<IResourceDTO>) {
    let API_RESOURCE = APIURL2 + CONTROLLER_NAME;

    let header: AxiosRequestConfig = await authConfig();
    try {
      await axios.put(API_RESOURCE + "/current", payload, header);
      dispatch("getResources");
    } catch (e) {
      console.log(e);
    }
  },

  async deleteResource({ commit, dispatch }, resourceId: number) {
    let API_RESOURCE = APIURL2 + CONTROLLER_NAME;

    let header: AxiosRequestConfig = await authConfig();
    try {
      await axios.delete(API_RESOURCE + "/current/" + resourceId, header);
      dispatch("getResources");
    } catch (e) {
      console.log(e);
    }
  },
};

export const resources = {
  actions,
  mutations,
  getters,
  state,
};
