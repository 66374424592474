
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import CustomButton from "@/components/global/styledComponents/customButton.vue";
import CustomInput from "@/components/global/styledComponents/CustomInput.vue";
import { getBookingUrl } from "@/Utilities/urlUtility";
import CompanyInfoField from "@/components/global/CompanyInfoField.vue";
import Textfield from "@/components/global/Textfield.vue";
import ImageUpload from "@/components/global/ImageUpload.vue";
import { IUserDTO } from "@shared/types";
const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

@Component({
  name: "company-info-card",
  components: {
    CustomInput,
    CustomButton,
    CompanyInfoField,
    Textfield,
    ImageUpload,
  },
})
export default class CompanyInfoCard extends Vue {
  uploadedFiles: Array<File> = [];
  uploadError: number | null = null;
  currentStatus: number | null = null;
  files: File | null = null;
  isShowingUploadSection: boolean = false;
  hasFile: boolean = false;
  isLoading: boolean = false;
  isEditingCompanyInfo: boolean = false;
  currentAdminInfo: Partial<IUserDTO> = {
    emailAddress: "",
    treatmentAdress: "",
    phoneNumber: "",
    companyName: "",
    bankAccount: "",
    organizationNumber: "",
  };

  @Action public postSnackbarMessage!: Function;
  @Action public getCurrentUser!: Function;
  @Action public updateUserImage!: (file: any) => Promise<boolean>;
  @Action patchUser!: (payload: Partial<IUserDTO>) => Promise<IUserDTO>;
  @Getter public user!: IUserDTO;

  async saveField(field: string, value: string | number): Promise<void> {
    try {
      await this.patchUser({ [field]: value });
    } catch (error) {
      console.error(error.message);
    }
  }

  setCurrentUser(): void {
    this.currentAdminInfo.emailAddress = this.user.emailAddress;
    this.currentAdminInfo.treatmentAdress = this.user.treatmentAdress;
    this.currentAdminInfo.phoneNumber = this.user.phoneNumber;
    this.currentAdminInfo.companyName = this.user.companyName;
  }

  mounted() {
    this.setCurrentUser();
  }

  async onImageSelected(file: File): Promise<void> {
    this.isLoading = true;
    let fb = new FormData();
    fb.append("file", file);
    try {
      await this.updateUserImage(fb);
    } finally {
      this.isLoading = false;
      this.isShowingUploadSection = false;
    }
  }

  get userProfilePicture(): string | undefined {
    return this.user.profilePictureUrl;
  }

  get userLink() {
    return getBookingUrl(this.user.bookingLink);
  }
}
