var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('CompanyInfoField',{attrs:{"label":_vm.$t('generalBookingSettings.customerVerification'),"description":_vm.$t(_vm.$ts.generalBookingSettings.customerVerificationDescription)}},[_c('v-select',{attrs:{"disabled":true,"items":_vm.verificationOptions,"item-value":"value","item-text":"label"},on:{"change":function($event){return _vm.saveField('verificationType', $event, _vm.user.verificationType)}},model:{value:(_vm.verificationType),callback:function ($$v) {_vm.verificationType=$$v},expression:"verificationType"}})],1),_c('v-divider'),_c('CompanyInfoField',{attrs:{"label":_vm.$t('generalBookingSettings.cancellationModification'),"description":_vm.$t(_vm.$ts.generalBookingSettings.cancellationModificationDescription)}},[_c('v-switch',{attrs:{"color":"#56dcb6","label":_vm.booleanToOnOff(_vm.abilityToCancel)},on:{"change":function($event){return _vm.saveField('abilityToCancel', $event, _vm.user.abilityToCancel)}},model:{value:(_vm.abilityToCancel),callback:function ($$v) {_vm.abilityToCancel=$$v},expression:"abilityToCancel"}})],1),_c('v-divider'),(_vm.abilityToCancel)?_c('CompanyInfoField',{attrs:{"label":_vm.$t('generalBookingSettings.minimumHoursBefore'),"description":_vm.$t('generalBookingSettings.minimumHoursBeforeDescription')}},[_c('Textfield',{attrs:{"number":true},on:{"blur":function($event){return _vm.saveField('hoursToCancel', _vm.hoursToCancel, _vm.user.hoursToCancel)}},model:{value:(_vm.hoursToCancel),callback:function ($$v) {_vm.hoursToCancel=$$v},expression:"hoursToCancel"}})],1):_vm._e(),_c('v-divider'),_c('CompanyInfoField',{attrs:{"label":_vm.$t('generalBookingSettings.remindersReceipts'),"description":_vm.$t('generalBookingSettings.remindersReceiptsDescription')}},[_c('v-radio-group',{attrs:{"mandatory":true,"multiple":false},on:{"change":function($event){return _vm.saveField(
          'customerReceiptAndReminderMode',
          $event,
          _vm.user.customerReceiptAndReminderMode
        )}},model:{value:(_vm.customerReceiptAndReminderMode),callback:function ($$v) {_vm.customerReceiptAndReminderMode=$$v},expression:"customerReceiptAndReminderMode"}},[_c('v-radio',{attrs:{"value":"email","label":_vm.$t('generalBookingSettings.email')}}),_c('v-radio',{attrs:{"value":"sms","label":_vm.$t('generalBookingSettings.sms')}}),_c('v-radio',{attrs:{"value":"whatsapp","label":_vm.$t('generalBookingSettings.whatsapp')}})],1)],1),_c('v-divider'),_c('CompanyInfoField',{attrs:{"label":_vm.$t('generalBookingSettings.alwaysSendReceipt'),"description":_vm.$t('generalBookingSettings.alwaysSendReceiptDescription')}},[_c('v-switch',{attrs:{"color":"#56dcb6","label":_vm.booleanToOnOff2(_vm.sendAutomaticallyReceipt)},on:{"change":function($event){return _vm.saveField(
          'sendAutomaticallyReceipt',
          $event,
          _vm.user.sendAutomaticallyReceipt
        )}},model:{value:(_vm.sendAutomaticallyReceipt),callback:function ($$v) {_vm.sendAutomaticallyReceipt=$$v},expression:"sendAutomaticallyReceipt"}})],1),_c('v-divider'),_c('CompanyInfoField',{attrs:{"label":_vm.$t('generalBookingSettings.alwaysSendReminder'),"description":_vm.$t('generalBookingSettings.alwaysSendReminderDescription')}},[_c('v-switch',{attrs:{"color":"#56dcb6","label":_vm.booleanToOnOff2(_vm.sendAutomaticallyReminder)},on:{"change":function($event){return _vm.saveField(
          'sendAutomaticallyReminder',
          $event,
          _vm.user.sendAutomaticallyReminder
        )}},model:{value:(_vm.sendAutomaticallyReminder),callback:function ($$v) {_vm.sendAutomaticallyReminder=$$v},expression:"sendAutomaticallyReminder"}})],1),_c('v-divider'),_c('CompanyInfoField',{attrs:{"label":_vm.$t('generalBookingSettings.bookingRestrictions'),"description":_vm.$t('generalBookingSettings.bookingRestrictionsDescription')}},[_c('v-text-field',{attrs:{"type":"number","label":_vm.$t('generalBookingSettings.days')},on:{"blur":function($event){return _vm.saveField(
          'maxDaysInAdvanceForBooking',
          _vm.maxDaysInAdvanceForBooking,
          _vm.user.maxDaysInAdvanceForBooking
        )}},model:{value:(_vm.maxDaysInAdvanceForBooking),callback:function ($$v) {_vm.maxDaysInAdvanceForBooking=$$v},expression:"maxDaysInAdvanceForBooking"}})],1),_c('v-divider'),_c('CompanyInfoField',{attrs:{"label":_vm.$t('generalBookingSettings.earlyBooking'),"description":_vm.$t('generalBookingSettings.earlyBookingDescription')}},[_c('v-text-field',{attrs:{"type":"number","label":_vm.$t('generalBookingSettings.hours')},on:{"blur":function($event){return _vm.saveField(
          'minumumHourBeforeBooking',
          _vm.minumumHourBeforeBooking,
          _vm.user.minumumHourBeforeBookingf
        )}},model:{value:(_vm.minumumHourBeforeBooking),callback:function ($$v) {_vm.minumumHourBeforeBooking=$$v},expression:"minumumHourBeforeBooking"}})],1),_c('v-divider'),_c('CompanyInfoField',{attrs:{"label":_vm.$t('generalBookingSettings.staffSelection'),"description":_vm.$t('generalBookingSettings.staffSelectionDescription')}},[_c('v-switch',{attrs:{"color":"#56dcb6","label":_vm.booleanToOnOff2(_vm.customerCanSelectStaff),"disabled":_vm.staffMembers.length <= 1 && !_vm.user.customerCanSelectStaff},on:{"change":function($event){return _vm.saveField(
          'customerCanSelectStaff',
          $event,
          _vm.user.customerCanSelectStaff
        )}},model:{value:(_vm.customerCanSelectStaff),callback:function ($$v) {_vm.customerCanSelectStaff=$$v},expression:"customerCanSelectStaff"}})],1),_c('v-divider'),_c('BookingDistribution')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }