
import { Component, Vue } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import CustomButton from "../../global/styledComponents/customButton.vue";
import Loader from "../../global/Loader.vue";
import { IResourceDTO, IStaffMemberDTO } from "@shared/types";
import { DialogMutations, DialogType, IDialog } from "@/store/dialog";
import BaseFormDialog from "@/components/global/BaseFormDialog.vue";
import CompanyInfoField from "@/components/global/CompanyInfoField.vue";
import Textfield from "@/components/global/Textfield.vue";

@Component({
  name: "resources",
  components: {
    Loader,
    CustomButton,
    BaseFormDialog,
    CompanyInfoField,
    Textfield,
  },
})
export default class Resources extends Vue {
  public newResource: Partial<IResourceDTO> = {};
  public isShowingForm: boolean = false;

  private isLoading: boolean = true;
  private editingResource: Partial<IResourceDTO> = null;
  private isEditing: boolean = false;
  public autoApply: boolean = true;
  private isCreating: boolean = false;

  @Action public getResources: () => Promise<any>;
  @Action public editResource: (
    resource: Partial<IResourceDTO>
  ) => Promise<any>;
  @Action public addResource: (resource: Partial<IResourceDTO>) => Promise<any>;
  @Action public deleteResource: (resourceId: number) => Promise<any>;
  @Getter public resources: Array<IResourceDTO>;

  @Action public getCurrentUser: Function;
  @Getter public user;
  @Action public postSnackbarMessage: (message: string) => void;
  @Getter public staffMembers: IStaffMemberDTO[];

  // Initialize as empty arrays
  private nameRules: Array<(v: any) => boolean | string> = [];
  private amountRules: Array<(v: any) => boolean | string> = [];

  private validateField(fieldName: string) {
    const baseForm = this.$refs.baseForm as any;
    if (baseForm) {
      baseForm.validate();
    }
  }

  public toggleAdd(): void {
    let newEditResource: Partial<IResourceDTO> = {
      amount: 1,
      description: "",
      name: "",
      userId: this.user.id,
    };
    this.editingResource = newEditResource;
    this.isCreating = true;
    this.isShowingForm = !this.isShowingForm;
  }

  public toggleEdit(resource: Partial<IResourceDTO>): void {
    this.editingResource = resource;
    this.isEditing = true;
    this.isShowingForm = !this.isShowingForm;
  }
  async runEditResource() {
    const baseForm = this.$refs.baseForm as any;
    if (baseForm && baseForm.validate()) {
      await this.editResource(this.editingResource);
      this.isEditing = false;
      this.editingResource = null;
    }
  }
  async runAddNewAppointmentType(
    resource: Partial<IResourceDTO>
  ): Promise<void> {
    if (resource.name == null || resource.name == "") {
      this.postSnackbarMessage(this.$t(this.$ts.nameIsRequired).toString());
      return;
    }

    if (resource.amount == null || resource.amount < 1) {
      this.postSnackbarMessage(
        this.$t(this.$ts.amountMustBeOneOrHigher).toString()
      );
      return;
    }

    this.isLoading = true;
    this.isShowingForm = false;

    await this.addResource(resource);

    this.isCreating = false;
    this.editingResource = null;
    this.clearForm();
    this.isLoading = false;
  }

  clearForm(): void {
    if (this.newResource) {
      this.newResource.name = "";
      this.newResource.amount = 1;
      this.newResource.description = "";
    }
  }

  async runDeleteAppointmentType(resourceId: number): Promise<void> {
    const vm = this;

    let dialog: IDialog = {
      text: this.$t(this.$ts.resources.deleteConfirmation).toString(),
      type: DialogType.Choice,
      header: this.$t(this.$ts.resources.deleteHeader).toString(),
      action: function () {
        vm.deleteResource(resourceId);
      },
    };
    vm.$store.commit(DialogMutations.AddToDialogQueue, dialog);
  }
  editService(resource) {
    this.isEditing = true;
    this.editingResource = resource;
  }
  runEditService() {
    this.editResource(this.editingResource);
    this.isEditing = false;
    this.editingResource = null;
  }

  async runCreateResource() {
    const baseForm = this.$refs.baseForm as any;
    if (baseForm && baseForm.validate()) {
      await this.addResource(this.editingResource);
      this.isCreating = false;
      this.editingResource = null;
    }
  }

  created() {
    // Set validation rules
    this.nameRules = [
      (v: string) => !!v || this.$t(this.$ts.nameIsRequired).toString(),
    ];

    this.amountRules = [
      (v: number) =>
        v >= 1 || this.$t(this.$ts.amountMustBeOneOrHigher).toString(),
    ];

    // Existing created logic
    this.getCurrentUser();
    this.getResources();
    this.isLoading = false;
  }

  get showEditDialog() {
    return this.isEditing || this.isCreating;
  }

  set showEditDialog(value) {
    this.isEditing = value;
    this.isCreating = value;
  }

  booleanToNorwegian(value: boolean): string {
    if (value) {
      return this.$t(this.$ts.boolean.yes).toString();
    } else {
      return this.$t(this.$ts.boolean.no).toString();
    }
  }
}
