
import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter } from "vuex-class";
import ToolTip from "../../ToolTip.vue";
import { IAuditLogDTO, IUserDTO } from "@shared/types";

@Component({
  name: "ServiceOptions",
  components: { ToolTip },
})
export default class ServiceOptions extends Vue {
  @Getter user!: IUserDTO;
  @Action patchUser!: Function;
  @Action postAuditLog!: Function;

  public multipleServices: boolean = false;
  public showServicePrice: boolean = false;

  created() {
    this.multipleServices = this.user.multipleServices;
    this.showServicePrice = this.user.showServicePrice;
  }

  async saveField(field: string, value: boolean): Promise<void> {
    try {
      // Create audit log based on field
      if (
        field === "multipleServices" &&
        value !== this.user.multipleServices
      ) {
        await this.postAuditLog({
          action: 1,
          type: 2,
          comment: "",
          message: this.$t(
            "serviceOptions.auditLogs.changedShowInformationBox",
            {
              setting: this.booleanToOnOff(value),
            }
          ).toString(),
          hideForUser: false,
        });
      } else if (
        field === "showServicePrice" &&
        value !== this.user.showServicePrice
      ) {
        await this.postAuditLog({
          action: 1,
          type: 2,
          comment: "",
          message: this.$t(
            this.$ts.serviceOptions.auditLogs.changedShowServicePrice,
            {
              setting: this.booleanToOnOff(value),
            }
          ).toString(),
          hideForUser: false,
        });
      }

      // Save the field
      await this.patchUser({ [field]: value });
    } catch (error) {
      console.error(error);
      // Reset to previous value on error
      if (field === "multipleServices") {
        this.multipleServices = this.user.multipleServices;
      } else if (field === "showServicePrice") {
        this.showServicePrice = this.user.showServicePrice;
      }
    }
  }

  booleanToOnOff(value: boolean): string {
    return value
      ? this.$t(this.$ts.boolean.yes).toString()
      : this.$t(this.$ts.boolean.no).toString();
  }
}
