
import Vue from "vue";
import Component from "vue-class-component";
import CompanyInfoField from "@/components/global/CompanyInfoField.vue";

@Component({
  components: {
    CompanyInfoField,
  },
})
export default class DianSettings extends Vue {}
