
import Vue from "vue";
import Component from "vue-class-component";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { Prop } from "vue-property-decorator";

@Component({
  name: "SettingLink",
  components: {},
})
export default class SettingLink extends Vue {
  @Prop({}) icon;
  @Prop({}) label;
  @Prop({}) id;
  @Prop({ default: null }) staffId;
  @Prop({ default: null }) selectedStaffId;
  @Prop({}) currentId;
  @Prop({ default: false }) disabled;

  created() {}

  expand() {
    this.$emit("selected", this.id);
  }

  get color() {
    if (this.isSelected) {
      return "#54C0B7";
    }

    return "#757575";
  }

  get isSelected() {
    if (this.staffId != null) {
      return this.staffId == this.selectedStaffId && this.currentId == this.id;
    }
    return this.currentId == this.id;
  }
}
