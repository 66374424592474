
import Vue from "vue";
import Component from "vue-class-component";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { Prop, Watch } from "vue-property-decorator";
import { IStaffMemberDTO } from "@shared/types";
import { IStaffMemberPatch } from "../../../../store/staffMember";
import CompanyInfoField from "../../../global/CompanyInfoField.vue";
import Textfield from "../../../global/Textfield.vue";
import ImageUpload from "../../../global/ImageUpload.vue";

@Component({
  name: "StaffMemberDetailsSettings",
  components: { CompanyInfoField, Textfield, ImageUpload },
})
export default class StaffMemberDetailsSettings extends Vue {
  @Prop({}) staffMember!: IStaffMemberDTO;

  @Action patchStaffMember: (patch: IStaffMemberPatch) => Function;
  @Action uploadStaffImage: (payload: {
    staffMemberId: number;
    formData: FormData;
  }) => Promise<any>;

  public editingStaffMember: Partial<IStaffMemberDTO> = {};
  public localStaffMember: IStaffMemberDTO | null = null;
  public isSaving: boolean = false;
  public isLoading: boolean = false;
  public color: string = "#4CAF50";
  public error: string | null = null;
  public isInitializing: boolean = true;
  public imageFile: File | null = null;
  public isUploadingImage: boolean = false;

  @Getter user;
  @Getter public staffMembers;
  @Action public getStaffMembers: Function;

  @Watch("editingStaffMember", { deep: true })
  async onEditingStaffMemberChange(newVal: any, oldVal: any) {
    if (!this.localStaffMember || this.isInitializing) return;

    // Compare each field to detect actual changes
    const hasChanges = Object.keys(newVal).some((key) => {
      const oldValue = this.localStaffMember?.[key];
      const newValue = newVal[key];
      return oldValue !== newValue && newValue !== undefined;
    });

    if (hasChanges) {
      await this.saveChanges();
    }
  }

  @Watch("color")
  async onColorChange(newVal: string, oldVal: string) {
    if (!this.localStaffMember || this.isInitializing) return;
    // Only save if this is a user change, not an initialization
    if (newVal !== oldVal) {
      await this.saveChanges();
    }
  }

  @Watch("staffMember")
  onStaffMemberChange(newStaffMember: IStaffMemberDTO) {
    if (newStaffMember) {
      this.isInitializing = true;
      this.localStaffMember = newStaffMember;
      this.initializeData();
    }
  }

  async saveChanges() {
    if (!this.localStaffMember) return;

    try {
      this.isSaving = true;
      const edit: Partial<IStaffMemberDTO> = {};

      // Only include changed fields in the patch
      if (
        this.editingStaffMember.firstName !== undefined &&
        this.editingStaffMember.firstName !== this.localStaffMember.firstName
      ) {
        edit.firstName = this.editingStaffMember.firstName;
      }

      if (
        this.editingStaffMember.lastName !== undefined &&
        this.editingStaffMember.lastName !== this.localStaffMember.lastName
      ) {
        edit.lastName = this.editingStaffMember.lastName;
      }

      if (
        this.editingStaffMember.phoneNumber !== undefined &&
        this.editingStaffMember.phoneNumber !==
          this.localStaffMember.phoneNumber
      ) {
        edit.phoneNumber = this.editingStaffMember.phoneNumber;
      }

      if (
        this.editingStaffMember.emailAddress !== undefined &&
        this.editingStaffMember.emailAddress !==
          this.localStaffMember.emailAddress
      ) {
        edit.emailAddress = this.editingStaffMember.emailAddress;
      }

      if (this.color !== this.localStaffMember.color) {
        edit.color = this.color;
      }

      // Only send patch if there are actual changes
      if (Object.keys(edit).length > 0) {
        const patch: IStaffMemberPatch = {
          staffMemberId: this.localStaffMember.id,
          patch: edit,
        };
        await this.patchStaffMember(patch);

        // Update localStaffMember with the new values
        Object.assign(this.localStaffMember, edit);
      }
    } catch (err) {
      console.error("Error saving changes:", err);
      this.error = "Failed to save changes";
    } finally {
      this.isSaving = false;
    }
  }

  async onImageSelected(file: File) {
    if (!this.localStaffMember) return;

    this.isUploadingImage = true;
    const formData = new FormData();
    formData.append("file", file);

    try {
      const success = await this.uploadStaffImage({
        staffMemberId: this.localStaffMember.id,
        formData,
      });

      if (success) {
        // Refresh staff members to get the updated profile picture URL
        await this.getStaffMembers();
        // Update localStaffMember with the new data
        const updatedStaffMember = this.staffMembers.find(
          (staff) => staff.id === this.localStaffMember?.id
        );
        if (updatedStaffMember) {
          this.localStaffMember = updatedStaffMember;
        }
      } else {
        console.error("Failed to upload image");
      }
    } finally {
      this.isUploadingImage = false;
    }
  }

  async mounted() {
    this.isLoading = true;
    try {
      if (this.staffMember) {
        // If staffMember is provided as prop, use it directly
        this.localStaffMember = this.staffMember;
        this.initializeData();
      } else {
        // Otherwise, try to get it from route params
        const staffId = this.$route.params.staffId;
        if (!staffId) {
          throw new Error("No staff member ID provided");
        }

        if (this.staffMembers.length === 0) {
          await this.getStaffMembers();
        }

        this.localStaffMember = this.staffMembers.find(
          (staff) => staff.id == staffId
        );

        if (!this.localStaffMember) {
          throw new Error("Staff member not found");
        }

        this.initializeData();
      }
    } catch (err) {
      this.error = err.message || "Failed to load staff member";
      console.error("Error loading staff member:", err);
    } finally {
      this.isLoading = false;
    }
  }

  initializeData() {
    if (!this.localStaffMember) return;

    this.isInitializing = true;
    // Reset all fields first
    this.editingStaffMember = {};
    this.color = "#4CAF50";

    // Then set new values
    this.color = this.localStaffMember.color || "#4CAF50";
    this.editingStaffMember = {
      phoneNumber: this.localStaffMember.phoneNumber,
      emailAddress: this.localStaffMember.emailAddress,
      firstName: this.localStaffMember.firstName,
      lastName: this.localStaffMember.lastName,
    };

    // Set isInitializing to false after a short delay to ensure all watchers have processed the initial values
    setTimeout(() => {
      this.isInitializing = false;
    }, 100);
  }

  get title(): string {
    return this.localStaffMember
      ? `${this.localStaffMember.firstName} ${this.localStaffMember.lastName}`
      : "";
  }

  get phone(): string {
    return this.localStaffMember?.phoneNumber || "";
  }

  get email(): string {
    return this.localStaffMember?.emailAddress || "";
  }
}
