
import Vue from "vue";
import Component from "vue-class-component";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { DialogMutations, DialogType, IDialog } from "@/store/dialog";
import Loader from "./Loader.vue";
import CustomButton from "./styledComponents/customButton.vue";
import DynamicForm from "../Settings/Services/components/DynamicForm.vue";
import { IServiceCategory } from "@/store/appointmentTypes";

@Component({
  name: "ServiceCategories",
  components: { CustomButton, Loader, DynamicForm },
})
export default class ServiceCategories extends Vue {
  public isLoading = false;
  public isShowingForm = false;
  public isEditing = false;
  public editingCategory: IServiceCategory | null = null;

  @Action public addNewCategory: (payload: IServiceCategory) => Promise<any>;
  @Action public putCategory: (payload: IServiceCategory) => Promise<any>;
  @Action public deleteCategory: (payload: any) => Promise<any>;
  @Action public getCategories: () => Promise<Array<IServiceCategory>>;
  @Action public changeCategoryOrder: (payload) => Promise<any>;
  @Action public postSnackbarMessage: (message: string) => void;
  @Getter public categories: Array<IServiceCategory>;
  @Getter public serviceCategoryMetadata!: any[];

  get nonReactiveTranslations() {
    return Object.freeze({ ...this.$ts.dtos.serviceCategoryFormDTO });
  }

  async created() {
    this.isLoading = true;
    await this.getCategories();
    this.isLoading = false;
  }

  async onAddNewCategory(category: Partial<IServiceCategory>): Promise<void> {
    this.isLoading = true;
    //@ts-ignore
    const newCategory: IServiceCategory = {
      ...category,
      order: this.categories.length,
    } as IServiceCategory;

    await this.addNewCategory(newCategory);
    this.isShowingForm = false;
    this.editingCategory = null;
    this.isEditing = false;
    this.isLoading = false;
  }

  async onUpdateCategory(category: IServiceCategory): Promise<void> {
    this.isLoading = true;
    await this.putCategory(category);
    this.isShowingForm = false;
    this.isEditing = false;
    this.editingCategory = null;
    this.isLoading = false;
  }

  public toggleItem(item, index): void {
    item.isShowing = !item.isShowing;
  }

  async archiveCategory(itemId: string): Promise<void> {
    const vm = this;

    let dialog: IDialog = {
      text: this.$t(this.$ts.serviceCategories.confirmDelete).toString(),
      type: DialogType.Choice,
      header: this.$t(this.$ts.serviceCategories.deleteHeader).toString(),
      action: function () {
        vm.deleteCategory(itemId);
      },
    };
    vm.$store.commit(DialogMutations.AddToDialogQueue, dialog);
  }

  public toggleForm(): void {
    if (!this.isShowingForm) {
      this.editingCategory = null;
      this.isEditing = false;
    }
    this.isShowingForm = !this.isShowingForm;
  }

  reOrder(categoryId, newOrder) {
    this.changeCategoryOrder({ categoryId: categoryId, order: newOrder });
  }

  editCategory(category) {
    this.editingCategory = category;
    this.isEditing = true;
    this.isShowingForm = true;
  }
}
