import { IFieldMetadata } from "@shared/types";
import { i18n } from "@/main";

export enum DisplayType {
  Text = 0,
  TextArea = 1,
  Number = 2,
  Switch = 3,
  ColorPicker = 4,
  Dropdown = 5,
}

// Define permission levels for dropdowns
export enum PermissionLevel {
  None = 0,
  Read = 1,
  ReadWrite = 2,
}

interface ValueFormatter {
  format(value: any): string;
}

class SwitchValueFormatter implements ValueFormatter {
  format(value: boolean): string {
    return value
      ? i18n.t("boolean.yes").toString()
      : i18n.t("boolean.no").toString();
  }
}

export class FormGeneratorService {
  private valueFormatters: { [key: string]: ValueFormatter } = {
    [DisplayType.Switch]: new SwitchValueFormatter(),
  };

  private getPermissionLevelItems() {
    return [
      {
        text: i18n.t("accountRole.permissionLevels.none"),
        value: PermissionLevel.None,
      },
      {
        text: i18n.t("accountRole.permissionLevels.read"),
        value: PermissionLevel.Read,
      },
      {
        text: i18n.t("accountRole.permissionLevels.readWrite"),
        value: PermissionLevel.ReadWrite,
      },
    ];
  }

  formatFieldValue(type: DisplayType, value: any): string {
    const formatter = this.valueFormatters[type];
    return formatter ? formatter.format(value) : value?.toString() || "";
  }

  getFieldComponent(type: DisplayType): string {
    const mappings: { [key in DisplayType]: string } = {
      [DisplayType.Text]: "Textfield",
      [DisplayType.TextArea]: "Textfield",
      [DisplayType.Number]: "Textfield",
      [DisplayType.Switch]: "v-switch",
      [DisplayType.ColorPicker]: "v-color-picker",
      [DisplayType.Dropdown]: "EnumField",
    };
    return mappings[type as DisplayType] || "Textfield";
  }

  getFieldProps(field: IFieldMetadata): { [key: string]: any } {
    const baseProps: { [key: string]: any } = {};

    switch (field.type) {
      case DisplayType.Number:
        return {
          ...baseProps,
          number: true,
        };
      case DisplayType.TextArea:
        return {
          ...baseProps,
          multiLine: true,
        };
      case DisplayType.Switch:
        return {
          ...baseProps,
          color: "#56dcb6",
          inset: true,
          type: "boolean",
        };
      case DisplayType.ColorPicker:
        return {
          "dot-size": 25,
          "hide-mode-switch": true,
          "show-swatches": true,
          "hide-sliders": true,
          "hide-inputs": true,
          "hide-canvas": true,
          "swatches-max-height": 100,
        };
      case DisplayType.Dropdown:
        // For permission level dropdowns
        return {
          ...baseProps,
          items: this.getPermissionLevelItems(),
          "item-text": "text",
          "item-value": "value",
        };
        return {
          ...baseProps,
          items: [],
        };
      default:
        return baseProps;
    }
  }
}

// v-model="editingRole.appointmentsAndClients"
//                   :items="permissionLevels"
//                   item-text="text"
//                   item-value="value"
