
import { Component, Vue, Watch } from "vue-property-decorator";
import NotificationDropdown from "./NotificationDropdown.vue";
import { Action, Getter } from "vuex-class";
import { logout } from "@/Utilities/authConfig";
import NotificationBell from "./NotificationBell.vue";
import { IUserDTO } from "@shared/types";

@Component({
  name: "main-header",
  components: { NotificationBell, NotificationDropdown },
})
export default class MainHeader extends Vue {
  public isShowing: boolean = false;
  public isShowingMenu: boolean = false;

  @Getter public user: IUser;

  public group = null;

  @Getter public mini: boolean;

  public pageTitle: string = "Hjem";

  @Watch("$route")
  async onRouterChange() {
    this.pageTitle = this.$router.currentRoute.name;
  }

  created() {
    //Page title is set if one enters the page directly ... reloading /kalender, for example
    this.pageTitle = this.$router.currentRoute.name;
  }

  get getUserWithTypes(): IUser {
    return this.user;
  }

  gotoEvents() {
    this.$router.push("/hendelser/").catch(() => {});
  }

  hideMenu(): void {
    this.isShowing = false;
    this.isShowingMenu = false;
  }
}
