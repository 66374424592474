// @ts-ignore
import Vue from "vue";
// @ts-ignore
import Vuex from "vuex";
import { user } from "./user";
import { appointmentTypes } from "./appointmentTypes";
import { customers } from "./customers";
import { customerJournals } from "./customerJournals";
import { appointments } from "./appointments/appointments";
import { workingHours } from "./workingHours";
import { snackbar } from "./snackbar";
import { onboarding } from "./Onboarding";
import { company } from "./company";
import { notifications } from "./notifications";
import { checkout } from "./checkout";
import { statistics } from "./statistics";
import { muscleComment } from "./muscleComment";
import { customerAccounts } from "./customerAccounts";
import { dialog } from "./dialog";
import { events } from "./Events";
import { invoices } from "./Invoices";
import { insurances } from "./Insurances";
import { payments } from "./Payments";
import { layout } from "./layout";
import { staffMember } from "./staffMember";
import { emailList } from "./emailList";
import { resources } from "./resources";
import { customFields } from "./customFields";
import { accountRole } from "./accountRole";
import CalendarModule from "./calendar";
import userPermissions from "./userPermissions";
import * as Sentry from "@sentry/vue";
import { metadata } from "./metadata";

// import customers from './customers';

export interface RootState {}

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    user,
    appointmentTypes,
    customers,
    customerJournals,
    appointments,
    workingHours,
    snackbar,
    onboarding,
    company,
    notifications,
    checkout,
    statistics,
    muscleComment,
    dialog,
    customerAccounts,
    events,
    invoices,
    insurances,
    payments,
    layout,
    staffMember,
    emailList,
    resources,
    customFields,
    calendar: CalendarModule,
    accountRole,
    userPermissions,
    metadata,
  },
});

store.subscribe((mutation, state) => {
  // Create a safe copy of the state (removing circular references)
  const stateCopy = JSON.parse(
    JSON.stringify({
      module: mutation.type.split("/")[0],
      mutation: mutation.type,
      payload: mutation.payload,
      // Only include relevant parts of state to avoid huge payloads
      relevantState: state[mutation.type.split("/")[0]],
    })
  );

  Sentry.addBreadcrumb({
    category: "my-vuex",
    message: `Mutation: ${mutation.type}`,
    level: "info",
    data: stateCopy,
  });

  // Optional: Track specific mutations more carefully
  if (mutation.type.includes("auth/") || mutation.type.includes("critical/")) {
    Sentry.setContext("store-change", stateCopy);
  }
});
