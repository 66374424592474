
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { actionStringCustomers, ICustomer } from "../../store/customers";
import { ICustomerJournals } from "../../store/customerJournals";
import muscleSelector from "../../components/global/muscleGroupSelector.vue";
import DatePicker from "../../components/global/DatePicker.vue";
import CustomInput from "../../components/global/styledComponents/CustomInput.vue";
import { Sector } from "../../types/Sector";
import CustomButton from "../../components/global/styledComponents/customButton.vue";
import { DialogMutations, DialogType, IDialog } from "../../store/dialog";
import Loader from "../../components/global/Loader.vue";
import CustomerModal from "../../components/global/CustomerModal.vue";
import EditCustomer from "../../components/global/EditCustomer.vue";
import CustomerModalWindow from "../../components/global/CustomerModalWindow.vue";
import {
  formatDate,
  FormatSpaceType,
  formatTime,
} from "../../types/formatDateHelper";
import { IInvoice, InvoiceActions } from "../../store/Invoices";
import AppointmentList from "./AppointmentList.vue";
import { AppointmentInfo } from "../../store/appointments/appointmentTypings";
import InvoiceCreator from "../../components/InvoiceCreator.vue";
import InvoicesList from "../../components/InvoicesList.vue";
import PaymentList from "../../components/PaymentList.vue";
import { IPayment, PaymentActions } from "../../store/Payments";
import PaymentCreator from "../../components/global/PaymentCreator.vue";

@Component({
  name: "PaymentsOverview",
  components: {
    PaymentCreator,
    PaymentList,
    InvoicesList,
    InvoiceCreator,
    AppointmentList,
    CustomerModalWindow,
    EditCustomer,
    CustomerModal,
    Loader,
    CustomButton,
    CustomInput,
    DatePicker,
  },
})
export default class PaymentsOverview extends Vue {
  @Getter public user: IUser;
  @Action public addToDialogQueue: Function;
  @Action public postSnackbarMessage: Function;

  @Action(PaymentActions.GET) public getPayments: () => Promise<
    Array<IPayment>
  >;
  @Getter public payments: IUser;

  public showDialog: boolean = false;
  public isLoading: boolean = true;

  selectedEvents = [];

  public chosenAppointment: AppointmentInfo = null;

  public async created(): Promise<any> {
    try {
      this.isLoading = true;
      await this.getPayments();
      this.isLoading = false;
    } catch (e) {
      let dialog: IDialog = {
        text: "Kunne ikke hente betalinger",
      };
      this.addToDialogQueue(dialog);
    }
  }

  onFinished() {
    this.showDialog = false;
  }
  onChosenAppointment(appointment) {
    this.chosenAppointment = appointment;
  }

  onFinishedPayment() {
    this.showDialog = false;
    this.chosenAppointment = null;
  }

  get dialog() {
    return this.showDialog;
  }
  set dialog(v) {
    this.chosenAppointment = null;
    this.showDialog = false;
  }
}
