
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { actionStringCustomers, ICustomer } from "../../store/customers";
import { ICustomerJournals } from "../../store/customerJournals";
import muscleSelector from "../../components/global/muscleGroupSelector.vue";
import DatePicker from "../../components/global/DatePicker.vue";
import CustomInput from "../../components/global/styledComponents/CustomInput.vue";
import { Sector } from "../../types/Sector";
import CustomButton from "../../components/global/styledComponents/customButton.vue";
import { DialogMutations, DialogType, IDialog } from "../../store/dialog";
import Loader from "../../components/global/Loader.vue";
import CustomerModal from "../../components/global/CustomerModal.vue";
import EditCustomer from "../../components/global/EditCustomer.vue";
import CustomerModalWindow from "../../components/global/CustomerModalWindow.vue";
import {
  formatDate,
  FormatSpaceType,
  formatTime,
} from "../../types/formatDateHelper";
import { formatDateStringHour } from "../../Utilities/dateUtility";
import { formatCurrency } from "@/Utilities/currencyHelper";

@Component({
  name: "EventsOverview",
  components: {
    CustomerModalWindow,
    EditCustomer,
    CustomerModal,
    Loader,
    CustomButton,
    CustomInput,
    DatePicker,
    muscleSelector,
  },
})
export default class EventsOverview extends Vue {
  public headers = [];

  @Getter public user;
  @Action public addToDialogQueue: Function;
  @Action public getUserEvents: Function;
  @Action public markEventsAsRead: Function;
  isLoading: boolean = true;
  events = [];
  selectedEvents = [];

  public async created(): Promise<any> {
    this.events = await this.getUserEvents();
    await this.markEventsAsRead();
    this.isLoading = false;
  }

  public async mounted(): Promise<any> {
    this.headers = [
      { text: "", align: "start", sortable: true, value: "severity" },
      {
        text: this.$t(this.$ts.eventsOverview.message),
        sortable: true,
        value: "message",
      },
      {
        text: this.$t(this.$ts.eventsOverview.time),
        sortable: true,
        value: "time",
      },
      {
        text: this.$t(this.$ts.eventsOverview.comment),
        sortable: true,
        value: "comment",
      },
      {
        text: this.$t(this.$ts.eventsOverview.booking),
        sortable: true,
        value: "messageData",
      },
      {
        text: this.$t(this.$ts.eventsOverview.receipt),
        sortable: true,
        value: "receipt",
      },
      {
        text: this.$t(this.$ts.eventsOverview.reminder),
        sortable: true,
        value: "reminder",
      },
      {
        text: this.$t(this.$ts.eventsOverview.seen),
        sortable: true,
        value: "hasSeen",
      },
    ];
  }

  formatCurrency(value: number): string {
    return formatCurrency(value);
  }

  getColor(eventId) {
    if (eventId == 0) return "grey";
    if (eventId == 1) return "red";
    if (eventId == 2) return "green";
    if (eventId == 3) return "orange";
    if (eventId == 4) return "red";
    if (eventId == 5) return "orange";
    if (eventId == 6) return "green";
    if (eventId == 7) return "green";
  }

  getLabel(eventId) {
    if (eventId == 0) return this.$t(this.$ts.eventsOverview.guest);
    if (eventId == 1) return this.$t(this.$ts.eventsOverview.critical);
    if (eventId == 2) return this.$t(this.$ts.eventsOverview.info);
    if (eventId == 3) return this.$t(this.$ts.eventsOverview.warning);
    if (eventId == 4) return this.$t(this.$ts.eventsOverview.bookingCancelled);
    if (eventId == 5) return this.$t(this.$ts.eventsOverview.bookingChanged);
    if (eventId == 6) return this.$t(this.$ts.eventsOverview.booking);
    if (eventId == 7) return this.$t(this.$ts.eventsOverview.customer);
  }
  eventIdToString(eventId) {
    if (eventId == 0) {
      return this.$t(this.$ts.eventsOverview.guest);
    }
    if (eventId == 1) {
      return this.$t(this.$ts.eventsOverview.errorWithSmsSending);
    }
    if (eventId == 2) {
      return this.$t(this.$ts.eventsOverview.timeAddedToExistingCustomer);
    }
    if (eventId == 3) {
      return this.$t(this.$ts.eventsOverview.timeAddedToExistingCustomer);
    }
    if (eventId == 4) {
      return this.$t(this.$ts.eventsOverview.timeCancelled);
    }
    if (eventId == 5) {
      return this.$t(this.$ts.eventsOverview.timeChanged);
    }
    if (eventId == 6) {
      return "";
    }
    if (eventId == 7) {
      return this.$t(this.$ts.eventsOverview.newCustomer);
    }
  }
  formatDateStringHour(date: Date) {
    return formatDateStringHour(date);
  }

  readComment(comment) {
    let dialog: IDialog = {
      text: comment,
      header: this.$t(this.$ts.eventsOverview.customerComment).toString(),
    };
    this.addToDialogQueue(dialog);
  }
}
