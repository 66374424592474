
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IAppointmentTypeDTO } from "@shared/types";
import CompanyInfoField from "@/components/global/CompanyInfoField.vue";

@Component({
  name: "service-selection",
  components: { CompanyInfoField },
})
export default class ServiceSelection extends Vue {
  @Prop({ default: false }) useMultipleServices!: boolean;
  @Prop() selectedServiceId!: number;
  @Prop() selectedServiceIds!: number[];
  @Prop() appointmentTypeItems!: IAppointmentTypeDTO[];
  @Prop() user!: any;

  selectServicesFilter(item: any, queryText: string) {
    const textOne = item.title.toLowerCase();
    return textOne.includes(queryText.toLowerCase());
  }

  onChangeServiceMultiple(e: number[]) {
    if (e.includes(-1)) {
      const lastElement = e[e.length - 1];
      this.$emit("update:selectedServiceId", -1);
      this.$emit("update:selectedServiceIds", [lastElement]);
      //@ts-ignore
      this.$refs.multipleselect.blur();
    } else {
      this.$emit("update:selectedServiceId", null);
      this.$emit("update:selectedServiceIds", e);
    }
    this.$emit("service-change");
  }

  onChangeService(e: number) {
    this.$emit("update:selectedServiceId", e);
    this.$emit("service-change");
    //@ts-ignore
    this.$refs.singleselect.blur();
  }
}
