
import Vue from "vue";
import Component from "vue-class-component";
import { Getter, Action } from "vuex-class";
import RegisterFirebaseAccount from "../RegisterFirebaseAccount.vue";
import PersonalAccountSettings from "./PersonalAccountSettings.vue";
import AccountRoleSettings from "./AccountRoleSettings.vue";
import { Watch } from "vue-property-decorator";
import { AccountRoleActions } from "@/store/accountRole";
import { DialogType } from "@/store/dialog";

@Component({
  name: "AccountSettings",
  components: {
    PersonalAccountSettings,
    RegisterFirebaseAccount,
    AccountRoleSettings,
  },
})
export default class AccountSettings extends Vue {
  @Action public addToDialogQueue!: Function;
  @Action(AccountRoleActions.GET_ROLES) getRoles!: Function;

  @Getter firebaseUser: any;
  @Getter public firebaseUsers: any[];
  @Getter public roles!: any[];
  @Action public getFirebaseUsersAsAdmin!: Function;
  @Action public addFirebaseUser!: Function;
  @Action public patchFirebaseUser!: Function;
  @Action public deleteFirebaseUser!: Function;

  public addingNewAccount: boolean = false;
  public newAccount: any = {};
  private isEditing: boolean = false;

  @Watch("addingNewAccount")
  onIsEditingNewAccount(newValue: boolean) {
    if (!newValue) this.isEditing = false;
  }

  initNewAccount() {
    this.newAccount = {
      email: "",
      roleId: null,
      password: "",
      password2: "",
      name: "",
    };
  }

  async created() {
    await Promise.all([this.getFirebaseUsersAsAdmin(), this.getRoles()]);
    this.initNewAccount();
  }

  async addNewAccount() {
    await this.addFirebaseUser(this.newAccount);
    this.addingNewAccount = false;
  }

  async saveAccountChanges(): Promise<void> {
    try {
      const updates: { name: string; role?: { id: number } | null } = {
        name: this.newAccount.name,
      };

      if (this.newAccount.roleId) {
        updates.role = { id: this.newAccount.roleId };
      }

      const payload = {
        firebaseUserId: this.newAccount.userId,
        patch: updates,
      };

      await this.patchFirebaseUser(payload);
      await this.getFirebaseUsersAsAdmin();
      this.addingNewAccount = false;
    } catch (error) {
      console.error(error);
    }
  }

  isOwner(account: any): boolean {
    return account === this.firebaseUsers[0];
  }

  getRoleDisplayName(account: any): string {
    if (this.isOwner(account)) {
      return this.$t(this.$ts.accountRole.owner).toString();
    }
    const role = this.roles.find((r) => r.id === account.roleId);
    return role ? role.name : "";
  }

  formatDate(timestamp: string): string {
    if (!timestamp) return "";
    const date = new Date(timestamp);
    return new Intl.DateTimeFormat(this.$i18n.locale, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }).format(date);
  }

  openNewAccountDialog() {
    this.initNewAccount();
    this.addingNewAccount = true;
  }

  editUser(account: any) {
    this.isEditing = true;
    this.addingNewAccount = true;
    this.newAccount = {
      ...account,
      roleId: account.role?.id,
    };
  }

  async deleteUser(account: any) {
    const vm = this;
    const dialog = {
      text: this.$t(this.$ts.account.deleteConfirmation).toString(),
      header: account.email,
      type: DialogType.Choice,
      action: async function () {
        try {
          await vm.deleteFirebaseUser(account.userId);
          vm.addToDialogQueue({
            text: vm.$t($ts.account.deleteSuccess).toString(),
          });
        } catch (error) {
          console.error(error);
          vm.addToDialogQueue({
            text: vm.$t($ts.account.deleteError).toString(),
            type: "error",
          });
        }
      },
    };

    this.addToDialogQueue(dialog);
  }
}
