
import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter } from "vuex-class";
import ToolTip from "../../ToolTip.vue";
import { IUserDTO } from "@shared/types";
import CompanyInfoField from "../../global/CompanyInfoField.vue";
import Textfield from "@/components/global/Textfield.vue";

@Component({
  name: "BookingNotificationSettings",
  components: { ToolTip, CompanyInfoField, Textfield },
})
export default class BookingNotificationSettings extends Vue {
  @Getter user!: IUserDTO;
  @Action patchUser!: Function;

  public recieveEmailOnAppointment: boolean = false;
  public recieveSMSOnAppointment: boolean = false;
  public emailAddressToReceive: string = "";
  public phoneNumberToReceive: string = "";
  public recieveWhatsAppOnAppointment: boolean = false;
  public whatsAppNumberToReceive: string = "";

  created() {
    this.recieveEmailOnAppointment = this.user.recieveEmailOnAppointment;
    this.recieveSMSOnAppointment = this.user.recieveSMSOnAppointment;
    this.recieveWhatsAppOnAppointment = this.user.recieveWhatsAppOnAppointment;
    this.emailAddressToReceive = this.user.emailAddressToReceive;
    this.phoneNumberToReceive = this.user.phoneNumberToReceive;
    this.whatsAppNumberToReceive = this.user.whatsAppNumberToReceive;
  }

  async saveChanges(field: string, value: string | boolean): Promise<void> {
    try {
      const updatedUserPayload: Partial<IUserDTO> = {
        [field]: value,
      };

      await this.patchUser(updatedUserPayload);
    } catch (error) {
      console.error(error);
    }
  }

  booleanToOnOff(value: boolean): string {
    return value
      ? this.$t(this.$ts.boolean.on).toString()
      : this.$t(this.$ts.boolean.off).toString();
  }
}
