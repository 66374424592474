
import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter } from "vuex-class";
import { IStaffMemberDTO } from "@shared/types";
import { Watch } from "vue-property-decorator";
import { DialogType, IDialog } from "@/store/dialog";
import ResponsiveTable from "@/components/global/ResponsiveTable.vue";
import CompanyInfoField from "@/components/global/CompanyInfoField.vue";
import Textfield from "@/components/global/Textfield.vue";
import { IStaffMemberPatch } from "@/store/staffMember";

@Component({
  name: "StaffMembersOverview",
  components: {
    ResponsiveTable,
    CompanyInfoField,
    Textfield,
  },
})
export default class StaffMembersOverview extends Vue {
  public createNewStaffMember: boolean = false;
  public editStaffMember: boolean = false;
  public newStaffMember: Partial<IStaffMemberDTO> | null = null;
  public selectedStaff: IStaffMemberDTO | null = null;

  @Action postStaffMember!: Function;
  @Action deleteStaffMember!: Function;
  @Action public getStaffMembers!: Function;
  @Action public addToDialogQueue!: Function;
  @Action public patchStaffMember!: Function;
  @Getter public staffMembers!: IStaffMemberDTO[];

  @Watch("createNewStaffMember")
  onNewStaffMemberToggle() {
    this.resetNewStaffMember();
  }

  created() {
    this.getStaffMembers();
    this.resetNewStaffMember();
  }

  resetNewStaffMember(): void {
    this.newStaffMember = {
      firstName: "",
      lastName: "",
      emailAddress: "",
      phoneNumber: "",
      role: "",
    };
  }

  get headers() {
    return [
      { text: "", align: "start", sortable: true, value: "severity" },
      {
        text: this.$t(this.$ts.staffMembersOverview.firstName),
        sortable: true,
        value: "firstName",
      },
      {
        text: this.$t(this.$ts.staffMembersOverview.lastName),
        sortable: true,
        value: "lastName",
      },
      {
        text: this.$t(this.$ts.staffMembersOverview.emailAddress),
        sortable: true,
        value: "emailAddress",
      },
      {
        text: this.$t(this.$ts.staffMembersOverview.phoneNumber),
        sortable: true,
        value: "phoneNumber",
      },
      {
        text: this.$t(this.$ts.staffMembersOverview.role),
        sortable: true,
        value: "role",
      },
      {
        text: this.$t(this.$ts.staffMembersOverview.lastLoggedIn),
        sortable: true,
        value: "lastLoggedIn",
      },
      {
        text: this.$t(this.$ts.staffMembersOverview.created),
        align: "start",
        sortable: true,
        value: "created",
      },
      {
        text: this.$t(this.$ts.staffMembersOverview.actions),
        align: "center",
        sortable: false,
        value: "actions",
      },
    ];
  }

  get items() {
    return this.staffMembers;
  }

  openNewStaffMember(): void {
    this.createNewStaffMember = true;
  }

  cancelNewStaffMember(): void {
    this.createNewStaffMember = false;
  }

  editStaff(staff: IStaffMemberDTO): void {
    this.selectedStaff = { ...staff };
    this.editStaffMember = true;
  }

  async confirmNewStaffMember(): Promise<void> {
    try {
      if (!this.newStaffMember) return;

      // Only validate required fields
      if (!this.newStaffMember.firstName || !this.newStaffMember.lastName) {
        this.addToDialogQueue({
          text: this.$t(
            "staffMembersOverview.requiredFieldsMissing"
          ).toString(),
        });
        return;
      }

      await this.postStaffMember(this.newStaffMember);
      this.createNewStaffMember = false;
    } catch (error) {
      console.error(error);
      this.addToDialogQueue({
        text: this.$t(
          this.$ts.staffMembersOverview.errorCreatingStaff
        ).toString(),
      });
    }
  }

  async confirmEditStaff(): Promise<void> {
    try {
      if (!this.selectedStaff) return;

      // Only validate required fields
      if (!this.selectedStaff.firstName || !this.selectedStaff.lastName) {
        this.addToDialogQueue({
          text: this.$t(
            "staffMembersOverview.requiredFieldsMissing"
          ).toString(),
        });
        return;
      }

      console.log("Updating", this.selectedStaff);

      const edit = {
        firstName: this.selectedStaff.firstName,
        lastName: this.selectedStaff.lastName,
        emailAddress: this.selectedStaff.emailAddress,
        phoneNumber: this.selectedStaff.phoneNumber,
        role: this.selectedStaff.role,
      };

      let patch: IStaffMemberPatch = {
        staffMemberId: this.selectedStaff.id,
        patch: edit,
      };

      await this.patchStaffMember(patch);
      this.editStaffMember = false;
    } catch (error) {
      console.error(error);
      this.addToDialogQueue({
        text: this.$t(
          this.$ts.staffMembersOverview.errorUpdatingStaff
        ).toString(),
      });
    }
  }

  async confirmDeleteStaff(staff: IStaffMemberDTO): Promise<void> {
    if (this.staffMembers.length === 1) {
      this.addToDialogQueue({
        text: this.$t(
          this.$ts.staffMembersOverview.minOneStaffRequired
        ).toString(),
      });
      return;
    }

    try {
      await this.deleteStaffMember(staff.id);
    } catch (error) {
      console.error(error);
      this.addToDialogQueue({
        text: this.$t(
          this.$ts.staffMembersOverview.errorDeletingStaff
        ).toString(),
      });
    }
  }

  deleteStaff(staff: IStaffMemberDTO): void {
    const vm = this;
    const dialog: IDialog = {
      text: this.$t(
        this.$ts.staffMembersOverview.confirmDeleteStaff
      ).toString(),
      type: DialogType.Choice,
      action: function () {
        vm.confirmDeleteStaff(staff);
      },
    };
    this.addToDialogQueue(dialog);
  }

  // Add validation rules as class properties
  private firstNameRules = [
    (v: string) =>
      !!v ||
      this.$t(this.$ts.staffMembersOverview.firstNameRequired).toString(),
  ];

  private lastNameRules = [
    (v: string) =>
      !!v || this.$t(this.$ts.staffMembersOverview.lastNameRequired).toString(),
  ];

  private emailRules = [
    (v: string) =>
      !v ||
      /.+@.+\..+/.test(v) ||
      this.$t(this.$ts.staffMembersOverview.invalidEmail).toString(),
  ];

  private phoneRules = [
    (v: string) =>
      !v ||
      /^[0-9+\-\s()]*$/.test(v) ||
      this.$t(this.$ts.staffMembersOverview.invalidPhone).toString(),
  ];
}
