// @ts-ignore
import { GetterTree, MutationTree, ActionTree } from "vuex";
// @ts-ignore
import axios, { AxiosRequestConfig } from "axios";
import { APIURL2 } from "@/main";
import { actionStringSnackbar } from "./snackbar";
import authConfig from "@/Utilities/authConfig";
import { i18n } from "@/main";
import { IAccountRoleDTO } from "@shared/types";

export const enum AccountRoleMutations {
  SET_ROLES = "setRoles",
}

export const enum AccountRoleActions {
  GET_ROLES = "getRoles",
  CREATE_ROLE = "createRole",
  UPDATE_ROLE = "updateRole",
  DELETE_ROLE = "deleteRole",
}

export interface AccountRoleState {
  roles: IAccountRoleDTO[];
}

export const state: AccountRoleState = {
  roles: [],
};

export const getters: GetterTree<AccountRoleState, AccountRoleState> = {
  roles: (state) => state.roles,
};

export const mutations: MutationTree<AccountRoleState> = {
  setRoles(state, payload: IAccountRoleDTO[]) {
    state.roles = payload;
  },
};

export const actions: ActionTree<AccountRoleState, any> = {
  async getRoles({ commit, dispatch }): Promise<any> {
    let header: AxiosRequestConfig = await authConfig();
    return new Promise((resolve, reject) => {
      axios
        .get(APIURL2 + "accountrole/current", header)
        .then((res) => {
          commit(AccountRoleMutations.SET_ROLES, res.data);
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          dispatch(actionStringSnackbar.postSnackbarMessage, err.message);
          reject(err);
        });
    });
  },

  async createRole(
    { dispatch },
    payload: Partial<IAccountRoleDTO>
  ): Promise<any> {
    let header: AxiosRequestConfig = await authConfig();
    return new Promise((resolve, reject) => {
      axios
        .post(APIURL2 + "accountrole/current", payload, header)
        .then((res) => {
          dispatch(
            actionStringSnackbar.postSnackbarMessage,
            i18n.t("settings.roleCreated").toString()
          );
          dispatch(AccountRoleActions.GET_ROLES);
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          dispatch(actionStringSnackbar.postSnackbarMessage, err.message);
          reject(err);
        });
    });
  },

  async updateRole(
    { dispatch },
    payload: { id: string; role: IAccountRoleDTO }
  ): Promise<any> {
    let header: AxiosRequestConfig = await authConfig();
    return new Promise((resolve, reject) => {
      axios
        .put(
          APIURL2 + `accountrole/current/${payload.id}`,
          payload.role,
          header
        )
        .then((res) => {
          dispatch(
            actionStringSnackbar.postSnackbarMessage,
            i18n.t("settings.roleUpdated").toString()
          );
          dispatch(AccountRoleActions.GET_ROLES);
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          dispatch(actionStringSnackbar.postSnackbarMessage, err.message);
          reject(err);
        });
    });
  },

  async deleteRole({ dispatch }, roleId: string): Promise<any> {
    let header: AxiosRequestConfig = await authConfig();
    return new Promise((resolve, reject) => {
      axios
        .delete(APIURL2 + `accountrole/current/${roleId}`, header)
        .then((res) => {
          dispatch(
            actionStringSnackbar.postSnackbarMessage,
            i18n.t("settings.roleDeleted").toString()
          );
          dispatch(AccountRoleActions.GET_ROLES);
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          dispatch(actionStringSnackbar.postSnackbarMessage, err.message);
          reject(err);
        });
    });
  },
};

export const accountRole = {
  state,
  getters,
  mutations,
  actions,
};
