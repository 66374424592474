
import { Bar, Line } from "vue-chartjs";
import { Component, Vue, Prop } from "vue-property-decorator";
import { getMonths } from "@/Utilities/dateUtility";
// @ts-ignore
@Component({
  extends: Line, // this is important to add the functionality to your component
})
export default class CommitChart extends Vue<Line> {
  @Prop({ default: null }) currentYear: Array<number>;
  @Prop({ default: null }) previousYear: Array<number>;

  public language: any;
  public gradient: any | null;
  public gradient2: any | null;
  public option: any = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
    legend: {
      display: true,
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  public mounted(): void {
    this.language = [
      {
        month: getMonths(),
      },
      {
        earnedThisYear: this.$t(this.$ts.commitChart.currentYear, {
          year: new Date().getFullYear(),
        }),
      },
      {
        earnedLastYear: this.$t(this.$ts.commitChart.previousYear, {
          year: new Date().getFullYear() - 1,
        }),
      },
    ];

    // Overwriting base render method with actual data.
    const canvas: any = this.$refs.canvas;

    this.gradient = canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);
    this.gradient2 = canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);

    this.gradient.addColorStop(0, "rgba(135, 106, 255, 0.8)");
    this.gradient.addColorStop(0.5, "rgba(135, 106, 255, 0.5)");
    this.gradient.addColorStop(1, "rgba(135, 106, 255, 0)");

    this.gradient2.addColorStop(0, "rgba(86, 220, 182, 0.5)");
    this.gradient2.addColorStop(0.5, "rgba(86, 220, 182, 0.5)");
    this.gradient2.addColorStop(1, "rgba(86, 220, 182, 0.5)");

    this.renderChart(
      {
        labels: this.language[0].month,
        datasets: [
          {
            label: this.$t(this.$ts.commitChart.currentYear, {
              year: new Date().getFullYear(),
            }).toString(),
            borderColor: "#876AFF",
            pointBackgroundColor: "#876AFF",
            borderWidth: 1,
            pointBorderColor: "#876AFF",
            backgroundColor: this.gradient,
            data: this.currentYear,
          },
          {
            label: this.$t(this.$ts.commitChart.previousYear, {
              year: new Date().getFullYear() - 1,
            }).toString(),
            borderColor: "#56dcb6",
            pointBackgroundColor: "#56dcb6",
            borderWidth: 1,
            pointBorderColor: "#56dcb6",
            backgroundColor: this.gradient2,
            data: this.previousYear,
          },
        ],
      } /*End of chart data*/,

      this.option
    );
  }
}
