import { GetterTree, MutationTree, ActionTree } from "vuex";

export enum UserPermissionMutations {
  SET_PERMISSIONS = "SET_PERMISSIONS",
}

export enum PermissionArea {
  APPOINTMENTS_AND_CLIENTS = "appointmentsAndClients",
  SETTINGS = "settings",
  STATISTICS_AND_FINANCES = "statisticsAndFinances",
  ADMINISTRATION = "administration",
}

export enum PermissionLevel {
  NONE = "none",
  READ = "read",
  READ_WRITE = "readwrite",
}

interface Permission {
  level: PermissionLevel;
}

interface Permissions {
  appointmentsAndClients: Permission;
  settings: Permission;
  statisticsAndFinances: Permission;
  administration: Permission;
}

interface UserPermissionState {
  permissions: Permissions | null;
}

const getAllPermissions = (): Permissions => ({
  appointmentsAndClients: { level: PermissionLevel.READ_WRITE },
  settings: { level: PermissionLevel.READ_WRITE },
  statisticsAndFinances: { level: PermissionLevel.READ_WRITE },
  administration: { level: PermissionLevel.READ_WRITE },
});

const state: UserPermissionState = {
  permissions: null,
};

const getters: GetterTree<UserPermissionState, any> = {
  hasPermission:
    (state) =>
    (area: keyof Permissions, requiredLevel: PermissionLevel): boolean => {
      if (!state.permissions) return false;

      const permission = state.permissions[area];
      if (!permission) return false;

      // Define permission hierarchy
      const levels: { [key in PermissionLevel]: number } = {
        [PermissionLevel.NONE]: 0,
        [PermissionLevel.READ]: 1,
        [PermissionLevel.READ_WRITE]: 2,
      };

      // Check if user's permission level is greater than or equal to required level
      return levels[permission.level] >= levels[requiredLevel];
    },

  getPermissions: (state) => state.permissions,

  // Appointments & Clients
  canViewAppointmentsAndClients: (state, getters): boolean => {
    return getters.hasPermission(
      PermissionArea.APPOINTMENTS_AND_CLIENTS,
      PermissionLevel.READ
    );
  },
  canWriteAppointmentsAndClients: (state, getters): boolean => {
    return getters.hasPermission(
      PermissionArea.APPOINTMENTS_AND_CLIENTS,
      PermissionLevel.READ_WRITE
    );
  },

  // Settings
  canViewSettings: (state, getters): boolean => {
    return getters.hasPermission(PermissionArea.SETTINGS, PermissionLevel.READ);
  },
  canWriteSettings: (state, getters): boolean => {
    return getters.hasPermission(
      PermissionArea.SETTINGS,
      PermissionLevel.READ_WRITE
    );
  },

  // Statistics & Finances
  canViewStatisticsAndFinances: (state, getters): boolean => {
    return getters.hasPermission(
      PermissionArea.STATISTICS_AND_FINANCES,
      PermissionLevel.READ
    );
  },
  canWriteStatisticsAndFinances: (state, getters): boolean => {
    return getters.hasPermission(
      PermissionArea.STATISTICS_AND_FINANCES,
      PermissionLevel.READ_WRITE
    );
  },

  // Administration
  canViewAdministration: (state, getters): boolean => {
    return getters.hasPermission(
      PermissionArea.ADMINISTRATION,
      PermissionLevel.READ
    );
  },
  canWriteAdministration: (state, getters): boolean => {
    return getters.hasPermission(
      PermissionArea.ADMINISTRATION,
      PermissionLevel.READ_WRITE
    );
  },
};

const mutations: MutationTree<UserPermissionState> = {
  [UserPermissionMutations.SET_PERMISSIONS](state, permissions: Permissions) {
    state.permissions = permissions;
  },
};

const actions: ActionTree<UserPermissionState, any> = {
  setUserPermissions({ commit }, firebaseUser: any) {
    // If user is owner or first user (no role), grant all permissions
    if (firebaseUser?.isOwner || !firebaseUser?.role) {
      commit(UserPermissionMutations.SET_PERMISSIONS, getAllPermissions());
    }
    // Otherwise use role permissions if they exist
    else if (firebaseUser?.role?.permissions) {
      commit(
        UserPermissionMutations.SET_PERMISSIONS,
        firebaseUser.role.permissions
      );
    }
    // Fallback to no permissions
    else {
      commit(UserPermissionMutations.SET_PERMISSIONS, null);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
