import { i18n } from "@/main";

import { ts } from "@/main";

//TODO: Duplciate from reinforcedtypings.ts i need to make export enums!!!!
export enum ValidationType {
  Required = 0,
  Min = 1,
  Max = 2,
  Number = 3,
  Email = 4,
  Phone = 5,
  Length = 6,
  Pattern = 7,
}
interface ValidationRule {
  type: ValidationType;
  value?: any;
}

interface ValidationStrategy {
  validate(value: any, rule: ValidationRule): boolean | string;
}

class RequiredStrategy implements ValidationStrategy {
  validate(value: any): boolean | string {
    return (
      (value !== null && value !== undefined && value !== "") ||
      i18n.t(ts.validation.required).toString()
    );
  }
}

class MinStrategy implements ValidationStrategy {
  validate(value: any, rule: ValidationRule): boolean | string {
    return (
      value >= rule.value ||
      i18n.t("validation.min", { value: rule.value }).toString()
    );
  }
}

class MaxStrategy implements ValidationStrategy {
  validate(value: any, rule: ValidationRule): boolean | string {
    return (
      value <= rule.value ||
      i18n.t("validation.max", { value: rule.value }).toString()
    );
  }
}

class NumberStrategy implements ValidationStrategy {
  validate(value: any): boolean | string {
    return !isNaN(value) || i18n.t("validation.number").toString();
  }
}

class EmailStrategy implements ValidationStrategy {
  validate(value: any): boolean | string {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value) || i18n.t("validation.email").toString();
  }
}

class PhoneStrategy implements ValidationStrategy {
  validate(value: any): boolean | string {
    return /^\+?[\d\s-]+$/.test(value) || i18n.t("validation.phone").toString();
  }
}

class LengthStrategy implements ValidationStrategy {
  validate(value: any, rule: ValidationRule): boolean | string {
    const length = value?.toString().length || 0;
    return (
      length <= rule.value ||
      i18n.t("validation.length", { value: rule.value }).toString()
    );
  }
}

export class ValidationStrategyService {
  private strategies: { [key in ValidationType]: ValidationStrategy } = {
    [ValidationType.Required]: new RequiredStrategy(),
    [ValidationType.Min]: new MinStrategy(),
    [ValidationType.Max]: new MaxStrategy(),
    [ValidationType.Number]: new NumberStrategy(),
    [ValidationType.Email]: new EmailStrategy(),
    [ValidationType.Phone]: new PhoneStrategy(),
    [ValidationType.Length]: new LengthStrategy(),
    [ValidationType.Pattern]: new RequiredStrategy(), // Placeholder, implement if needed
  };

  addStrategy(type: ValidationType, strategy: ValidationStrategy): void {
    this.strategies[type] = strategy;
  }

  createValidator(
    rule: ValidationRule,
    fieldName: string
  ): (v: any) => boolean | string {
    const strategy = this.strategies[rule.type];
    if (!strategy) {
      console.warn(`No strategy found for rule type: ${rule.type}`);
      return () => true;
    }

    return (value: any) => {
      const result = strategy.validate(value, rule);
      if (typeof result === "string") {
        return this.formatValidationMessage(result, fieldName, rule);
      }
      return result;
    };
  }

  private formatValidationMessage(
    message: string,
    fieldName: string,
    rule: ValidationRule
  ): string {
    return message
      .replace("{field}", fieldName)
      .replace("{value}", rule.value?.toString() || "");
  }
}
