
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import router from "../../router";
import { user } from "../../store/user";
import { logout } from "@/Utilities/authConfig";
import { DialogType, IDialog } from "../../store/dialog";
import NotificationBell from "./NotificationBell.vue";
import { PermissionArea, PermissionLevel } from "@/store/userPermissions";
import { IUserDTO } from "@shared/types";
import { TranslateResult } from "vue-i18n";

interface NavigationItem {
  alt: string;
  text: string;
  image: string;
  route: string;
  icon: string;
  onlySmallMenu?: boolean;
}

@Component({ name: "sidebar", components: { NotificationBell } })
export default class Sidebar extends Vue {
  readonly PermissionArea = PermissionArea;
  readonly PermissionLevel = PermissionLevel;

  @Getter("hasPermission", { namespace: "userPermissions" })
  hasPermission!: Function;

  get canViewAppointments(): boolean {
    return this.hasPermission(
      PermissionArea.APPOINTMENTS_AND_CLIENTS,
      PermissionLevel.READ
    );
  }

  get itemlist(): NavigationItem[] {
    const items: NavigationItem[] = [
      {
        alt: "home",
        text: "home",
        image: "../img/dashboard.png",
        route: "/admin",
        icon: "home",
      },
    ];

    // Only add these items if user has permission to view appointments and clients
    if (this.canViewAppointments) {
      items.push(
        {
          alt: "customers",
          text: "customers",
          image: "../img/customers.png",
          route: "/kunder",
          icon: "account_circle",
        },
        {
          alt: "calendar",
          text: "calendar",
          image: "../img/calendars.png",
          route: "/kalender",
          icon: "calendar_today",
        }
      );
    }

    items.push({
      alt: "settings",
      text: "settings",
      image: "../img/settings.png",
      route: "/settings",
      icon: "calendar_today",
      onlySmallMenu: true,
    });

    return items;
  }

  get appointmentTabs() {
    if (this.user == null) {
      return [];
    }

    const canViewStatistics = this.hasPermission(
      PermissionArea.STATISTICS_AND_FINANCES,
      PermissionLevel.READ
    );

    const baseItems = [
      {
        alt: "overview",
        children: [],
        text: "overview",
        image: "../img/overview.png",
        route: "/bestillinger",
        icon: "event_note",
      },
    ];

    if (this.user.enablePaymentModule) {
      baseItems.push(
        {
          alt: "payments",
          text: "payments",
          image: "../img/paymenticon.png",
          route: "/betalinger",
          icon: "event_note",
        },
        {
          alt: "invoices",
          text: "invoices",
          image: "../img/commerce.png",
          route: "/faktura",
          icon: "event_note",
        }
      );

      if (canViewStatistics) {
        baseItems.push({
          alt: "statistics",
          text: "statistics",
          image: "../img/increasing-chart.png",
          route: "/statistikk",
          icon: "insert_chart",
        });
      }

      baseItems.push({
        alt: "export",
        text: "export",
        image: "../img/export.png",
        route: "/eksporter",
        icon: "calendar_today",
      });
    } else {
      baseItems.push(
        ...(canViewStatistics
          ? [
              {
                alt: "statistikk",
                text: "statistics",
                image: "../img/increasing-chart.png",
                route: "/statistikk",
                icon: "insert_chart",
              },
            ]
          : []),
        {
          alt: "eksporter",
          text: "export",
          image: "../img/export.png",
          route: "/eksporter",
          icon: "calendar_today",
        }
      );
    }

    return baseItems;
  }

  public showListGroup = false;

  @Action public getCurrentUser: Function;
  @Action public addToDialogQueue: Function;
  @Action public getUnseenEvents: Function;
  @Getter public unseenEvents: Function;
  @Mutation public setMini: Function;
  @Getter public user: IUserDTO;

  @Getter public firebaseUserAccount;

  @Getter mini: Boolean;

  public drawer: boolean = true;
  // public mini:boolean = false;

  // @Getter(getterStringUser.HAS_SUBSCRIPTION) hasSubscription:boolean;
  // @Mutation(mutationStringUser.SET_HAS_SUBSCRIPTION) setHasSubscription:(payload:boolean) => void;

  get getSidebarWidth() {
    if (this.mini) {
      return 80;
    } else {
      return 256;
    }
  }

  toggleMini() {
    this.setMini(!this.mini);

    if (this.mini) {
      localStorage.setItem("sidebarmini", "true");
    } else {
      localStorage.setItem("sidebarmini", "false");
    }
  }

  get displayProfilePicture() {
    return this.user != null && this.user.profilePictureUrl != null;
  }

  openChat(): void {
    window.open("http://m.me/availify/", "_blank");
  }

  public goToSettingsPage(): void {
    this.$router.push("/settings").catch(() => {});
  }

  public async created(): Promise<void> {
    let defaultMini = localStorage.getItem("sidebarmini");
    if (defaultMini != null) {
      if (defaultMini == "true") {
        this.setMini(true);
      } else {
        this.setMini(false);
      }
    }
  }

  @Watch("$route")
  onChangeRoute(newRoute, oldroute) {
    if (
      newRoute.name == "Bestillinger" ||
      newRoute.name == "Bestilling" ||
      newRoute.name == "Faktura" ||
      newRoute.name == "Statistikk" ||
      newRoute.name == "Betalinger" ||
      newRoute.name == "Eksporter"
    ) {
      this.showListGroup = true;
    } else {
      this.showListGroup = false;
    }
  }

  get showNotificationBell(): boolean {
    return this.canViewAppointments;
  }

  get appointmentsLabel(): string {
    return this.$t(this.$ts.sidebar.appointments).toString();
  }
}
