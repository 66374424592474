
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Action } from "vuex-class";
import CompanyInfoField from "@/components/global/CompanyInfoField.vue";
import { IUserDTO } from "@shared/types";
import { IDialog } from "@/store/dialog";

@Component({
  components: {
    CompanyInfoField,
  },
})
export default class KidSettings extends Vue {
  @Prop({ required: true }) user!: IUserDTO;
  @Action public patchUser: Function;
  @Action public postSnackbarMessage: Function;
  @Action public addToDialogQueue: Function;

  useKID: boolean = false;
  kidLength: number = -1;
  kidMod: number = -1;

  created() {
    this.useKID = this.user.useKID;
    this.kidLength = this.user.kidLength;
    this.kidMod = this.user.kidMod;
  }

  async saveChanges(field: string, value: any) {
    if (field === "kidLength" && (value > 25 || value < 2)) {
      let dialog: IDialog = {
        text: this.$t(
          $ts.invoiceSettings.kidNumber.invalidKidLength
        ).toString(),
      };
      this.addToDialogQueue(dialog);
      return;
    }

    const payload: Partial<IUserDTO> = {
      [field]: value,
    };
    await this.patchUser(payload);
    this.postSnackbarMessage(this.$t(this.$ts.messages.updated).toString());
  }

  booleanToNorwegian(value: boolean): string {
    return value
      ? this.$t(this.$ts.boolean.yes).toString()
      : this.$t(this.$ts.boolean.no).toString();
  }
}
