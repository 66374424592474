
import Vue from "vue";
import Component from "vue-class-component";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import CustomButton from "./global/styledComponents/customButton.vue";
import CustomInput from "./global/styledComponents/CustomInput.vue";

import firebase from "firebase/app";
import { Prop } from "vue-property-decorator";
import "firebase/auth"; // Import only the auth module. improtant. otherwise it all willl break apart like a unmitigated disaster.
const firebaseAuth = firebase.auth();

@Component({
  name: "RegisterFirebaseAccount",
  components: { CustomInput, CustomButton },
})
export default class RegisterFirebaseAccount extends Vue {
  @Prop({ default: false }) public onBoardingOnRegister: boolean;

  @Action public postSnackbarMessage: (message: string) => void;

  hasRegistered: boolean = false;

  password: string = "";
  password2: string = "";
  email: string = "";

  created() {}

  public async register(): Promise<void> {
    if (this.email === "" || this.password == "") {
      this.postSnackbarMessage(
        this.$t(this.$ts.registerFirebase.emailAndPasswordRequired).toString()
      );
      return;
    }

    if (this.password !== this.password2) {
      this.postSnackbarMessage(
        this.$t(this.$ts.registerFirebase.passwordsMustMatch).toString()
      );
      return;
    }

    try {
      if (this.onBoardingOnRegister) {
        this.$store.commit("setIsNewUser", true);
      }
      this.hasRegistered = true;

      await firebaseAuth.createUserWithEmailAndPassword(
        this.email,
        this.password
      );
    } catch (error) {
      this.$store.commit("setIsNewUser", false);
      let errorCode = error.code;
      if (errorCode === "auth/email-already-in-use") {
        this.postSnackbarMessage(
          this.$t(this.$ts.registerFirebase.emailAlreadyInUse).toString()
        );
      }
      if (errorCode === "auth/invalid-email") {
        this.postSnackbarMessage(
          this.$t(this.$ts.registerFirebase.invalidEmail).toString()
        );
      }
      if (errorCode === "auth/weak-password") {
        this.postSnackbarMessage(
          this.$t(this.$ts.registerFirebase.passwordTooWeak).toString()
        );
      }
    }
  }
}
