
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { onboardingProgressType } from "@/types/OnboardingProgress";
import { Sector } from "@/types/Sector";
import { IUserDTO } from "@shared/types";
import CompanyInfoField from "../CompanyInfoField.vue";
import Textfield from "../Textfield.vue";

export interface ILocalUser {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

@Component({
  name: "Register-user",
  components: {
    Textfield,
    CompanyInfoField,
  },
})
export default class RegisterUser extends Vue {
  @Prop() public currentStep;

  localUser: ILocalUser = { firstName: "", lastName: "", phoneNumber: "" };
  menu: boolean = false;
  modal: boolean = false;
  hasGottenUser: boolean = false;

  @Action updateUserProfile: Function;
  @Action getCurrentUser: Function;
  @Action postSnackbarMessage: (message: string) => void;
  @Getter user: IUserDTO;

  @Action patchUser: (payload: Partial<IUserDTO>) => Promise<IUserDTO>;

  validateFormFields(): boolean {
    if (this.localUser.firstName === "") {
      this.postSnackbarMessage(
        this.$t(this.$ts.login.firstNameRequired).toString()
      );
      return false;
    }
    if (this.localUser.lastName === "") {
      this.postSnackbarMessage(
        this.$t(this.$ts.login.lastNameRequired).toString()
      );
      return false;
    }
    return true;
  }

  clickHandler(): void {
    this.$emit("register-user-click", this.validateFormFields());
  }

  @Watch("currentStep")
  checkElValue(newval, oldVal) {
    if (oldVal === 1) {
      let update: Partial<IUserDTO> = {
        firstName: this.localUser.firstName,
        lastName: this.localUser.lastName,
        phoneNumber: this.localUser.phoneNumber,
        //@ts-ignore, I have to fix reinforced typings - or just create my own god damn python script
        language: this.$i18n.locale,
      };

      //TODO: Remove this function, I think this is broken.. its not used. authHander.ts is actually doing this.
      if (this.validateFormFields()) {
        //Information for initial staff member
        this.updateUserProfile(update);
      }
    }
  }
}
