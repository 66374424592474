
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { actionStringCustomers, ICustomer } from "../../store/customers";
import { ICustomerJournals } from "../../store/customerJournals";
import muscleSelector from "../../components/global/muscleGroupSelector.vue";
import DatePicker from "../../components/global/DatePicker.vue";
import CustomInput from "../../components/global/styledComponents/CustomInput.vue";
import { Sector } from "../../types/Sector";
import { IUser } from "../../store/user";
import CustomButton from "../../components/global/styledComponents/customButton.vue";
import { DialogMutations, DialogType, IDialog } from "../../store/dialog";
import Loader from "../../components/global/Loader.vue";
import CustomerModal from "../../components/global/CustomerModal.vue";
import EditCustomer from "../../components/global/EditCustomer.vue";
import CustomerModalWindow from "../../components/global/CustomerModalWindow.vue";
import {
  formatDate,
  FormatSpaceType,
  formatTime,
} from "../../types/formatDateHelper";
import { IInvoice, InvoiceActions } from "../../store/Invoices";
import AppointmentList from "./AppointmentList.vue";
import { AppointmentInfo } from "../../store/appointments/appointmentTypings";
import InvoiceCreator from "../../components/InvoiceCreator.vue";
import InvoicesList from "../../components/InvoicesList.vue";

@Component({
  name: "EventsOverview",
  components: {
    InvoicesList,
    InvoiceCreator,
    AppointmentList,
    CustomerModalWindow,
    EditCustomer,
    CustomerModal,
    Loader,
    CustomButton,
    CustomInput,
    DatePicker,
  },
})
export default class EventsOverview extends Vue {
  @Getter public user: IUser;
  @Action public addToDialogQueue: Function;
  @Action public postSnackbarMessage: Function;

  @Action(InvoiceActions.GetInvoices) public getInvoices: () => Promise<
    Array<IInvoice>
  >;
  @Getter public invoices: Array<IInvoice>;

  public newInvoice: boolean = false;
  public isLoading: boolean = true;

  selectedEvents = [];

  public async created(): Promise<any> {
    this.loadInvoices();
  }

  async loadInvoices() {
    this.isLoading = true;
    await this.getInvoices();
    this.isLoading = false;
  }

  onFinished() {
    this.newInvoice = false;
  }
}
