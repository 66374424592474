// @ts-ignore
import { GetterTree, MutationTree, ActionTree } from "vuex";
import axios from "axios";
import { APIURL2 } from "@/main";
import { IFieldMetadata, IFormsMetadataDTO } from "@shared/types";
import authConfig from "@/Utilities/authConfig";
import { actionStringSnackbar } from "./snackbar";

enum FormType {
  AppointmentType = 0,
  ServiceCategory = 1,
  AccountRole = 2,
}
// Interface
export interface MetadataState {
  formsMetadata: Record<Lowercase<keyof typeof FormType>, IFieldMetadata[]>;
}

// Action/Mutation string constants
export const actionStringMetadata = {
  FETCH_FORMS_METADATA: "fetchFormsMetadata",
} as const;

export const mutationStringMetadata = {
  SET_FORMS_METADATA: "setFormsMetadata",
} as const;

export const state: MetadataState = {
  formsMetadata: {
    appointmenttype: [],
    servicecategory: [],
    accountrole: [],
  },
};

export const getters: GetterTree<MetadataState, any> = {
  appointmentTypeMetadata: (state) =>
    state.formsMetadata[FormType[FormType.AppointmentType].toLowerCase()] || [],
  serviceCategoryMetadata: (state) =>
    state.formsMetadata[FormType[FormType.ServiceCategory].toLowerCase()] || [],
  accountRoleMetadata: (state) =>
    state.formsMetadata[FormType[FormType.AccountRole].toLowerCase()] || [],
};

//Maybe get it dyanmically from DTO naming convention
export const mutations: MutationTree<MetadataState> = {
  [mutationStringMetadata.SET_FORMS_METADATA](
    state,
    payload: Record<string, IFieldMetadata[]>
  ) {
    state.formsMetadata = {
      appointmenttype: payload.appointmenttype || [],
      servicecategory: payload.servicecategory || [],
      accountrole: payload.accountrole || [],
    };
  },
};

export const actions: ActionTree<MetadataState, any> = {
  async [actionStringMetadata.FETCH_FORMS_METADATA]({
    commit,
    dispatch,
  }): Promise<void> {
    try {
      const header = await authConfig();
      const { data } = await axios.get<IFormsMetadataDTO>(
        `${APIURL2}metadata/forms`,
        header
      );
      commit(mutationStringMetadata.SET_FORMS_METADATA, data.forms);
    } catch (error) {
      console.error("Failed to fetch forms metadata:", error);
      dispatch(actionStringSnackbar.postSnackbarMessage, error.message);
      throw error;
    }
  },
};

export const metadata = {
  state,
  getters,
  mutations,
  actions,
};
