var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}},[(_vm.editingRole)?_c('DynamicForm',{attrs:{"data":_vm.editingRole,"entity":_vm.$t(this.$ts.entities.accountRole),"metadata":_vm.accountRoleMetadata,"translation-path":_vm.nonReactiveTranslations,"is-editing":_vm.isEditing,"is-creating":_vm.isCreating,"edit-title":_vm.$ts.accountRole.editRole,"create-title":_vm.$ts.accountRole.createRole},on:{"save":_vm.onSave,"create":_vm.onSave,"cancel":_vm.cancelEdit}}):_vm._e()],1),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":[
          { text: _vm.$t(_vm.$ts.accountRole.title), value: 'name' },
          {
            text: _vm.$t(_vm.$ts.accountRole.actions),
            value: 'actions',
            sortable: false,
          },
        ],"items":_vm.roles},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.startEdit(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteRoleHandler(item.id)}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }