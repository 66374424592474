// @ts-ignore
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
// @ts-ignore
import { GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState, store } from "@/store/store";
import { APIURL2 } from "@/main";
import authConfig, { AuthConfig } from "@/Utilities/authConfig";
import { actionStringSnackbar } from "@/store/snackbar";
import { ICustomFieldDTO, IResourceDTO } from "@shared/types";

const CONTROLLER_NAME = "CustomField";

// Interface
export interface CustomFieldsState {
  customFields: Array<ICustomFieldDTO>;
}

export const state: CustomFieldsState = {
  customFields: [],
};

export const getters: GetterTree<CustomFieldsState, CustomFieldsState> = {
  customFields: (state) => state.customFields,
};

export const mutations: MutationTree<CustomFieldsState> = {
  setCustomFields(state, payload: Array<ICustomFieldDTO>) {
    console.log("Set custom fieelds: ", payload);
    state.customFields = payload;
  },
};

export const actions: ActionTree<CustomFieldsState, CustomFieldsState> = {
  async getCustomFields({ commit }) {
    let API_RESOURCE = APIURL2 + CONTROLLER_NAME;

    let header: AxiosRequestConfig = await authConfig();
    try {
      let res: AxiosResponse<Array<IResourceDTO>> = await axios.get(
        API_RESOURCE + "/current",
        header
      );
      commit("setCustomFields", res.data);
    } catch (e) {
      console.log(e);
    }
  },

  async putCustomField(
    { commit, dispatch },
    payload: Partial<ICustomFieldDTO>
  ) {
    let API_RESOURCE = APIURL2 + CONTROLLER_NAME;

    let header: AxiosRequestConfig = await authConfig();
    try {
      await axios.put(API_RESOURCE + "/current", payload, header);
      dispatch("getCustomFields");
    } catch (e) {
      console.log(e);
    }
  },

  async postCustomField(
    { commit, dispatch },
    payload: Partial<ICustomFieldDTO>
  ) {
    let API_RESOURCE = APIURL2 + CONTROLLER_NAME;

    let header: AxiosRequestConfig = await authConfig();
    try {
      await axios.post(API_RESOURCE + "/current", payload, header);
      dispatch("getCustomFields");
    } catch (e) {
      console.log(e);
    }
  },

  async deleteCustomField({ commit, dispatch }, id: number) {
    let API_RESOURCE = APIURL2 + CONTROLLER_NAME;

    let header: AxiosRequestConfig = await authConfig();
    try {
      await axios.delete(API_RESOURCE + "/current/" + id, header);
      dispatch("getCustomFields");
    } catch (e) {
      console.log(e);
    }
  },
};

export const customFields = {
  actions,
  mutations,
  getters,
  state,
};
