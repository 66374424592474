
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Action } from "vuex-class";
import CompanyInfoField from "@/components/global/CompanyInfoField.vue";
import { IUserDTO } from "@shared/types";

@Component({
  components: {
    CompanyInfoField,
  },
})
export default class GeneralSettings extends Vue {
  @Prop({ required: true }) user!: IUserDTO;
  @Action public patchUser: Function;
  @Action public postSnackbarMessage: Function;

  dueDateLength: number = 30;
  taxPercentage: number = 25;
  taxNumber: string = "";
  bankAccount: string = "";

  created() {
    this.dueDateLength = this.user.dueDateLength;
    this.taxPercentage = this.user.taxPercentage;
    this.taxNumber = this.user.organizationNumber || "";
    this.bankAccount = this.user.bankAccount || "";
  }

  async saveChanges(field: string, value: any) {
    const payload: Partial<IUserDTO> = {
      [field]: value,
    };
    await this.patchUser(payload);
    this.postSnackbarMessage(this.$t(this.$ts.messages.updated).toString());
  }
}
