import { render, staticRenderFns } from "./InvoicesOverview.vue?vue&type=template&id=6f1bb76f&scoped=true&"
import script from "./InvoicesOverview.vue?vue&type=script&lang=ts&xmlns%3Av-slot=http%3A%2F%2Fwww.w3.org%2F1999%2FXSL%2FTransform&"
export * from "./InvoicesOverview.vue?vue&type=script&lang=ts&xmlns%3Av-slot=http%3A%2F%2Fwww.w3.org%2F1999%2FXSL%2FTransform&"
import style0 from "./InvoicesOverview.vue?vue&type=style&index=0&id=6f1bb76f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f1bb76f",
  null
  
)

export default component.exports