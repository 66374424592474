
import { Component, Vue, Prop } from "vue-property-decorator";
import type { IAppointmentTypeDTO } from "@shared/types";
import type { IServiceCategory } from "@/store/appointmentTypes";
import ResponsiveTable from "@/components/global/ResponsiveTable.vue";
import { formatCurrency } from "@/Utilities/currencyHelper";
interface Category {
  id: string;
  title: string;
}

@Component({
  name: "treatment-type-table",
  components: {
    ResponsiveTable,
  },
})
export default class TreatmentTypeTable extends Vue {
  @Prop({ required: true }) services!: IAppointmentTypeDTO[];
  @Prop({ required: true }) categories!: Category[];
  @Prop({ required: true }) extraServices!: IAppointmentTypeDTO[];
  @Prop({ required: true }) isExtra!: boolean;

  get categoryOptions(): string[] {
    return this.categories.map((x) => x.title);
  }

  formatCurrency(value: number): string {
    return formatCurrency(value);
  }

  getCategoryTitleById(id: string): string {
    const cat = this.categories.find((x) => x.id === id);
    return cat?.title || "";
  }

  getCategoryIdByTitle(title: string): string {
    const cat = this.categories.find((x) => x.title === title);
    return cat?.id ?? "";
  }

  onReorder(serviceId: string, direction: number): void {
    this.$emit("reorder", { serviceId, direction });
  }

  onCategoryChange(categoryName: string, serviceId: string): void {
    const categoryId = this.getCategoryIdByTitle(categoryName);
    this.$emit("category-change", { serviceId, categoryId, order: 1 });
  }

  onExtraServicesChange(
    extraServices: IAppointmentTypeDTO[],
    serviceId: string
  ): void {
    this.$emit("extra-services-change", { serviceId, extraServices });
  }
}
