
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

interface PauseTime {
  id: number;
  start: string;
  end: string;
}

@Component({
  name: "BreakTimes",
})
export default class BreakTimes extends Vue {
  @Prop({ required: true }) pauseTimes!: PauseTime[];
  @Prop({ required: true }) start!: string;
  @Prop({ required: true }) end!: string;

  localStart = "";
  localEnd = "";

  public timeRules = [
    (v) => !!v || this.$t(this.$ts.workingHours.timeRequired).toString(),
    (v) => {
      let pattern = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
      return (
        pattern.test(v) ||
        this.$t(this.$ts.workingHours.invalidFormat).toString()
      );
    },
  ];

  @Watch("start")
  onStartChange(val: string) {
    this.localStart = val;
  }

  @Watch("end")
  onEndChange(val: string) {
    this.localEnd = val;
  }

  created() {
    this.localStart = this.start;
    this.localEnd = this.end;
  }

  updateStart(val: string) {
    this.$emit("update:start", val);
  }

  updateEnd(val: string) {
    this.$emit("update:end", val);
  }

  get isValid() {
    return this.timeRules.every(
      (rule) => rule(this.localStart) === true && rule(this.localEnd) === true
    );
  }
}
