
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { Sector } from "../../../types/Sector";
import { getterStringUser } from "../../../store/user";
import CompanyInfoField from "../CompanyInfoField.vue";
import Textfield from "../Textfield.vue";
import { IUserDTO } from "@shared/types";

@Component({
  name: "Register-company",
  components: {
    Textfield,
    CompanyInfoField,
  },
})
export default class RegisterCompany extends Vue {
  @Prop() public el;

  public localUser = {
    companyName: "",
    treatmentAdress: "",
  };
  public hasCompanyName: Boolean = false;

  @Action public getCurrentUser: Function;
  @Action public updateUserProfile: Function;
  @Getter public user: IUserDTO;
  @Action public postSnackbarMessage: (message: string) => void;
  @Getter(getterStringUser.SELECTED_SECTOR) selectedSector: Sector;
  @Action patchUser: (payload: Partial<IUserDTO>) => Promise<IUserDTO>;

  validateFormFields(): boolean {
    if (this.user.companyName === null) {
      this.postSnackbarMessage("Du må oppgi et bedriftsnavn");
      return false;
    }
    if (this.user.companyName.length < 3) {
      this.postSnackbarMessage("Bedriftsnavnet må inneholde minst 3 bokstaver");
      return false;
    }
    if (this.user.treatmentAdress === null) {
      this.postSnackbarMessage("Du må oppgi en adresse");
      return false;
    }

    return true;
  }

  clickHandler(): void {
    this.$emit("register-user-click", this.validateFormFields());
  }

  @Watch("el")
  async checkElValue(newval) {
    //Check onboarding progress
    if (newval == 2) {
      if (this.user.companyName !== "") {
        this.hasCompanyName = true;
      }
    }

    if (newval == 3) {
      let sector = this.selectedSector;
      if (sector == null) {
        sector = 3; //Temporarily default to "General booking" TODO: Make sure sector must be chosen on home page (New user? Register here doesn't select sector)
      }
      let updatedUser: Partial<IUserDTO> = {
        companyName: this.user.companyName,
        treatmentAdress: this.user.treatmentAdress,
      }; //The order matters, unfortunately
      if (this.validateFormFields()) {
        await this.patchUser(updatedUser);
        this.getCurrentUser();
      }
    }
  }

  public created(): void {
    this.getCurrentUser();
  }

  get typedUser(): IUserDTO {
    return this.user;
  }
}
