
import { Component, Vue, Prop } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { IAppointmentTypeDTO } from "@shared/types";
import { DialogMutations, DialogType, type IDialog } from "@/store/dialog";
import TreatmentTypeTable from "./components/TreatmentTypeTable.vue";
import Loader from "@/components/global/Loader.vue";
import DynamicForm from "./components/DynamicForm.vue";
import { IFieldMetadata } from "@shared/types";

interface Category {
  id: string;
  title: string;
}

@Component({
  name: "treatment-types",
  components: {
    Loader,
    DynamicForm,
    TreatmentTypeTable,
  },
})
export default class TreatmentTypes extends Vue {
  @Prop({ default: false }) public isOnboarding!: boolean;
  @Prop({ default: false }) public extra!: boolean;

  @Getter public extraServices!: IAppointmentTypeDTO[];
  @Getter public appointmentType!: IAppointmentTypeDTO[];
  @Getter public categories!: any[];
  @Getter public staffMembers!: any[];
  @Getter public appointmentTypeMetadata!: any[];

  @Action public getCurrentUser!: () => Promise<void>;
  @Action public getAppointmentTypes!: (extra: boolean) => Promise<void>;
  @Action public getCategories!: () => Promise<void>;
  @Action public editAppointmentType!: (
    treatment: Partial<IAppointmentTypeDTO>
  ) => Promise<void>;
  @Action public addNewAppointmentType!: (
    treatment: Partial<IAppointmentTypeDTO>
  ) => Promise<void>;
  @Action public deleteAppointmentType!: (id: string) => Promise<void>;
  @Action public changeServiceOrder!: (payload: {
    serviceId: string;
    order: number;
  }) => Promise<void>;
  @Action public changeCategoryOfService!: (payload: {
    serviceId: string;
    categoryId: number;
    order: number;
  }) => Promise<void>;
  @Action public changeExtraService!: (payload: {
    serviceId: string;
    extraServices: IAppointmentTypeDTO[];
  }) => Promise<void>;

  private isLoading = true;
  private showForm = false;
  private editingService: Partial<IAppointmentTypeDTO> | null = null;
  private isEditing = false;
  private isCreating = false;

  get services(): IAppointmentTypeDTO[] {
    return this.extra ? this.extraServices : this.appointmentType || [];
  }

  get formattedCategories(): Category[] {
    return (this.categories || []).map((cat) => ({
      id: String(cat.id),
      title: cat.title,
    }));
  }

  get hasMultipleStaff() {
    return (this.staffMembers || []).length > 1;
  }

  get nonReactiveTranslations() {
    return Object.freeze({ ...this.$ts.dtos.appointmentTypeFormDTO });
  }

  async loadData() {
    this.isLoading = true;
    await Promise.all([
      this.getAppointmentTypes(this.extra),
      this.getCategories(),
    ]);
    this.isLoading = false;
  }

  toggleForm() {
    this.editingService = { discountPrice: -1 };
    this.isCreating = true;
    this.showForm = !this.showForm;
  }

  async startEdit(service: IAppointmentTypeDTO) {
    this.isEditing = true;
    this.editingService = service;
    this.showForm = true;
  }

  cancelEdit() {
    this.isEditing = false;
    this.isCreating = false;
    this.editingService = null;
    this.showForm = false;
  }

  async saveTreatment(treatment: Partial<IAppointmentTypeDTO>) {
    this.isLoading = true;
    try {
      if (this.isEditing) {
        await this.editAppointmentType(treatment);
      } else {
        treatment.isExtraService = this.extra;
        await this.addNewAppointmentType(treatment);
      }
      this.cancelEdit();
    } finally {
      await this.loadData();
    }
  }

  confirmDelete(id: string) {
    const dialog: IDialog = {
      text: this.$t(this.$ts.dialog.confirmDeleteService).toString(),
      type: DialogType.Choice,
      header: this.$t(this.$ts.dialog.deleteServiceHeader).toString(),
      action: () => this.deleteTreatment(id),
    };
    this.$store.commit(DialogMutations.AddToDialogQueue, dialog);
  }

  async deleteTreatment(id: string) {
    await this.deleteAppointmentType(id);
  }

  async reorderTreatment(payload: { serviceId: string; direction: number }) {
    await this.changeServiceOrder({
      serviceId: payload.serviceId,
      order: payload.direction,
    });
  }

  async updateCategory(payload: {
    serviceId: string;
    categoryId: string;
    order: number;
  }) {
    await this.changeCategoryOfService({
      ...payload,
      categoryId: Number(payload.categoryId),
    });
  }

  async updateExtraServices(payload: {
    serviceId: string;
    extraServices: IAppointmentTypeDTO[];
  }) {
    await this.changeExtraService(payload);
  }

  async created() {
    await this.loadData();
  }
}
