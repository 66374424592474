
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  name: "DayWorkingHours",
})
export default class DayWorkingHours extends Vue {
  @Prop({ required: true }) dayLabel!: string;
  @Prop({ required: true }) openTime!: string;
  @Prop({ required: true }) closeTime!: string;

  private localOpenTime: string = "";
  private localCloseTime: string = "";

  created() {
    this.localOpenTime = this.openTime;
    this.localCloseTime = this.closeTime;
  }

  public workingHourRule = [
    (v) => !!v || this.$t(this.$ts.workingHours.timeRequired),
    (v) => {
      let pattern = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
      return pattern.test(v) || this.$t(this.$ts.workingHours.invalidFormat);
    },
  ];

  get openingHourLabel() {
    return this.$t(this.$ts.workingHours.openingHourFor, {
      day: this.dayLabel,
    });
  }

  get closingHourLabel() {
    return this.$t(this.$ts.workingHours.closingHourFor, {
      day: this.dayLabel,
    });
  }

  validateTime(value: string): boolean {
    return this.workingHourRule.every((rule) => {
      const result = rule(value);
      return result === true;
    });
  }

  validateAndUpdateOpenTime(value: string) {
    this.localOpenTime = value;
    if (this.validateTime(value)) {
      this.$emit("update", {
        openTime: value,
        closeTime: this.closeTime,
      });
    }
  }

  validateAndUpdateCloseTime(value: string) {
    this.localCloseTime = value;
    if (this.validateTime(value)) {
      this.$emit("update", {
        openTime: this.openTime,
        closeTime: value,
      });
    }
  }

  validateAndEmitUpdate() {
    if (
      this.validateTime(this.localOpenTime) &&
      this.validateTime(this.localCloseTime)
    ) {
      this.$emit("update", {
        openTime: this.localOpenTime,
        closeTime: this.localCloseTime,
      });
    }
  }
}
