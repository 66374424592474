
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { actionStringCheckout, ISubscriptionForm } from "@/store/checkout";
import CustomButton from "@/components/global/styledComponents/customButton.vue";
import Loader from "@/components/global/Loader.vue";
import StripePayment from "./StripePayment.vue";
import { DialogActions, DialogType, IDialog } from "@/store/dialog";
import {
  ISubscriptionPlan,
  ISubscriptionStatus,
  Subscription,
} from "@/types/HasSubscription";
import { actionStringUser, getterStringUser } from "@/store/user";
import { formatDate } from "@/types/formatDateHelper";
import { IUserDTO } from "@shared/types";

@Component({
  name: "checkout",
  components: { StripePayment, Loader, CustomButton },
})
export default class Checkout extends Vue {
  @Action(actionStringCheckout.CREATE_SUBSCRIPTION) createSubscription: (
    subscriptionForm: ISubscriptionForm
  ) => Promise<void>;
  @Action(actionStringCheckout.CANCEL_SUBSCRIPTION_AT_END_OF_MONTH)
  cancelSubscriptionAtEndOfMonth: (payload: boolean) => Promise<void>;
  @Action(actionStringUser.GET_USER_SUBSCRIPTION)
  getUserSubscription: () => Promise<ISubscriptionStatus>;
  @Getter(getterStringUser.SUBSCRIPTION_STATUS)
  subscriptionStatus: ISubscriptionStatus;
  @Action(actionStringUser.GET_USER_SUBSCRIPTION_PLAN)
  getUserSubscriptionPlan: () => Promise<ISubscriptionPlan>;
  @Action(DialogActions.AddToDialogQueue) addToDialogQueue: Function;
  @Prop({ default: null }) subscriptionExpirationDate: string | null;

  @Getter user: IUserDTO;

  isLoading: boolean = false;
  isShowingCancelModal: boolean = false;
  checkout: boolean = false;

  public amount: Number = 25000;
  public description: String = "Abonnement / 250 mnd";

  triggerCancelSubscriptionModal(): void {
    this.isShowingCancelModal = true;
    let dialog: IDialog = {
      text: this.$t(this.$ts.subscription.cancelSubscriptionConfirm).toString(),
      header: this.$t(this.$ts.subscription.subscription).toString(),
      type: DialogType.Choice,
      action: this.cancelSubscription,
    };
    this.addToDialogQueue(dialog);
  }

  async cancelSubscription(): Promise<any> {
    this.isLoading = true;
    await this.cancelSubscriptionAtEndOfMonth(true);
    await this.getUserSubscription();
    this.isLoading = false;
    this.isShowingCancelModal = false;
  }
  async continueSubscription(): Promise<any> {
    this.isLoading = true;
    await this.cancelSubscriptionAtEndOfMonth(false);
    await this.getUserSubscription();
    this.isLoading = false;
  }

  get hasActiveSubscription(): boolean {
    return this.subscriptionStatus.status === Subscription.Active;
  }
  get hasCanceledSubscriptionPlan(): boolean {
    return this.subscriptionStatus.status === Subscription.CancelNextPayment;
  }

  get hasTrialPlan(): boolean {
    return this.subscriptionStatus.status === Subscription.Trial;
  }

  get hasPaymentIssues(): boolean {
    return (
      this.subscriptionStatus.status === Subscription.Expired ||
      this.subscriptionStatus.status === Subscription.Unpaid ||
      this.subscriptionStatus.status === Subscription.PastDue ||
      this.subscriptionStatus.status === Subscription.IncompleteExpired ||
      this.subscriptionStatus.status === Subscription.Incomplete
    );
  }

  get hasNoSubscription() {
    return this.subscriptionStatus.status == Subscription.None;
  }

  get getYearlyFinish() {
    let d = new Date(this.user.lastYearlySubscription);

    console.log("D: ", d);

    let year = d.getFullYear();
    let month = d.getMonth();
    let day = d.getDate();
    let c = new Date(year + 1, month, day);

    return formatDate(c);
  }
  async created() {
    await this.getUserSubscription();
  }

  async recievedPaymentToken(token) {
    await this.createSubscription(token);
  }

  async recievedFormData(subscriptionFormData) {
    await this.createSubscription(subscriptionFormData);
    this.isLoading = true;
    await this.getUserSubscription();
    await this.getUserSubscriptionPlan();
    this.isLoading = false;
    this.checkout = false;
  }
}
